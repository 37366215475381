import { IResponseQuestion } from '../../models/IResponseQuestion'
import { Draggable } from '../Draggable/Draggable';

import './DragQuestion.css'

//A component wrap with a question to be able to drag
export const DragQuestion = (props: { group: number, dragNo: string, contentProperty: string, showIndex: boolean, stepGroupQuestions: IResponseQuestion[], readOnlyAccess: boolean}) => {


     return (
        <div className="dragger-question-container">
            {

                props.stepGroupQuestions.filter((q: any) => q.questionNo === props.dragNo).map((question: any) => {
                    return <div key={question._id}>
                        <Draggable name={props.showIndex ? (question['group'] + '. ' + question[props.contentProperty]) : question[props.contentProperty]} id={question.group} readOnlyAccess={props.readOnlyAccess}></Draggable>
                    </div>
                })
            }


        </div>
    )
}