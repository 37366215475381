import React, {useEffect} from 'react';

import { useMsal } from "@azure/msal-react";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { handleLogout } from '../../utils/Utils';

export const SessionTimeOutModal = (props: {showModal: any, handleContinue: any, handleLogout: any}) => {

    const { instance } = useMsal();

    useEffect( () => {
        if(props.showModal) {
            handleLogout(instance);
        }
    });


    return (
        <></>
        // <Modal show={props.showModal} onHide={props.handleContinue}>
        //     <Modal.Header closeButton>
        //     <Modal.Title>You Have Been Idle!</Modal.Title>
        //     </Modal.Header>
        //     <Modal.Body>Your session is Timed Out. You want to stay?</Modal.Body>
        //     <Modal.Footer>
        //     <Button variant="danger" onClick={props.handleLogout}>
        //         Logout
        //     </Button>
        //     <Button variant="primary" onClick={props.handleContinue}>
        //         Continue Session
        //     </Button>
        //     </Modal.Footer>
        // </Modal>
    )
}