import { Header } from './Header';
import { Navigation } from './Navigation';

import './Layout.css';
import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';
import { TenantAccessDenied } from '../TenantAccessDenied/TenantAccessDenied';
import { Footer } from './Footer';

export const Layout = ({ children, currentTab }: any) => {
  const { instance, accounts } = useMsal();
  const [visible, setVisible] = useState(true);

  const logout = () => {
    instance.logoutPopup().catch((e: any) => {});
  };

  return (
    <div className="layout">
      <Header></Header>
      <div className="flex flex-col sm:flex-row sm:h-fit ">
        <Navigation currentTab={currentTab} />
        {visible ? (
          <div className="flex flex-col ">
            <main>{children}</main>
            <Footer></Footer>
          </div>
        ) : (
          <TenantAccessDenied show={!visible} onClose={logout}></TenantAccessDenied>
        )}
      </div>
    </div>
  );
};
