import { useEffect, useState } from 'react';
import { useDrop } from 'react-dnd'
import { IResponseQuestion } from '../../models/IResponseQuestion';
import { Draggable } from '../Draggable/Draggable';

import "./QuestionGridDropCell.css"

// The target components for hypothesis to drop into the approach area
export const QuestionGridDropCell = (
  props: { question: IResponseQuestion, onDropped(key: string, name: string, vals: any): any, readOnlyAccess: boolean }
) => {

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: "option",
    drop: (item: any) => onDrop(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))


  const onDrop = (item: any) => {
    props.onDropped(props.question._id, "answerText", item.name)
  }

  return (
    <div className='grid-drop-cell' ref={drop} >

      {
        (props.question.answerText != '') && <Draggable key={props.question.answerText} name={props.question.answerText} id={props.question.answerText} readOnlyAccess={props.readOnlyAccess}></Draggable>
      }


    </div>
  )
}
