import { useContext, useEffect, useState } from 'react';
import { IProblem } from '../../models/IProblem';
import { ProblemList } from '../ProblemList/ProblemList';
import { UserContext } from '../UserContext/UserContext';
import { Pagination } from '@appkit4/react-components/pagination';
import { Search } from '@appkit4/react-components/search';
import { useNavigate } from 'react-router-dom';
import './buckets.css';
import { Constants } from '../../utils/Constants';
import { Loading } from '@appkit4/react-components/loading';
import { PreferenceDropdownList } from '../PreferenceDropdownList/PreferenceDropDownList';
import { apiProblemPagination } from '../../services/apiService';
export const RightBucket = (props: { user: any }) => {
  const initialPreferences = { preferences: props.user.unitPreferences };
  const [user, setUser] = useContext(UserContext);
  // let initialPreferences = { preferences: user.unitPreferences };
  const [preference, setPreference] = useState(initialPreferences);

  const [problems, setProblems] = useState<{ data: IProblem[] }>({ data: [] });
  const [pageNumber, setPageNumber] = useState(1);
  const [currPage, setCurrPage] = useState(1); // storing current page number
  const [startingIndex, setStartingIndex] = useState(0);
  const [prevPage, setPrevPage] = useState(0); // storing prev page number
  const [wasLastList, setWasLastList] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [networkError, setNetworkError] = useState('');
  const [pageMode, setPageMode] = useState('');
  const [loading, setLoading] = useState(true);
  const problemsPerPage = Constants.rightBucketProblemsPerPage;
  const navigate = useNavigate();
  const [showPreference, setShowPreference] = useState(false);
  const [searchText, setSearchText] = useState('');

  const onPageChange = (page: number) => {
    setLoading(true);
    setCurrPage(page);
    setPageNumber(page);
    setStartingIndex(page - 1);
  };
  const newSelection = async (vals: any) => {
    setLoading(true);
    setPreference({ ...preference, ['preferences']: vals });
    setPageNumber(1);
    updateProblem(vals, 'filter');
  };

  const clearPreference = () => {
    setLoading(true);
    setShowPreference(false);
    setPreference(initialPreferences);
    setCurrPage(1);
    setPageNumber(1);
    updateProblem(initialPreferences.preferences, 'filter');
    setStartingIndex(0);
  };

  const handleProblemListChange = () => {};

  const updateProblem = async (prefs: any, callType: string) => {
    try {
      setNetworkError('');
      const preferences = {
        companyId: process.env.REACT_APP_COMPANYID,
        field: 'business-unit',
        preferences: prefs,
        userId: props.user?.mail,
        bucket: 'right',
        query: searchText,
      };

      if (props.user?.mail) {
        setPageMode(callType);
        const response = await apiProblemPagination(
          (callType == 'filter' ? 0 : startingIndex) * problemsPerPage,
          problemsPerPage,
          preferences,
        );
        //check if response is empty...
        if (response.data.ProblemsBetweenIndex.length != 0 && callType == 'pagination') {
          setPrevPage(currPage);
        }
        if (response.data.TotalNumOfPages != 0) {
          setNumberOfPages(response.data.TotalNumOfPages);
        }
        setProblems({ data: response.data.ProblemsBetweenIndex });
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setNetworkError('Server Error....');
    }
  };

  const handleSearchText = async () => {
    setLoading(true);
    setPageNumber(1);
    await updateProblem(preference.preferences, 'filter');
  };

  useEffect(() => {
    const fetchData = async () => {
      updateProblem(preference.preferences || [], 'pagination');
    };

    if (!wasLastList && prevPage !== currPage && user) {
      fetchData();
    }
  }, [currPage, wasLastList, prevPage, user]);

  useEffect(() => {
    const getData = setTimeout(() => {
      handleSearchText();
    }, Constants.debounceTime)
    return () => clearTimeout(getData)
  }, [searchText]);

  return (
    <>
      <Search
        className="search-bar"
        searchType="secondary"
        onSearch={handleSearchText}
        searchValue={searchText}
        onChange={(val) => {
          setSearchText(val);
        }}
      ></Search>
      {problems.data.length != 0 && !loading && (
        <div className="h-[80%] sm:h-[73%]">
          <ProblemList
            problems={problems.data}
            bucket="right"
            onChange={handleProblemListChange}
          ></ProblemList>
        </div>
      )}
      {problems.data.length != 0 && !loading && numberOfPages > 1 && (
        <Pagination
          className="max-h-[30px] p-0 text-xs items-center  min-w-0"
          current={pageNumber}
          total={numberOfPages}
          onPageChange={onPageChange}
        ></Pagination>
      )}
      {loading && (
        <div className="loader">
          {' '}
          <Loading
            className="scale-[3px]"
            loadingType="circular"
            indeterminate={true}
            compact={false}
          ></Loading>
        </div>
      )}
      {problems.data.length == 0 && showPreference && (
        <div className="no-records">No problems found for selected preference</div>
      )}
      {problems.data.length == 0 && !loading && !showPreference && (
        <div className="no-records">No problems are posted yet</div>
      )}
      {showPreference && (
        <div className="filter">
          <span className="dropdown-list">
            <PreferenceDropdownList
              multiple={true}
              field="business-unit"
              value={preference.preferences}
              onSelected={(vals) => newSelection(vals)}
            ></PreferenceDropdownList>{' '}
          </span>
          <span className="preferences" onClick={() => clearPreference()}>
            Clear
          </span>
        </div>
      )}
      {!showPreference && problems.data.length != 0 && !loading && (
        <div className="preferences" onClick={() => setShowPreference(true)}>
          Update preferences &gt;
        </div>
      )}
      {networkError != '' && <div className="preferences">Server Error...</div>}
    </>
  );
};
