import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAccount, useMsal } from '@azure/msal-react';
import { AppPages } from '../../utils/Constants';
import { HomeDashboard } from '../../components/HomeDashboard/HomeDashboard';
import { Layout } from '../../components/Layout/Layout';
import { ProfileModal } from '../../components/Profile/ProfileModal';

import './Home.css';
import { UserContext } from '../../components/UserContext/UserContext';
import { apiFindUser } from '../../services/apiService';
import { useTrackPage } from '../../utils/useTrackPage';

export const Home = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const navigate = useNavigate();
  useTrackPage(AppPages.HOME);

  const [loading, setLoading] = useState(true);

  const [firsttime, setFirsttime] = useState(false);
  const [firstTimeUser, setFirstTimeUser] = useState(false);

  const fetchProfile = async () => {
    apiFindUser(account?.username)
      .then((res) => {
        setLoading(false);
        if (res.data.userProfile) {
          setFirsttime(false);
          setFirstTimeUser(res.data.userProfile.firstTime);
          const trainingCompleted = res.data.userProfile.trainings?.find(
            (t: any) => t.completed === false && t.required === true,
          );
          if (trainingCompleted) {
            navigate(`/${AppPages.TRAINING}`);
          }
        } else {
          setFirsttime(true);
        }
      }).catch(()=>{
        setLoading(false);
      });
  };

  const profiledCreated = () => {
    setFirsttime(false);
    navigate(`/${AppPages.TRAINING}`);
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <Layout currentTab="home">
      {firsttime ? (
        <ProfileModal onCreated={profiledCreated} flag={'create'}></ProfileModal>
      ) : (
       !loading && <HomeDashboard firstTimeUser={firstTimeUser}></HomeDashboard>
      )}
    </Layout>
  );
};
