import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../components/Layout/Layout'
import { Requests } from '../../components/RequestList/Requests'
import { UserContext } from '../../components/UserContext/UserContext';
import { AppPages } from '../../utils/Constants';
import { useTrackPage } from '../../utils/useTrackPage';

//Request Page
export const RequestsPage = () => {
  const [user, setUser] = useContext(UserContext);
  useTrackPage(AppPages.REQUESTS);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!user?.trainingCompleted) {
      navigate(`/${AppPages.TRAINING}`);
    } 
  }, []);
  
  return (
    <Layout currentTab="requests">
       {user && <Requests></Requests>}
    </Layout>
  )
}
