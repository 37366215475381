import React, { useEffect, useState } from 'react';

import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import pwcLogoImage from '../../assets/images/PoweredbyPwC.svg';

import './Login.css';
import { appInsights } from '../../utils/appInsights';

export const Login = (props: { accessDenied: boolean }) => {
  const { instance, accounts } = useMsal();
  const [isAccessDenied, setAccessDenied] = useState<boolean>(false);

  // const { instance, accounts } = useMsal();
  // const account = useAccount(accounts[0]);

  function handleLogin(instance: any) {
    instance
      .loginRedirect(loginRequest)
      .then((response: any) => {
        appInsights.setAuthenticatedUserContext(accounts[0]?.username || '', accounts[0]?.name);
      })
      .catch((e: any) => {
        console.error(e);
      });
  }

  useEffect(() => {
    setAccessDenied(props.accessDenied);
  });

  return (
    <div className="login-flex-container">
      <div className="flex-child login-content">
        <div className="logo">
          <img src={`/${process.env.REACT_APP_LOGIN_LOGO}`}></img>
        </div>

        <div className="login-box ">
          <div className="title pb-2 hidden sm:block">{process.env.REACT_APP_LOGIN_TITLE}</div>
          <div className="description pb-2">{process.env.REACT_APP_LOGIN_DESC}</div>
          {isAccessDenied && <div className="access-denied">You are not authorized!</div>}
          <div className="login">
            <button onClick={() => handleLogin(instance)}>
              {process.env.REACT_APP_LOGIN_BUTTON}
            </button>
          </div>
          <div className="poweredby">
            <img src={pwcLogoImage} />
          </div>
        </div>
      </div>
      <div className="flex-child login-img">
        <img className="image_container" src={process.env.REACT_APP_LOGIN_IMAGE}></img>
      </div>
    </div>
  );
};
