import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@appkit4/react-components/modal';
import { Button } from '@appkit4/react-components';
import { QuestionGroup } from '../QuestionList/QuestionGroup';

import { IResponse } from '../../models/IResponse';
import { IResponseQuestion } from '../../models/IResponseQuestion';

import { apiGetFeedbackResponse, apiPostFeedbackResponse } from '../../services/apiService';

import { getInitResponse } from '../../utils/QuestionUtil';

import "./Feedback.css";

export const Feedback = (props: {
    companyId: string;
    problemId: string;
}) => {
    const initFeedback: IResponse = getInitResponse();
    const [feedback, setFeedback] = useState<IResponse>(initFeedback);
    const [validateAnswers, setValidateAnswers] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        loadFeedbackQuestions();
    }, []);

    const handleOnClose = () => {
        apiPostFeedbackResponse(feedback).then(res => {
            navigate('/');
        });
    }

    const loadFeedbackQuestions = () => {
        apiGetFeedbackResponse(props.companyId, props.problemId).then(res => {
            setFeedback(res.data);
        });
    }

    const handleOnChange = (key: string, name: string, vals: any) => {
        if (name == "answerText") {
            const updatingQuestion = feedback.questions.find((q) => q._id == key);
            if (updatingQuestion) {
                updatingQuestion.answerText = vals;
            }
        }
        handleValidateAnswers();
    }

    const handleValidateAnswers = () => {
        let isValidated = true;
        feedback.questions.map(question => {
            if (question.answerText.trim() == "") {
                isValidated = false;
            }
        });
        setValidateAnswers(isValidated);
    }

    return (
        <Modal
            title='Feedback'
            className='feedback-container'
            visible={true}
            closable={true}
            footer={
                <Button className='close-button' kind="negative" onClick={handleOnClose} disabled={!validateAnswers}>
                    Submit
                </Button>
            }
        >
            <div className='feedback-questions'>
                {feedback && <QuestionGroup group={1} stepGroupQuestions={feedback?.questions} filterCategory="" onChanged={handleOnChange} onFocus={handleOnChange} onRemove={() => {}} readOnlyAccess={false}></QuestionGroup>}  
            </div>
        </Modal>
    );
}