import React, { useEffect, useState } from "react"
import { IResponseQuestion } from "../../models/IResponseQuestion";
import { RawContent } from "../RawContent/RawContent";

import "./QuestionEditText.css"

//Edit box componnet 
export const QuestionEditText = (props: { question: IResponseQuestion, onChange: any, readOnlyAccess: boolean }) => {

    const [value, setValue] = useState<string>('');


    useEffect(() => {
        setValue(props.question.answerText);
    }, [])

    useEffect(() => {

    }, [props.question])


    const handleAnswerChange = (event: any) => {
        setValue(event.target.value);
        props.onChange(props.question._id, [event.target.name], event.target.value,)

    }

    return (
        <div className="question-edittext-container" >
            <div className="question">{props.question.step}{props.question.questionNo}. <RawContent input={props.question.question} links={props.question.questionLinks}></RawContent> {props.question.required && <span className="question-red-star">*</span>} </div>
            {props.question.subtitle && <div className="subtitle">
                <div><span className="Appkit4-icon icon-information-outline"></span></div>
                <div className="subtitle-content">{props.question.subtitle}</div>
            </div>}
            <div className="content">

                <div className="edittext">
                    <input type={"text"} name="answerText" value={value} onChange={handleAnswerChange} disabled={props.readOnlyAccess}></input>
                </div>
            </div>
        </div>
    )
}