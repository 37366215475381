/* 
 *  Component that renders each Question caled from Intake Questionnaire
 *  props: 
 *      question - accepts a question (IQuestion) to display
 *      reviewing - true (if not edittable ie review page while not editing) and false (if step 1 or while editing in step 2)
 *      onChange - selects the option on questionnaire
*/
import React, { useEffect, useState } from 'react';
import { Button, Select } from '@appkit4/react-components';

import "./Question.css";
import { IQuestion } from '../../models/IQuestion';
import { RawContent } from '../RawContent/RawContent';

export const Question = (props: {question:IQuestion,reviewing:boolean, onChange:any}) => {
    const [answer, setAnswer] = useState('');
    const [value, setValue] = React.useState('');
    const [options, setOptions] = useState<{value:string, label:string}[]>([]);

    const fetchOptions = async () => {
        if (props.question.additionalOptions.length > 0) {
            let additionalOptions = props.question.additionalOptions.map((option: any) => {
                return { value: option.value, label: option.value }
            });
            setOptions(additionalOptions);
            setValue(props.question.additionalAnswer);
        }

    }

    useEffect(() => {
        setAnswer(props.question.answer);
        fetchOptions();
        setValue(props.question.additionalAnswer);
    }, []);

    const handleButtonClick = (name: string, val: string) => {

        props.onChange(props.question.questionNo, name, val);
    }

    const handleDropdownSelected = (name: string, val: string) => {
        props.onChange(props.question.questionNo, name, val);
    }

    return (
        <div className='question-container'>
            <div className="question-title">
                {props.question.questionNo}. <RawContent input={props.question.question} links={props.question.questionLinks}></RawContent><span className="red-star">*</span>
            </div>
            {props.question.subtitle.length>0  &&
                <div className="question-subtitle">
                    {props.question.subtitle.map((s: any, index: any) => {
                        return (<div key={index}>{s}</div>)
                    })}
                </div>
            }
            {
                (!props.question?.skipAnswer) &&
                <div className="question-buttons">
                    <Button className={(answer == 'Yes') ? 'button-selected' : 'button-unselected'} kind='secondary' disabled={props.reviewing} onClick={() => { setAnswer('Yes'); handleButtonClick("answer", "Yes"); setValue('') }}>Yes</Button>
                    <Button className={(answer == 'No') ? 'button-selected' : 'button-unselected'} kind='secondary' disabled={props.reviewing} onClick={() => { setAnswer('No'); handleButtonClick("answer", "No"); setValue('') }}>No</Button>
                </div>
            }

            {(((!!props.question.skipAnswer) || (props.question.additionalCondition == answer)) && (props.question.additionalOptions.length > 0)) &&
                <div className="additional-question">

                    {(!props.question.skipAnswer) && 
                    <>

                        <div className="additional-question-title">
                            {props.question.questionNo}a. {props.question.additionalQuestion} <span className="red-star">*</span>
                        </div>
                        {
                            props.question.additionalSubtitle &&
                        
                        <div className="additional-question-subtitle">
                            <RawContent input={props.question.additionalSubtitle} links={[]}></RawContent>
                        </div>
                        }
                    </>
                    

                    }

                    <div>
                        <div className="additional-question-subtitle">
                            <RawContent input={props.question.additionalSubtitle} links={[]}></RawContent>
                        </div>

                        <Select
                            placeholder={""}
                            disabled={props.reviewing}
                            dropdownAlwaysDown={false}
                            data={options}
                            value={value}
                            searchable={false}
                            onSelect={vals => { setValue(vals); handleDropdownSelected("additionalAnswer", vals) }}>
                        </Select>
                    </div>
                </div>

            }
        </div>
    )
}