import { IProblem } from "../../models/IProblem"
import { ProblemCard } from "../ProblemCard/ProblemCard"


export const ProblemList = (props:{problems:IProblem[], bucket:string, onChange: any}) => {
    return (
        <div >
              {props.problems.map((p:IProblem)=>{
               return( <ProblemCard key={p._id} problem={p} bucket={props.bucket} onChange={props.onChange}></ProblemCard>)
            })}
        </div>
    )
}