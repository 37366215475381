import { Modal } from '@appkit4/react-components/modal';
import { Button } from '@appkit4/react-components/button';
import { IProblem } from '../../models/IProblem';

import "./ProblemDeleteConfirmModal.css"

export const ProblemDeleteConfirmModal = (props: {problem: IProblem, visible: boolean, onClick: any}) => {
    return (
        <Modal
            className="delete-problem-confirm-modal"
            visible={props.visible}
            closable={false}
            header={<strong className="home_profile_title">Are you sure?</strong>}
            footer={
                <>
                <Button className="gem-confirm-button" kind="text" onClick={() => props.onClick(false)}>
                    Cancel
                </Button>
                <Button className="gem-confirm-button" kind="negative" onClick={() => props.onClick(true)}>
                    Delete
                </Button>
                </>
            }
        >You will be deleting "{props.problem.title}" permenantly. Are you sure you want to continue?</Modal>
    );
}