
export const AppPages={
    PROBLEM:'problem',
    INTAKE:'intake',
    RESULT:'result',
    TRAINING:'training',
    QUICKWINS:'quickwins',
    GEM:'gem',
    STRATEGIC:'strategic',
    REPORTING:'reporting',
    BACKEND:'backend',
    HOME:'home',
    REQUESTS:"requests",
    APPROVAL:"approval",
    LEADERBOARD: "leaderboard"
}

export const StepStyle = {
         List: 'List',
         Grid: 'Grid',
         DragToTable: 'DragToTable',
         RatingGroup: 'RatingGroup',
         Hypothesis: 'Hypothesis',
         DragToSolutions: 'DragToSolutions'
}

export const RequestStatus = {
    Pending: "Pending",
    Approved: "Approved",
    Declined: "Declined",
    InProgress: "In Progress",
    Solved: "Solved"
}

export const ProblemStatus = {
    Intake: "Intake",
    Pending: "Pending",
    Approved: "Approved",
    Declined: "Declined",
    InProgress: "In Progress",
    Resolved: "Resolved"
}

export const Constants={
    requestPerPage:4,
    teamMembersGroupCount:5,
    textMaxCharacterLength:420,
    leftBucketProblemsPerPage:4,
    rightBucketProblemsPerPage:4,
    commentsPerLoad: 5,
    debounceTime:500,
    saveLoaderTimeout:500,   // loader timeout: 0.5 secounds when user hits save 
    backgroundColorForIcon: "#49ABA0",   // background color for icon
    numOfNotifications: 6
}

export const NotificationText = {
    textCommentOnCurrentUserPost: 'commented on your post',
    textLikeOnCurrentUserPost: 'liked your post',
    textCurrentUserTagged: 'tagged you in a post',
    textCommentOnProblemWithCurrentUserTagged: 'commented on a problem you were tagged on',
    textLikeOnProblemWithCurrentUserTagged: 'liked a problem you were tagged on',
    textProblemDeadlineGreaterThan0: 'Your unresolved problem is appraching the deadline',
    textProblemDeadlineLessThan0: 'days have passed since your unresolved problem deadline',
    textProblemDeadlineToday: 'Your unresolved problem deadline is today',
    textSendRequestToCoach: 'sent a request for completing',
    textDeclineRequest: 'sugguested to re-evaluate your problem',
    textApproveRequest: 'approved your request',
}

export const LeaderboardType = {
    Individual: "Individual",
    Unit: "Unit",
    Division: "Division"
}

export const AppRoles = {
    SpotUser: "spotuser",
    Coach: "coach",
    Admin: "admin"
}