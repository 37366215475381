import React, { useState, useEffect } from 'react';

import { Modal } from '@appkit4/react-components/modal';
import { Button } from '@appkit4/react-components/button';

import { IResponse } from '../../models/IResponse';
import './GemConfirmModal.css';
import { Constants } from '../../utils/Constants';

//Gem Submit Confirm Modal
export const GemConfirmModal = (props: {
  show: boolean;
  gem: IResponse;
  onClose(ok: boolean, achievedBenefit: string): any;
}) => {
  const [visible, setVisible] = useState(false);
  const [achievedBenefit, setAchievedBenefit] = useState('');

  useEffect(() => {
    setVisible(visible);
  }, [visible]);

  useEffect(() => {
    setVisible(props.show);
    setAchievedBenefit(props.gem.achievedBenefit);
  }, [props.show]);

  const closeModal = (result: boolean) => {
     props.onClose(result, achievedBenefit);
  };

  const achieveBenefitChanged = (event: any) => {
    setAchievedBenefit(event.target.value);
  };

  return (
    <div className="gem-confirm-modal-container">
      <Modal
        visible={visible}
        closable={false}
        header={<strong className="home_profile_title">Confirm</strong>}
        footer={
          <>
            <Button className="gem-confirm-button" kind="text" onClick={() => closeModal(false)}>
              Cancel
            </Button>
            <Button className="gem-confirm-button" kind="negative" onClick={() => closeModal(true)}>
              Problem Solved
            </Button>
          </>
        }
      >
        <div className="gem-confirm-container">
          <div>
            On Step 3, you selected the following Metrics and Target for the metrics. Is the
            improvement made against the metric completed?
          </div>
          <div>
            <div>
              <div className="gem-confirm-table-header">
                <div>Metrics Categories</div>
                <div>Target for the metrics</div>
                <div>Quantify the target for the metrics selected*</div>
              </div>
              <div className="gem-confirm-table-row">
                <div>
                  {
                    props.gem.questions?.find((q) => q.step == 1 && q.questionNo == 'b')
                      ?.answerText
                  }
                </div>
                <div>
                  {
                    props.gem.questions?.find((q) => q.step == 3 && q.questionNo == 'b')
                      ?.answerText
                  }
                </div>
                <div>
                  {
                    props.gem.questions?.find((q) => q.step == 3 && q.questionNo == 'a')
                      ?.answerText
                  }
                </div>
              </div>
            </div>
          </div>
          <div>If yes, what was the actual benefit for the target metric achieved?</div>
          <div>
            <textarea
              className="achievedBenefit-input"
              maxLength={Constants.textMaxCharacterLength}
              name="achivedBenefit"
              value={achievedBenefit}
              onChange={achieveBenefitChanged}
            ></textarea>
          </div>
        </div>
      </Modal>
    </div>
  );
};
