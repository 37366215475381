/*
 * This code gets activated for every single HTTP request received by the application.
 */
import React, { useContext, useEffect } from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import axios from 'axios';
import { UserContext } from './UserContext/UserContext';
import { loginRequest } from '../authConfig';
import { apiFindUser,  apiSubmitUserProfile } from '../services/apiService';
import { getAuthorizedPages } from '../utils/QuestionUtil';

interface RequestInterceptorProps {
  children: JSX.Element;
}

const RequestInterceptor: React.FC<RequestInterceptorProps> = ({
  children,
}: RequestInterceptorProps) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const [user, setUser] = useContext(UserContext);

  useEffect(() => {
    /*
     * fetchs the use profile details.
     * @param - none.
     * sets the user profile and authorized pages for a role in to user state
     */
    const fetchUserDetails = async () => {
      if (!user) {
        //get the list of authorized pages against the role
        const authorizedPages = await getAuthorizedPages(account?.idTokenClaims?.roles||[])
        //get the user details by calling user/find/{companyId}/{username}
        apiFindUser(account?.username)
          .then(async (res) => {
            if (res.data.userProfile) {

              //update appRoles if it is changed
              if(res.data.userProfile?.appRoles?.sort().join(',')!=account?.idTokenClaims?.roles?.sort().join(',')){
                const updatedUserProfile = {...res.data.userProfile, ["appRoles"]: account?.idTokenClaims?.roles};
                apiSubmitUserProfile(updatedUserProfile)
              } 
              const trainingCompleted = res.data.userProfile.trainings?.find(
                (t: any) => t.completed === false && t.required === true,
              );
              if (trainingCompleted) {
                //set the user profile details to user state
                setUser({
                  ...user,
                  givenName: res.data.userProfile.firstName,
                  surname: res.data.userProfile.lastName,
                  OfficeLocation: res.data.userProfile.location,
                  mail: res.data.userProfile.email,
                  trainingCompleted: false,
                  role: account?.idTokenClaims?.roles ? account?.idTokenClaims?.roles[0] : '',
                  authorizedPages: authorizedPages, // add authorized pages to the user state
                  unitPreferences: res.data.userProfile.unitPreferences
                });
              } else {
                //set the user profile details to user state
                setUser({
                  ...user,
                  givenName: res.data.userProfile.firstName,
                  surname: res.data.userProfile.lastName,
                  OfficeLocation: res.data.userProfile.location,
                  mail: res.data.userProfile.email,
                  trainingCompleted: true,
                  role: account?.idTokenClaims?.roles ? account?.idTokenClaims?.roles[0] : '',
                  authorizedPages: authorizedPages, // add authorized pages to the user state
                  unitPreferences: res.data.userProfile.unitPreferences
                });
              }
            }
          });
      }
    };
    fetchUserDetails();
  }, [user]);

  /* eslint-disable no-param-reassign */
  axios.interceptors.request.use(async (config) => {
    if (!account) {
      throw Error('No active account! Verify a user has been signed in.');
    }

    const response = await instance.acquireTokenSilent({
      ...loginRequest,
      account,
    });

    const bearer = `Bearer ${response.idToken}`;
    config!.headers!.Authorization = bearer;

    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      if (response.status === 401) {
        alert('You are not authorized');
      }
      return response;
    },
    (error) => {
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.message);
    },
  );
  /* eslint-enable no-param-reassign */

  return <>{children}</>;
};

export default RequestInterceptor;
