import React, { useEffect, useState } from 'react';

import { Select } from '@appkit4/react-components';

import { IResponseQuestion } from '../../models/IResponseQuestion';

import './QuestionDropdown.css';
import { RawContent } from '../RawContent/RawContent';


//Dropdown inside Grid Grid
export const QuestionCategorySelectDropdown = (props: {
    multiple: boolean;
    question: IResponseQuestion;
    options: any;
    value: string;
    onSelected(key: string, name: string, vals: any, question: IResponseQuestion): any;
    readOnlyAccess: boolean;
}) => {

    const [value, setValue] = useState('');
    const [allOptions, setAllOptions] = useState<string[]>();
  
    useEffect(() => {
        fetchOptions();
    }, []);

    const fetchOptions = () => {
        const tempOptions: string[] = [];
        props.options?.map((option: any) => {
          tempOptions.push(option.value);
        })
        setAllOptions(tempOptions);
    }

    const selectionChanged = (vals: any) => {
        setValue(vals);
        props.onSelected(props.question._id, 'answerText', vals, props.question);
    };

    useEffect(() => {
        setValue(props.value);
    }, []);

    useEffect(() => {
        if (props.value != '') {
            setValue(props.value);
        }
    }, [props.value]);

    return (
        <div className="question-dropdown">
        <div className="question">
            {props.question.step}
            {props.question.questionNo}. <RawContent input={props.question.question} links={props.question.questionLinks} ></RawContent>{' '}
            {props.question.required && <span className="question-red-star">*</span>}
        </div>
        <div className="options">
            <Select
                disabledItemValues={props.readOnlyAccess ? allOptions : []}
                multiple={props.multiple}
                placeholder={''}
                dropdownAlwaysDown={false}
                data={props.options}
                value={value}
                valueKey={'value'}
                labelKey={'label'}
                searchable={false}
                showSelectAll={true}
                onSelect={(vals: any) => {
                    selectionChanged(vals);
                }}
            ></Select>
        </div>
        </div>
    );
};
