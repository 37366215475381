import React, { createContext } from "react";

import { IUserProfile } from "../../models/IUserProfile"

export const UserContext = createContext<any>({
    givenName: '',
    surname:'',
    userPrincipalName:'',
    id:'',
    jobTitle: '',
    officeLocation:'',
    mail:'',
    trainingCompleted: false,
    role:'',
    authorizedPages:'',
    unitPreferences:[]   
});