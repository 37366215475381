import { useEffect, useRef, useState } from "react"
import {useDrag} from "react-dnd"

import "./Draggable.css"


//The generic component to be able to be dragged
export const Draggable = (props: { name:string, id: string, readOnlyAccess: boolean }) => {

const [{isDragging}, drag] = useDrag(()=>({
    type:"option",
    canDrag: (props.readOnlyAccess === false),
    item: {name: props.name, id: props.id},
    collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
    })

}))

    return (
      <div className="draggable-container" ref={drag} >
        {props.name}
      </div>
    )
  }
  
