/*
*   Accepts an array of questions (IQuestion []) and traverses through each question to concatenate
*   each answer to a single string to form a sequence.
*   Sample Output - IndirectNoMediumInfrequentlyYes>200k40+Yes20+
*/
import { IQuestion } from "../models/IQuestion";

export const MakeSequence = async (questions: IQuestion[]) => {
    let sequence: string = "";
    
    questions.map((question) => {
        if (question.skipAnswer) {
            sequence = sequence.concat(question.additionalAnswer);
        } else {
            sequence = sequence.concat(question.answer);
        }
    })

    return sequence;
}