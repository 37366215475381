/* 
*   This is a wrapper module for CommentModal
*   Accepts: {
*       show: boolean value for the visibility of the modal (true -> visible, false -> not visible)
*       problem: IProblem type relating the comments
*       changeCommentCount: function to change comment count when one is added
*       changeLikeCount: function to change like count when one is added
*       changeModalStatus: function to change the visibility of the modal
*   }
*/

import { useEffect, useState } from "react";
import { Modal } from "@appkit4/react-components";
import { CommentModal } from "./CommentModal";
import { Button } from '@appkit4/react-components/button';
import { IProblem } from "../../models/IProblem";

import "./CommentModalWrapper.css";

export const CommentModalWrapper = (props: {
    show: boolean;
    addComment: boolean;
    problem: IProblem;
    changeCommentCount: any;
    changeLikeCount: any;
    changeModalStatus: any;
}) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setVisible(props.show);
    }, []);

    const handleClose = () => {
        setVisible(false);
        props.changeModalStatus();
    };
    return(
        <Modal
            visible={visible}
            title={''}
            ariaLabel={'Lorem ipsum dolor sit'}
            className='comment-modal-wrapper'
            header={''}
            icons={''}
            footer={
                <div className="close-button">
                    <Button kind="negative" onClick={handleClose}>Close</Button>
                </div>
            }
        >
            <div className="problem-desc">
                <strong>Problem: </strong>{props.problem.description}
            </div>
            <CommentModal
                problem={props.problem}
                changeCommentCount={props.changeCommentCount}
                addComment={props.addComment}
                width={'380px'}
                height={'260px'}
            ></CommentModal>
        </Modal>
    );
}