import React, { useEffect, useState } from 'react';
import { IDragHypothesis } from '../../models/IDragHypothesis';
import { IResponseQuestion } from '../../models/IResponseQuestion';
import { Canvas } from '../ResultChart/Canvas';
import { DropQuestion } from './DropQuestion';

import './ApproachChart.css';

//The Approach Chart for hypothesis to drop on
export const ApproachChart = (props: {
  action: string;
  stepQuestions: IResponseQuestion[];
  onDropped(dragHypothesis: IDragHypothesis): any,
  readOnlyAccess: boolean;
}) => {
  return (
    <div className="chart-container">
      <div className="chart-wrapper">
        <Canvas></Canvas>
      </div>

      <div className="card-container">
        <DropQuestion approach="Gem"  stepQuestions={props.stepQuestions}
          className={' gem-card ' }
          title="Gem"
          subtitle="A3"
          onDropped={props.onDropped}
          readOnlyAccess={props.readOnlyAccess}
        ></DropQuestion>
        <DropQuestion approach="Strategic"   stepQuestions={props.stepQuestions}
          className={ 
              ' strategic-card  '
          }
          title="Strategic"
          subtitle="7 Step Framing"
          onDropped={props.onDropped}
          readOnlyAccess={props.readOnlyAccess}
        ></DropQuestion>
        <DropQuestion approach="QuickWins"   stepQuestions={props.stepQuestions}
          className={  'quick-win-card' 
          }
          title="Quick Wins"
          subtitle="PAR (Problem, Action, Result)"
          onDropped={props.onDropped}
          readOnlyAccess={props.readOnlyAccess}
        ></DropQuestion>
        <DropQuestion approach="Avoid"   stepQuestions={props.stepQuestions}
          onDropped={props.onDropped}
          className={'avoid-card'   }
          title="Avoid"
          subtitle=""
          readOnlyAccess={props.readOnlyAccess}
        ></DropQuestion>
        
      </div>
    </div>
  );
};
