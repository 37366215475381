import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@appkit4/react-components';
import { IResponse } from '../../models/IResponse';
import { Switch } from '../Switch/Switch';
import { IResponseQuestion } from '../../models/IResponseQuestion';
import { UserContext } from '../UserContext/UserContext';
import { AppPages, Constants, ProblemStatus, } from '../../utils/Constants';
import { GemConfirmModal } from './GemConfirmModal';
import { BlobLink } from '../Blobs/BlobLink';
import { apiCompleteProblem, apiGetGem, apiResolveProblem, apiUpdateGemResponse, apiAddNotification, apiSetLastPage, apiTraceEvent, apiGetProblem, apiToggleProblemPrivateStatus } from '../../services/apiService';
import { QuestionStepper } from '../QuestionStepper/QuestionStepper';
import { Feedback } from '../Feedback/Feedback';
import { IStepHeading } from '../../models/IStepHeading';
import { getInitResponse, getLastStep, getMaxStep, getNewStepGroupQuestions, validateResponse } from '../../utils/QuestionUtil';
import { StepQuestions } from '../StepQuestions/StepQuestions';
import { IDragHypothesis } from '../../models/IDragHypothesis';
import { INotificationType } from '../Notification/INotificationType';
import { validateUserReadOnlyAccess } from '../../utils/QuestionUtil';

import './Gem.css';
import { TeamMember } from '../TeamMember/TeamMember';
import { IProblem } from '../../models/IProblem';

//Show Gem Questions
export const Gem = () => {
  const initGem: IResponse = getInitResponse();

  const [gem, setGem] = useState<IResponse>(initGem);
  const [headings, setHeadings] = useState<IStepHeading[]>([]);  // hold headings for Steps
  const [lastStep, setLastStep] = useState<number>(1); //set last step
  const [current, setCurrent] = useState<number>(1); //current step
  const [maxStep, setMaxStep] = useState<number>(1); //max step completed
  const [invalidSteps, setInvalidSteps] = useState<any[]>([]); //invalid steps
  const [user] = useContext(UserContext);
  const navigate = useNavigate();
  const [confirm, setConfirm] = useState(false);
  const [validated, setValidated] = useState(false);
  const [nextEnabled, setNextEnabled] = useState(false);
  const [saving, setSaving] = useState<boolean>(false); //show loading icon when click button
  const [filterCategory, setFilterCategory] = useState('');
  const { problemId } = useParams();
  const [readOnlyAccess, setReadOnlyAccess] = useState<boolean>(false);
  const [feedbackModal, setFeedbackModal] = useState<boolean>(false);
  const [privateProblem, setPrivateProbem] = useState<boolean>(false);

  const fetchGem = async () => {
    if (problemId) {
      // get readonly access (true/false)
      const readOnly: boolean = await validateUserReadOnlyAccess(problemId, user?.mail);
      setReadOnlyAccess(readOnly);
      // set last problem page to gems
      if (!readOnly) await apiSetLastPage(problemId, AppPages.GEM);

      await apiGetGem(problemId).then((res: any) => {
        if (!res.data.gemViewDto.problemId) {
          res.data.gemViewDto.problemId = problemId;
          res.data.gemViewDto.companyId = process.env.REACT_APP_COMPANYID;
        }
        setHeadings(res.data.gemViewDto.headings);
        setGem(res.data.gemViewDto);

        let previousMaxStep = getMaxStep(res.data.gemViewDto);

        setLastStep(getLastStep(res.data.gemViewDto));

        if (previousMaxStep > 1) {
          //load initial the steps status
          if (readOnly) {
            setCurrent(1);
          } else {
            setCurrent(previousMaxStep);
          }
          setMaxStep(previousMaxStep + 1);
          const findInvalidSteps = res.data.gemViewDto.questions
            .filter((q: IResponseQuestion) => q.step < previousMaxStep && q.answerText == '')
            .map((q: any) => q.step);
          setInvalidSteps(findInvalidSteps);
        } else {
          setCurrent(1);
          setMaxStep(1);
        }
        // set 1e gemsMetricsGroup
        res.data.gemViewDto.questions.map((question: IResponseQuestion) => {
          if (question.step == 1 && question.questionNo == 'b') {
            setFilterCategory(question.answerText);
          }
        });
      });
      
      // set problem private status
      const problem: IProblem = (await apiGetProblem(problemId)).data.RegisterProblem;
      setPrivateProbem(problem?.private);
    }
  };

  useEffect(() => {
    fetchGem();
  }, [user]);

  const validateStep = () => {
    if (current === 7) {
      const v = validateResponse(gem);
      setValidated(v);
    } else {
      const missingAnswer = gem.questions?.find(
        (q: any) =>
          (q.answerText === '' || q.answerText?.length == 0 || !q.answerText) && q.step === current,
      );
      if (missingAnswer) {
      } else {
        //remove step from invalidSteps if it is validated
        if (invalidSteps.includes(current)) {
          setInvalidSteps(invalidSteps.fill((s: any) => s == current));
        }
      }
      setNextEnabled(missingAnswer ? false : true);
    }
  };

  useEffect(() => {
    validateStep();
  }, [current]);

  useEffect(() => {
    validateStep();
  }, [gem]);


  const onNavigate = (step: number) => {
    checkInvalidSteps();

    if (step <= maxStep) {
      setCurrent(step);
    }
  };

  //check whether the step was invalid when navigate back
  const checkInvalidSteps = () => {
    validateStep();
    const findInvalidSteps = gem.questions
      .filter((q: any) => q.step < maxStep && q.answerText == '')
      .map((q: any) => q.step);
    setInvalidSteps(findInvalidSteps);
  };

  const onContinue = () => {
    if (current >= maxStep) {
      setMaxStep(current + 1);
    }
    setCurrent(current + 1);
    checkInvalidSteps();
  };

  const onBack = () => {
    checkInvalidSteps();
    if (current > 1) setCurrent(current - 1);
  };

  const resolveProblem = async () => {
    setConfirm(true);
    if (problemId) {
      apiCompleteProblem(problemId).then((res: any) => { });
      await apiTraceEvent(user?.mail, problemId || '', ProblemStatus.Resolved);
      await apiAddNotification(problemId, problemId, INotificationType.TAG);
    }
  };

  const handleAnswer = (key: any, name: any, val: any) => {
    let copyKey: string;
    if (name == 'answerText') {
      const updatingQuestion = gem.questions.find((q) => q._id == key);
      if (updatingQuestion) {
        if (updatingQuestion.step === 4 && updatingQuestion.questionNo === 'a') {
          //looking for copyKey
          const copyStep5Question = gem.questions.find(
            (q) => q.step === 5 && q.questionNo === 'a' && q.group === updatingQuestion.group,
          );
          if (copyStep5Question) {
            copyKey = copyStep5Question._id;
          }
        }
        if (updatingQuestion.step === 1 && updatingQuestion.questionNo === 'b') {
          setFilterCategory(val);
        }
        if (updatingQuestion.step === 5 && updatingQuestion.questionNo === 'b') {
          //looking for copyKey
          const copyStep6Question = gem.questions.find(
            (q) => q.step === 6 && q.questionNo === 'a' && q.group === updatingQuestion.group,
          );
          if (copyStep6Question) {
            copyKey = copyStep6Question._id;
          }
        }
      }
    }
    if (gem) {
      // pre populate step 4a with step 1g
      let answer1g = '';
      gem.questions.map((q) => {
        if (q.step == 1 && q.questionNo === 'g') {
          answer1g = q.answerText;
        }
      });
      const updatedGem = gem.questions.map((q) => {
        if (answer1g != '' && q.step == 4 && q.questionNo === '-1') {
          if (name[0] == "answerText") {
            return { ...q, [name]: answer1g };
          }
        }
        if (q._id == key) {
          return { ...q, [name]: val };
        }
        if (copyKey && q._id == copyKey) {
          return { ...q, [name]: val };
        }
        return q;
      });
      setGem({ ...gem, questions: updatedGem });
    }
  };

  const handleFocus = (key: any, name: any, val: any) => {
    if (key === '1g' && val.trim() == '') {
      let ready = true;
      const step1Questions = gem.questions.filter((q) => q.step == 1 && q.questionNo != 'g');
      let answer1g = '';
      step1Questions.map((question: IResponseQuestion) => {
        if (!question.answerText) ready = false;
        answer1g = answer1g + question.answerText + ' ';
      });

      if (gem && ready) {
        const updatedGem = gem.questions.map((q) => {
          if (q.step == 1 && q.questionNo === 'g') {
            return { ...q, [name]: answer1g };
          }
          return q;
        });
        setGem({ ...gem, questions: updatedGem });
      }
    }
  };

  const removeGroup = (step: number, group: number) => {
    if (step == 4) { //only step one allow to remove group, remove step 4, 5, 6
      const newquestions = gem.questions.filter((q) => !((q.step == step || q.step == step + 1 || q.step == step + 2) && q.group == group));
      setGem({ ...gem, questions: newquestions });
    }
  };

  const addGroup = (step: number) => {
    const newquestions = getNewStepGroupQuestions(gem, step, step == 4 ? [5, 6] : []);
    setGem({ ...gem, questions: gem.questions.concat(newquestions) });
  };

  const closeConfirm = (result: boolean, achievedBenefit: string) => {
    setConfirm(false);
    if (result) {
      const updatedGem = { ...gem, userId: user.mail, achievedBenefit: achievedBenefit };
      apiUpdateGemResponse(updatedGem).then((res: any) => {
        const resolvedStatus = result;
        apiResolveProblem(gem.problemId, resolvedStatus)
          .then(() => {
            setFeedbackModal(true);
          });
      });
    }
  };

  const saveQuestions = () => {
    const updatedGem = { ...gem, userId: user.mail };
    setSaving(true);
    apiUpdateGemResponse(updatedGem).then((res: any) => {
      setGem(res.data.gemViewDto);
      setTimeout(() => {
        //privde time for user to see the loading icon
        setSaving(false);
      }, Constants.saveLoaderTimeout);
    });
  };

  return (
    <div className="gem-container">
      <div className="gem-navigation-container">
        <div className="title">{headings[0]?.title}</div>
        <div className="subtitle">
          {headings[0]?.subtitle}{' '}
          <BlobLink file={headings[0]?.quote}>
            <span className="Appkit4-icon icon-help-question-outline"></span>
          </BlobLink>{' '}
        </div>
        {headings?.length > 1 && <QuestionStepper
          headings={headings}
          currentStep={current}
          maxStep={maxStep}
          invalidSteps={invalidSteps}
          onNavigate={onNavigate}
        ></QuestionStepper>
        }
       {problemId && <TeamMember problemId={problemId} readOnlyAccess={readOnlyAccess} ></TeamMember> }
      </div>

      <div className="gem-step-container">
        {!readOnlyAccess && 
          <div className="switchbox" title='Private problems are only visible to team members tagged as collaborators'>
            <Switch
              checked={privateProblem} 
              onChange={() => {
                if (problemId) {
                  apiToggleProblemPrivateStatus(problemId, !privateProblem);
                  setPrivateProbem(!privateProblem);
                }
              }}
              label="Private"
              class="gem"
            />
          </div>
        }
        {
          gem.questions.length > 0 && 
          <StepQuestions
            response={gem} questionStep={current} headingStep={current}
            filterCategory={filterCategory}
            onChanged={handleAnswer}
            onFocus={handleFocus}
            onAddGroup={addGroup}
            onRemoveGroup={removeGroup}
            onDropAnswer={(handleAnswer)}
            onDropHyperthesis={(value: IDragHypothesis) => {/*no event handle needed */ }}
            readOnlyAccess={readOnlyAccess}
          ></StepQuestions>
        }

        <hr></hr>
        <div className="button-container">
          {current > 1 && (
            <Button kind="negative" onClick={onBack}>
              Back
            </Button>
          )}
          {current != lastStep && (
            <Button kind="negative" onClick={onContinue} disabled={!nextEnabled}>
              Continue
            </Button>
          )}
          {current == lastStep && (readOnlyAccess === false) && (
            <Button kind="negative" onClick={resolveProblem} disabled={!validated}>
              Problem Solved
            </Button>
          )}
          {!readOnlyAccess &&
            <Button
              kind="secondary"
              className=".ap-border-primary-red-01"
              onClick={saveQuestions}
              loading={saving}
            >
              Save
            </Button>
          }
        </div>
      </div>

      <GemConfirmModal show={confirm} onClose={closeConfirm} gem={gem}></GemConfirmModal>
      {feedbackModal && <Feedback companyId={gem.companyId} problemId={gem.problemId}></Feedback>}
    </div>
  );
};
