import React, { useEffect, useState } from 'react'
import { IResponseQuestion } from '../../models/IResponseQuestion'
import { IStepHeading } from '../../models/IStepHeading'
import { groupArrayOfObjects } from '../../utils/Utils'
import { Draggable } from '../Draggable/Draggable'
import { QuestionGridRow } from '../QuestionGrid/QuestionGridRow'


import "./QuestionDraggable.css"

//Component for Drag item to drop table
export const QuestionDraggable = (props: { step: number, stepQuestions: IResponseQuestion[], heading: IStepHeading, onChanged(key: string, name: string, vals: any): any, readOnlyAccess: boolean }) => {

  const [groups, setGroups] = useState({});

  const [items, setItems] = useState<string[]>([]);

  useEffect(() => {
    setGroups(groupArrayOfObjects(props.stepQuestions, "group"));
    const selectedItems = props.stepQuestions.filter((p: IResponseQuestion) => p.questionType === 'drag' && p.step == props.step && p.answerText != "").map((p:IResponseQuestion)=>p.answerText);
    const allItems = props.stepQuestions.filter((p:IResponseQuestion)=>p.questionType === 'drag' && p.step == props.step)[0].answerOptions
    const remainItems = allItems.filter( ( el ) => !selectedItems.includes( el ) );
    setItems(remainItems)

  }, [props.stepQuestions])


  const handleAnswer = (key: any, name: any, val: any) => {
    props.onChanged(key, name, val);
  }

  return (
    <div className='dragdrop-container'>

      <div className="step-title">Step {props.heading.stepInTitle?props.heading.stepInTitle:props.heading.step}: {props.heading.title} </div>
      <div className="step-subtitle">
        {props.heading.subtitle} <span className="question-red-star">*</span>
      </div>

      <div className="drag-drop">

        <div className="draggable">
        <div className="drag-instruction" >
            <div><span className="Appkit4-icon icon-information-outline"></span></div>
            <div className="drag-instructino-content">{props.heading.quote}</div>
          </div>
          {
            
             items.length>=0 && items.map((item:any, index:number)=>{ return <Draggable key={item} name={item} id={item} readOnlyAccess={props.readOnlyAccess} ></Draggable>})
          }
        </div>
        <div className="droppable">
          {
            groups && Object.keys(groups).map(
              (keyName: string, i: number) => {
                return (
                  <QuestionGridRow key={props.step + keyName} group={Number(keyName)} stepGroupQuestions={groups[keyName as keyof typeof groups]} onChanged={handleAnswer} readOnlyAccess={props.readOnlyAccess}></QuestionGridRow>
                )
              }
            )
          }

        </div>
      </div>
    </div>

  )
}
