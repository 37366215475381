import { Button, Modal } from '@appkit4/react-components';
import React, { useEffect, useState } from 'react'
import { IProblem } from '../../models/IProblem';
import { Constants, RequestStatus } from '../../utils/Constants';
import "./RequestModal.css"


//Popup Modal for Request Sending, Approve, Reject
export const RequestModal = (props: {
    show: boolean;
    type: string;
    problem: IProblem;
    onClose(ok: boolean, type:string, message: string): any;
}) => {
    const [visible, setVisible] = useState(false);
    const [message, setMessage] = useState('');


    useEffect(() => {
        setVisible(props.show);
    }, [props.show]);

    const closeModal = (result: boolean) => {
        props.onClose(result, props.type, message);
    };

    const messageChanged = (event: any) => {
        setMessage(event.target.value);
    };

    return (

        <div className="request-modal-container">
            <Modal
                visible={visible}
                closable={false}
                header={<strong className="request-modal-header-title">{props.type == RequestStatus.Pending && <span>Contact CI Coach</span>}{props.type == RequestStatus.Approved && <span>Approve Problem Solving</span>}{props.type == RequestStatus.Declined && <span>Decline</span>} </strong>}
                footer={
                    <>
                        <Button className="request-modal-confirm-button" kind="text" onClick={() => closeModal(false)}>
                            Cancel
                        </Button>
                        <Button className="request-modal-confirm-button" kind="negative" onClick={() => closeModal(true)}>
                            {props.type == RequestStatus.Pending && <span>Contact CI Coach</span>}{props.type == RequestStatus.Approved && <span>Approve</span>}{props.type == RequestStatus.Declined && <span>Decline</span>}
                        </Button>
                    </>
                }
            >
                <div className="request-modal-body-container">
                    {props.type == RequestStatus.Pending && <div>
                        Please specify any additional details you would like to provide to the CI coach to review and approved this problem as a Strategic problem.
                    </div>}
                    {props.type == RequestStatus.Approved && <div>
                        Do you want to approve {props.problem.owner}’s request for solving {props.problem.title}
                    </div>}
                    {props.type == RequestStatus.Declined && <div>
                        Do you want to decline {props.problem.owner}’s request for solving {props.problem.title}?
                    </div>}

                    <div>
                        {props.type != RequestStatus.Approved && <textarea
                            className="message-input"
                            maxLength={Constants.textMaxCharacterLength}
                            name="message"
                            value={message}
                            onChange={messageChanged}
                        ></textarea>}
                    </div>
                </div>
            </Modal>
        </div>
    )
}
