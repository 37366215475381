import { useContext, useEffect, useState } from 'react';
import { IProblem } from '../../models/IProblem';
import { ProblemList } from '../ProblemList/ProblemList';
import { UserContext } from '../UserContext/UserContext';
import { Pagination } from '@appkit4/react-components/pagination';
import { useNavigate } from 'react-router-dom';
import './buckets.css';
import { Loading } from '@appkit4/react-components/loading';
import { Constants } from '../../utils/Constants';
import { Search } from '@appkit4/react-components/search';
import { apiProblemPagination } from '../../services/apiService';

export const LeftBucket = (props: { bucketType: string, user: any }) => {
  const [user, setUser] = useContext(UserContext);
  const [leftProblems, setLeftProblems] = useState<{ data: IProblem[] }>({ data: [] });
  const [wasLastList, setWasLastList] = useState(false);
  const [currPageLeft, setCurrPageLeft] = useState(1); // storing current page number
  const [prevPageLeft, setPrevPageLeft] = useState(0); // storing prev page number
  const [wasLastListLeft, setWasLastListLeft] = useState(false);
  const [numberOfPagesLeft, setNumberOfPagesLeft] = useState(0);
  const [pageModeLeft, setPageModeLeft] = useState('');
  const [networkError, setNetworkError] = useState('');
  const [loading, setLoading] = useState(true);
  const problemsPerPage = Constants.leftBucketProblemsPerPage;
  const navigate = useNavigate();
  const [showPreference, setShowPreference] = useState(false);
  const [searchText, setSearchText] = useState("");

  const onPageChangeLeft = (page: number) => {
    setLoading(true);
    setCurrPageLeft(page);
  };

  const updateProblemLeft = async (prefs: any, callType: string) => {
    try {
      const leftPreferences = {
        companyId: process.env.REACT_APP_COMPANYID,
        field: 'business-unit',
        preferences: [],
        userId: user?.mail,
        bucket: props.bucketType,
        query: searchText
      };
      if (user?.mail) {
        setPageModeLeft(callType);
        const leftResponse = await apiProblemPagination((currPageLeft - 1) * problemsPerPage, problemsPerPage, leftPreferences);
        //check if response is empty...
        if (leftResponse.data.ProblemsBetweenIndex.length != 0 && callType == 'pagination') {
          setPrevPageLeft(currPageLeft);
        }

        if (leftResponse.data.TotalNumOfPages != 0) {
          setNumberOfPagesLeft(leftResponse.data.TotalNumOfPages);
        }
        setLeftProblems({ data: leftResponse.data.ProblemsBetweenIndex });
        setLoading(false);
      }
    } catch (err) {
      setNetworkError('Server Error....');
    }
  };

  const handleProblemListChange = async () => {
    // re-populate the list by decrementing current page by 1
    setLoading(true);
    if (leftProblems.data.length == 1 && currPageLeft > 1) {
      setCurrPageLeft(currPageLeft-1);
    } else {
      await updateProblemLeft([], 'pagination');
    }
    setLoading(false);
  }

  const handleSearchText = async () => {
    setLoading(true);
    setCurrPageLeft(1);
    await updateProblemLeft([], 'pagination');
    setLoading(false);
  }

  useEffect(() => {
    const fetchLeftData = async () => {
      updateProblemLeft([], 'pagination');
    };

    if (!wasLastListLeft && prevPageLeft !== currPageLeft && user) {
      fetchLeftData();
    }
  }, [currPageLeft, wasLastListLeft, prevPageLeft, user]);

  useEffect(() => {
    const getData = setTimeout(() => {
      handleSearchText();
    }, Constants.debounceTime)
    return () => clearTimeout(getData)
  }, [searchText]);

  return (
    <>
      <Search 
        className="search-bar"
        searchType="secondary"
        onSearch={handleSearchText}
        searchValue={searchText}
        onChange={(val) => {setSearchText(val)}}
      ></Search>
      {leftProblems.data.length != 0 && !loading && (
        <ProblemList problems={leftProblems.data} bucket={props.bucketType} onChange={handleProblemListChange}></ProblemList>
      )}
      {loading && (
        <div className="loader">
          {' '}
          <Loading
            className="scale-[3px] sm:scale-[30px]"
            loadingType="circular"
            indeterminate={true}
            compact={false}
          ></Loading>
        </div>
      )}
      {leftProblems.data.length != 0 && !loading && numberOfPagesLeft > 1 && (
        <Pagination
          className="max-h-[50px] p-0 text-xs items-center  min-w-0"
          current={currPageLeft}
          total={numberOfPagesLeft}
          onPageChange={onPageChangeLeft}
        ></Pagination>
      )}
      {leftProblems.data.length == 0 && !loading && props.bucketType === "left" && (
        <div className="no-records">You have not posted any problems yet</div>
      )}
      {leftProblems.data.length == 0 && !loading && props.bucketType === "tag" && (
        <div className="no-records">You are not tagged in any problems yet</div>
      )}
      {networkError != '' && <div className="preferences">Server Error...</div>}
    </>
  );
};
