import React, { useEffect, useState } from 'react'
import { Button, Modal } from '@appkit4/react-components';
import "./HelpModal.css"
import { apiGetLookup } from '../../services/apiService';
import { ILookup } from '../../models/ILookup.type';

export const HelpModal = (props: { onClose: any }) => {
    const [visible, setVisible] = useState(false);
    const [helpLink, setHelpLink] = useState("");
    const [helpContact, setHelpContact] = useState("");


    useEffect(() => {
        setVisible(true);
        apiGetLookup(`${process.env.REACT_APP_HELP_LINK1}`).then(
            (res: any) => {
                let values = res.data.lookups.map((option: ILookup) => {
                    return option.value;
                });
                if (values) setHelpLink(values[0]);
            }
        );
        apiGetLookup(`${process.env.REACT_APP_HELP_LINK2}`).then(
            (res: any) => {
                let values = res.data.lookups.map((option: ILookup) => {
                    return option.value;
                });
                if (values) setHelpContact(values[0]);
            }
        );

    }, []);

    const handleClose = () => {
        setVisible(false);
    }
    
    return (
        <>
            <Modal
                visible={visible}
                closable={true}
                maskCloseable={false}
                onCancel={handleClose}
                className="profile-modal"
                header={<strong className='help-header-title'>Help</strong>}
                footer={
                    <>
                        <Button className="help-modal-button" kind="negative" onClick={() => props.onClose()}>
                            Close
                        </Button>
                    </>
                }
            >
                <div className="help-container">
                    <div className="help-section">
                        <div className="help-title"><a href={helpLink} target="_blank">Continuous Improvement Sharepoint page</a></div>
                        <div className="help-content"><a href={helpLink} target="_blank">{helpLink}</a></div>
                    </div>
                    <div className="help-section">
                        <div className="help-title"><a href='mailto:${helpContact}' target="_blank">Technical support</a></div>
                        <div className="help-content"><a href='mailto:${helpContact}' target="_blank">{helpContact}</a></div>
                    </div>
                </div>
            </Modal>
        </>
    );
}
