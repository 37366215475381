/* A component to register new problem */
import { useContext, useEffect, useState, useMemo } from 'react';
import { Combobox } from '@appkit4/react-components';
import { useNavigate, useParams } from 'react-router-dom';
import { CalendarPicker } from '@appkit4/react-components/datepicker';
import { Button } from '@appkit4/react-components/button';
import { Switch } from '../Switch/Switch';
import { v4 as uuidv4 } from 'uuid';
import { Tag } from '@appkit4/react-components/tag';
import { DropdownList } from '../../components/DropdownList';
import { UserContext } from '../UserContext/UserContext';
import { IProblem } from '../../models/IProblem';
import { IUserTag } from '../../models/IUserTag';
import { BlobLink } from '../Blobs/BlobLink';
import './Register.css';
import { AppPages, Constants, ProblemStatus } from '../../utils/Constants';
import { apiGetProblem, apiSetFirstTimeUserToFalse, apiSubmitProblem, apiTagUser, apiTraceEvent, apiUpdateProblem } from '../../services/apiService';
import { getInitProblem, validateUserReadOnlyAccess } from '../../utils/QuestionUtil';
export const Register = () => {
  //set min Date as tomorrow not today
  const tomorrow = new Date();
  tomorrow.setHours(24, 0, 0, 0);

  const [problemPrivate, setProblemPrivate] = useState(false);
  const [minDate, setMinDate] = useState<Date>((tomorrow));
  const [user] = useContext(UserContext);
  const [validated, setValidated] = useState(false);
  const [data, setData] = useState<IUserTag[]>([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const { problemId } = useParams();
  const [readOnlyAccess, setReadOnlyAccess] = useState<boolean>(false);

  let initProblem: IProblem = {
    _id: uuidv4().toString(),
    companyId: process.env.REACT_APP_COMPANYID || '',
    userId: user?.mail || 'anonymous',
    owner: user ? user.givenName + ' ' + user.surname : '',
    title: '',
    description: '',
    reportTo: '',
    teamMember: [],
    location: '',
    observedUnits: [],
    impactedUnits: [],
    impactedMetrics: '',
    targetDate: new Date(),
    createdAt: new Date(),
    status: '',
    resolved: false,
    numOfLikes: '',
    actionType: '',
    requestStatus: '',
    requestMessage: '',
    requestStatusUpdatedBy: "",
    requestStatusUpdatedOn: new Date(),
    updatedBy: '',
    updatedAt: new Date(),
    private: false
  };

  const [problem, setProblem] = useState(initProblem);

  const handleChange = (event: any) => {
    setProblem({ ...problem, [event.target.name]: event.target.value });
  };
  /*
   *Fetches team members list
   * @params {event} event- the combobox search input event that holds the input value
   * add the list to combobox data
   * */
  const fetchTeamMemberUsers = async () => {
    let _data: IUserTag[] = [{ desc: 'users', type: 'group', children: [] }];
    //get the list of users that match a search string
    const userList = await apiTagUser(searchText);
    const children:any[] = [];
    //add the returned list to the user group list
    userList.data.allUsersContainingString.map((userDetails: any) => {
      children.push({
        value: userDetails.email,
        desc:
          userDetails.email + '  ' + '(' + userDetails.firstName + ' ' + userDetails.lastName + ')',
      });
    });
    children.sort((a:any, b:any)=>{
      return a.desc?.toLowerCase()>b.desc?.toLowerCase()? 1:-1
    });
    _data[0].children.push(...children);
    setData(_data);
  };

  const validInput = () => {
    if (
      !problem.owner ||
      !problem.title ||
      !problem.description ||
      problem.reportTo === '' ||
      !problem.location ||
      !problem.observedUnits ||
      !problem.impactedUnits ||
      !problem.impactedMetrics ||
      problem.observedUnits.length === 0 ||
      problem.impactedUnits.length === 0 ||
      !problem.targetDate
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!validInput()) {
      return null;
    }

    if (readOnlyAccess && problemId) {
      navigate(`/${AppPages.INTAKE}/${problemId}`);
    } else {
      //set the first time user to false
      await apiSetFirstTimeUserToFalse(user.mail);

      if (problemId) {
        apiUpdateProblem(problem._id, problem)
          .then((res) => {
            navigate(`/${AppPages.INTAKE}/${res.data.newRegisterProblem._id}`);
          });
      } else {
        const trainingCompleted = user?.trainings?.find(
          (t: any) => t.completed === false && t.required === true,
        );
        if (trainingCompleted) {
          navigate(`/${AppPages.TRAINING}`);
        } else {
          apiSubmitProblem(problem).then((res) => {
            apiTraceEvent(user?.mail, res.data.newRegisterProblem._id, ProblemStatus.Intake).then(() => {
              navigate(`/${AppPages.INTAKE}/${res.data.newRegisterProblem._id}`);
            })
          });
        }
      }
    }

  };
  //load the data of the created problem
  const loadProblem = async () => {
    if (problemId) {
      apiGetProblem(problemId).then((res) => {
        setSelectedUsers(res.data.RegisterProblem.teamMember); //add the tagged users
        setProblem(res.data.RegisterProblem);
      });
    }
  };
  //add the tagged team member to the list and update problem data
  const onSelectUser = (list: any) => {
    setSelectedUsers(list);
    setProblem({ ...problem, ['teamMember']: list });
  };

  //add the tagged team member to reportTo and update problem data
  const onSelectReportsToUser = (user: any) => {
    setProblem({ ...problem, ['reportTo']: user });
  };

  //on clearing combobox, set searchtext to empty and revert to initial list by calling fetchTeamMemberUsers
  const onClear = () => {
    setSearchText('');
    fetchTeamMemberUsers();
  };
  /*
   * removes a selected user
   * @param {number} index- Index of the user to be removed
   * sets the remaining user list to selected user list
   */
  const removeUser = (index: number) => {
    const _selectedUsers = [...selectedUsers];
    _selectedUsers.splice(index, 1);
    setSelectedUsers(_selectedUsers);
    setProblem({ ...problem, ['teamMember']: _selectedUsers });
  };

  const removeReportsToUser = () => {
    if(!readOnlyAccess){
      setProblem({ ...problem, ['reportTo']: '' });
    }
  };

  //capture the search string and set it to searchText
  const handleSearchChange = (event: any) => {
    setSearchText(event.target.value);
  };
  //set the users list to its initial state
  const handleDropDownVisibility = (value: any) => {
    if (!value) setSearchText('');
  };

  useEffect(() => {
    //wait for the debounceTime to call fetchTeamMemberUsers
    const debounce = setTimeout(() => {
      fetchTeamMemberUsers();
    }, Constants.debounceTime);
    return () => clearTimeout(debounce);
  }, [searchText]);

  useEffect(() => {
    fetchTeamMemberUsers();
  }, []);

  useEffect(() => {
    if (!user?.trainingCompleted) {
      navigate(`/${AppPages.TRAINING}`);
    } else if (user) {
      loadProblem();
    }
  }, []);

  useEffect(() => {
    if (user) {
      if (problemId) {
        validateUserReadOnlyAccess(problemId, user?.mail).then((readOnly: boolean) => {
          setReadOnlyAccess(readOnly);
        });
      }
    }
  }, [user, problemId]);

  useEffect(() => {
    const v = validInput();
    setValidated(v);
  }, [problem, selectedUsers]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="register-container">
        <div className="register-bar">Register Problem</div>
        <div className="register-title">Register Problem</div>
        <div className='checkbox' title="Private problems are only visible to team members tagged as collaborators">
          <Switch 
            checked={problemPrivate}
            onChange={() => {
              problem.private = !problemPrivate;
              setProblemPrivate(problem.private);
            }}
            label="Private"
            class="register"
          />
        </div>
        <div className="register-component">
          <label className="registger-label">
            Problem owner<span>*</span>
          </label>
          <div>
            <input
              type={'text'}
              className="register-input"
              name="owner"
              value={problem.owner}
              onChange={handleChange}
              required={true}
              readOnly={true}
            ></input>
          </div>
        </div>
        <div className="register-component">
          <label className="registger-label">
            Problem title<span>*</span>
          </label>
          <input
            type={'text'}
            maxLength={140}
            className="register-input"
            name="title"
            value={problem.title}
            onChange={handleChange}
            required={true}
            readOnly={readOnlyAccess}
          ></input>
        </div>
        <div className="register-component">
          <label className="registger-label">
            Brief description<span>*</span>
          </label>
          <textarea
            className="register-input"
            maxLength={Constants.textMaxCharacterLength}
            name="description"
            value={problem.description}
            onChange={handleChange}
            readOnly={readOnlyAccess}
          ></textarea>
          <div className="charCounter">
            {problem.description.length}/{Constants.textMaxCharacterLength}
          </div>
        </div>
        <div className="register-component">
          <label className="registger-label">
            Reports to<span>*</span>
            <span className="user-not-found-info">
              (If team member not found, please reach out to them to create a profile)
            </span>
          </label>
          {problem.reportTo?.length !== 0 && (
            <Tag onClose={removeReportsToUser}>{problem.reportTo}</Tag>
          )}
        </div>
        {problem.reportTo?.length === 0 && (
          <Combobox
            data={data}
            valueKey={'value'}
            labelKey={'desc'}
            placeholder="Tag team member"
            multiple={false}
            showSelectAll={false}
            value={problem.reportTo}
            onChange={handleSearchChange}
            onClear={onClear}
            onSelect={onSelectReportsToUser}
            onVisibleChange={handleDropDownVisibility}
            disabled={readOnlyAccess}
          ></Combobox>)}
        <div className="register-component">
          <label className="registger-label">
            Team member{" "}
            <span className="user-not-found-info">
              (If team member not found, please reach out to them to create a profile)
            </span>
          </label>
          {selectedUsers?.length !== 0 &&
            (selectedUsers?.map((user, i) => {
              return <Tag key={user} onClose={() => !readOnlyAccess && removeUser(i)}>{user}</Tag>;
            }))
          }
        </div>
        <Combobox
          data={data}
          valueKey={'value'}
          labelKey={'desc'}
          placeholder="Tag team member"
          multiple
          showSelectAll={false}
          value={selectedUsers}
          onChange={handleSearchChange}
          onClear={onClear}
          onSelect={onSelectUser}
          onVisibleChange={handleDropDownVisibility}
          disabled={readOnlyAccess}
        ></Combobox>
        <div className="register-component">
          <label className="registger-label">
            Location where you have observed the problem<span>*</span>
          </label>
          <input
            type={'text'}
            className="register-input"
            name="location"
            value={problem.location}
            onChange={handleChange}
            readOnly={readOnlyAccess}
          ></input>
        </div>
        <div className="register-component">
          <label className="registger-label">
            Units/Department where you observed the problem<span>*</span>
          </label>
          <DropdownList
            multiple={true}
            field="business-unit"
            value={problem.observedUnits}
            onSelected={(vals) => {
              setProblem({ ...problem, ['observedUnits']: vals });
            }}
            readonlyAccess={readOnlyAccess}
          ></DropdownList>
        </div>
        <div className="register-component">
          <label className="registger-label">
            Units/Department impacted by the problem<span>*</span>
          </label>
          <DropdownList
            multiple={true}
            field="business-unit"
            value={problem.impactedUnits}
            onSelected={(vals) => {
              setProblem({ ...problem, ['impactedUnits']: vals });
            }}
            readonlyAccess={readOnlyAccess}
          ></DropdownList>
        </div>
        <div className="register-component">
          <label className="registger-label">
            Primary <BlobLink file={'metrics.pdf'}><text style={{textDecoration: "underline", color: "#221e1f"}}>metric</text></BlobLink> impacted by problem<span>*</span>
          </label>
          <DropdownList
            multiple={false}
            field="metrics"
            value={problem.impactedMetrics}
            onSelected={(vals) => {
              setProblem({ ...problem, ['impactedMetrics']: vals });
            }}
            readonlyAccess={readOnlyAccess}
          ></DropdownList>
        </div>
        <div className="register-component">
          <label className="registger-label">
            Target date to have solved the problem<span>*</span>
          </label>
          <CalendarPicker
            placeholder="mm/dd/yyyy"
            locale="en"
            editable={false}
            value={new Date(problem.targetDate)}
            onValueChange={(vals: Date) => {
              setProblem({ ...problem, ['targetDate']: vals });
            }}
            minDate={(problem.targetDate&&(new Date(problem.targetDate)<minDate))?new Date(problem.targetDate):minDate}
            disabled={readOnlyAccess}
          />
        </div>
        <hr></hr>
        <div>
          <Button
            kind="negative"
            className="register-button"
            disabled={!validated}
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </div>
      </div>
    </form>
  );
};
