import react, { useEffect, useState } from 'react';
import { apiGetImage } from '../../services/apiService';


//show image from blob storage
export const BlobImage = (props:{file:string}) => {

   const [image, setImage] = useState("");

   useEffect(() => {
    apiGetImage(props.file).then(
        (res:any) => {
            setImage(window.URL.createObjectURL(res.data));
        }
    )
   },[])

    return (
        <>
        { image ? <img src={image} /> : <></>}
        </>
    )
}