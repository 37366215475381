
import React, { useEffect, useState } from 'react'


import { Button } from '@appkit4/react-components'
import { IResponseQuestion } from '../../models/IResponseQuestion'
import { groupArrayOfObjects } from '../../utils/Utils'
import { QuestionGridRow } from './QuestionGridRow'

import './QuestionGrid.css'
import { IStepHeading } from '../../models/IStepHeading'


//Grid style components for Step
export const QuestionGrid = (props: { step: number, stepQuestions: IResponseQuestion[],heading: IStepHeading, onChanged(key: string, name: string, vals: any): any, onAddGroup(step:number): any, readOnlyAccess: boolean }) => {

    const [groups, setGroups] = useState({});

    useEffect(() => {
        setGroups(groupArrayOfObjects(props.stepQuestions, "group"));
    }, [props.stepQuestions])

    const handleAnswer = (key: any, name: any, val: any) => {
        props.onChanged(key, name, val);
    }

    return (
        <div className="step-grid-container">
            <div className="step-title">Step {props.heading.stepInTitle?props.heading.stepInTitle:props.heading.step}: {props.heading.title}</div>
            {
                props.heading.subtitle &&  <div className="step-subtitle">
                  {props.heading.subtitle} <span className="question-red-star">*</span>
                </div>
            }
            {
                groups && Object.keys(groups).map(
                    (keyName: string, i: number) => {
                        return (
                            <QuestionGridRow key={props.step + keyName} group={Number(keyName)} stepGroupQuestions={groups[keyName as keyof typeof groups]} onChanged={handleAnswer} readOnlyAccess={props.readOnlyAccess}></QuestionGridRow>
                        )
                    }
                )
            }
        </div>
    )
}