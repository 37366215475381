import React, { useEffect, useState } from 'react';

import { ResultCard } from './ResultCard';
import { Canvas } from './Canvas';

import './GuadrantChart.css';

export const GuadrantChart = (props: {
  action: string;
}) => {
  return (
    <div className="chart-container">
      <div className="chart-wrapper">
        <Canvas></Canvas>
      </div>

      <div className="card-container">
        <ResultCard
          className={props.action == 'Gem' ? 'gem-card active-card' : 'gem-card inactive-card'}
          title="Gem"
          subtitle="A3"
        ></ResultCard>
        <ResultCard
          className={
            props.action == 'Strategic'
              ? 'strategic-card active-card'
              : 'strategic-card inactive-card'
          }
          title="Strategic"
          subtitle="7 Step Framing"
        ></ResultCard>
        <ResultCard
          className={
            props.action == 'Quick Wins'
              ? 'quick-win-card active-card'
              : 'quick-win-card inactive-card'
          }
          title="Quick Wins"
          subtitle="PAR (Problem, Action, Result)"
        ></ResultCard>
        <ResultCard
          className={
            props.action == 'Avoid' ? 'avoid-card active-avoid-card' : 'avoid-card inactive-card'
          }
          title="Avoid"
          subtitle="-"
        ></ResultCard>
      </div>
    </div>
  );
};
