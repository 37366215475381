import { useEffect, useState } from 'react'
import { getPageTrace } from './QuestionUtil';
import { apiTrace } from '../services/apiService';
import { useAccount, useMsal } from '@azure/msal-react';

export const useTrackPage = (page: string, problemId?: any) => {

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const [entryTime, setEntryTime] = useState<Date>(new Date());

  const handleBeforeUnload = () => {
    const endTime = new Date();
    apiTrace(getPageTrace(account?.username || '', page, problemId || '', entryTime, endTime)).then((res) => {
    })
  }

  useEffect(() => {
    const startTime = new Date();
    setEntryTime(startTime);
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      const endTime = new Date();
      apiTrace(getPageTrace(account?.username || '', page, problemId || '', startTime, endTime)).then((res) => {
      })
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, [])
}
