/* Account Popup that appears when the top right user icon is clicked */
import React, { useEffect, useState, useContext } from 'react';
import { useAccount, useMsal } from '@azure/msal-react';
import { UserContext } from '../UserContext/UserContext';
import { Modal } from '@appkit4/react-components';
import logoutImage from '../../assets/images/logout-fill.svg';
import profileIcon from '../../assets/images/profile_icon.svg';
import { handleLogout } from '../../utils/Utils';
import { apiSetFirstTimeUserToFalse, apiBlacklistToken } from '../../services/apiService';

import './AccountPopUp.css';

export const AccountPopUp = (props: { show: boolean; onClose: any; onShowProfile: any }) => {
  /* state to toggle between to show the profile or not. Triggered when the user icon is clicked */
  const [visible, setVisible] = useState(false);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const [user, setUser] = useContext(UserContext);

  /* Handles user logout */
  const handleSubmitLogout = async (instance: any) => {
    await apiSetFirstTimeUserToFalse(user.mail); // set the first time user to false
    await apiBlacklistToken(); // blacklist the token before logging out
    handleLogout(instance);
  };
  useEffect(() => {
    setVisible(props.show);
  });

  /* Opens the profile page to be updated when the profile button is clicked on the popup */
  const openProfile = () => {
    props.onClose();
    props.onShowProfile();
    setVisible(false);
  };
  return (
    <Modal
      visible={visible}
      className="account-modal"
      placement="top"
      modalStyle={{ border: '1px solid #eb1c2c' }}
      bodyStyle={{ marginTop: '0px', padding: '3px 1px 3px 5px' }}
      wrapperStyle={{
        bottom: 'auto',
        left: 'auto',
        marginTop: '2.5%',
        marginRight: '1%',
        borderRadius: '5px',
      }}
    >
      <div className="profile-info">
        <div className="profile-item">
          <div className="i-circle rounded-full h-6 w-6">{user?.mail[0].toUpperCase()}</div>
          <div className="p-item-identity">{user?.mail.toLowerCase()}</div>
        </div>
        <div className="profile-item" onClick={openProfile}>
          <img className="p-item-profile-img" src={profileIcon} />
          <div></div>
          <div className="p-item-profile">Profile</div>
        </div>
        <div className="profile-item" onClick={() => handleSubmitLogout(instance)}>
          <img className="p-item-logout-img" src={logoutImage} />
          <div className="p-item-logout">Logout</div>
        </div>
      </div>
    </Modal>
  );
};
