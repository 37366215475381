/*
* Model that displays a list of users wo have liked the problem
* Accepts: {
*   show: boolean value to display the modal (true -> visible, false -> not visible)
*   problem: an IProblem type related to the like
*   changeModalStatus: function to toggle the visibility of the modal
* }
*/
import React, { useState, useEffect } from 'react';
import { Modal } from '@appkit4/react-components/modal';
import { List, ListItem } from '@appkit4/react-components/list';
import { Avatar} from '@appkit4/react-components/avatar';

import { IProblem } from '../../models/IProblem';
import { ILike } from '../../models/ILike';
import { IListData } from '../../models/IListData';

import { Constants } from '../../utils/Constants';
import { apiGetAllUsersWhoLikedProblem } from '../../services/apiService';

import "./LikeModal.css";

export const LikeModal = (props: {
    show: boolean;
    problem: IProblem;
    changeModalStatus: any;
}) => {
    const [visible, setVisible] = useState(false);
    const [usersWhoLikedProblem, setUsersWhoLikedProblem] = useState<ILike[]>([]);
    const [userData, setUserData] = useState<IListData[]>([]);

    useEffect(() => {
        setVisible(props.show);
        fetchAllUsersToLikeProblem();
    }, []);

    useEffect(() => {
        let list: IListData[] = [];
        if (usersWhoLikedProblem.length > 0) {
            usersWhoLikedProblem.map(user => {
                const dataObj: IListData = {
                    primary: user.firstName + " " + user.lastName,
                    avatar: user.firstName[0].toLocaleUpperCase() + user.lastName[0].toLocaleUpperCase(),
                    backgroundColor: Constants.backgroundColorForIcon
                }
                list.push(dataObj);
            })
            setUserData(list);
        }
    }, [usersWhoLikedProblem]);

    const handleClose = () => {
        setVisible(false);
        props.changeModalStatus();
    };

    const fetchAllUsersToLikeProblem = async () => {
        if (props.problem._id) {
            apiGetAllUsersWhoLikedProblem(props.problem._id)
            .then((response) => {
                setUsersWhoLikedProblem(response.data.allUsersWhoLikedProblem);
            });
        }
    }

    const renderItem = (item: any, index: number) => {
        return (
            <ListItem
                key={index}
                title={item.primary}
                avatar={
                <div className="ap-list-avatar-container" role="option">
                    <Avatar
                    label={item.avatar}
                    bgColor={item.backgroundColor}>
                    </Avatar>
                </div >
                }
            >
          </ListItem>
        )
    }
    
    return(
        <Modal
            className='like-modal'
            visible={visible}
            closable={true}
            ariaLabel={'Lorem ipsum dolor sit'}
            header={
                <span className="Appkit4-icon icon-close-outline" onClick={handleClose}></span>
            }
            icons={''}
        >
            <List className='user-list' itemKey='id' bordered={true} data={userData} renderItem={renderItem} ></List>
        </Modal>
    );
}