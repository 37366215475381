import { Modal } from "@appkit4/react-components";
import { Button } from "@appkit4/react-components";
import "./QuickWinsConfirmModal.css";

export const QuickWinsConfirmModal = (props: {show: boolean, onClose(result: boolean): any}) => {

    const closeModal = (result: boolean) => {
        props.onClose(result);
    };

    return (
        <Modal
            visible={props.show}
            className="quickwins-confirm"
            header={<strong className="home_profile_title">Confirm</strong>}
            footer={
                <>
                  <Button className="quickwins-confirm-button" kind="text" onClick={() => closeModal(false)}>
                    Cancel
                  </Button>
                  <Button className="quickwins-confirm-button" kind="negative" onClick={() => closeModal(true)}>
                    Problem Solved
                  </Button>
                </>
            }
        >
            <div className="description">
                Please confirm the problem you identified has been resolved and all relevant details have been added
            </div>
        </Modal>
    );
}