import React, { useEffect, useState } from 'react';

import { CalendarPicker } from '@appkit4/react-components';
import { IResponseQuestion } from '../../models/IResponseQuestion';

import './QuestionDatePicker.css';


//Date Picker Component
export const QuestionDatePicker = (props: { question: IResponseQuestion, onChange: any, readOnlyAccess: boolean }) => {

    //set min Date as tomorrow not today
    const tomorrow =  new Date();
    tomorrow.setHours(24,0,0,0);

    const [minDate, setMinDate] = useState<Date>((tomorrow));

    const selectionChanged = (vals: Date) => {
        props.onChange(props.question._id, "answerText", vals)
    }

    return (
        <div className="question-datepicker-container">
            <div className="question">{props.question.step}{props.question.questionNo}. {props.question.question}{props.question.required && <span className="question-red-star">*</span>} </div>
            {props.question.subtitle && <div className="subtitle">
                <div><span className="Appkit4-icon icon-information-outline"></span></div>
                <div className="subtitle-content">{props.question.subtitle}</div>
            </div>}
            <div className="datepicker">
                <CalendarPicker
                    disabled={props.readOnlyAccess}
                    placeholder="mm/dd/yyyy"
                    locale="en"
                    editable={false}
                     value={props.question.answerText||undefined}
                    onValueChange={(vals: Date) => { selectionChanged(vals) }}
                    minDate={(props.question.answerText&&new Date(props.question.answerText)<minDate)?props.question.answerText:minDate}
                    required={false}
                    defaultValue={minDate}
                />
            </div>
        </div>
    )
}