import { useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd'
import { IDragHypothesis } from '../../models/IDragHypothesis';
import { IPoint } from '../../models/IPoint';
import { IResponseQuestion } from '../../models/IResponseQuestion';
import { DragSquare } from '../Draggable/DragSquare';

import "./DropQuestion.css"

// The target components for hypothesis to drop into 
export const DropQuestion = (
  props: { approach: string, className: string, title: string, subtitle: string, stepQuestions: IResponseQuestion[], onDropped(dragHypothesis: IDragHypothesis): any, readOnlyAccess: boolean }
) => {


  let x = 40;
  let y = 40;

  const divStyle = {
    color: 'yellow'
  }

  const [option, setOption] = useState<string>("");

  const [drags, setDrags] = useState<IDragHypothesis[]>([]);

  const [point, setPoint] = useState<IPoint>({ x: 40, y: 40 })

  const rootRef = useRef<any>(null)

  useEffect(() => {
    const filterQuestions = props.stepQuestions.filter((q: any) => q.step == 8 && q.questionNo == '0' && q.answerText == props.approach);

    const dragHypothesises = filterQuestions.map((m: IResponseQuestion) => {
      const group = m.group;
      const qx = props.stepQuestions.find((n: any) => n.step == 8 && n.group == group && n.questionNo == 'x');
      const qy = props.stepQuestions.find((n: any) => n.step == 8 && n.group == group && n.questionNo == 'y');
      const qa = props.stepQuestions.find((n: any) => n.step == 8 && n.group == group && n.questionNo == 'a');
      const dragHypothesis: IDragHypothesis = {
        id: m.group.toString(),
        x: qx?.answerText,
        y: qy?.answerText,
        hypothesis: qa?.answerText,
        approach: props.approach
      }
      return dragHypothesis;
    })

    setDrags(dragHypothesises)


  }, [props.stepQuestions])


  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: "option",
    drop: (item: any, monitor) => onDrop(item, monitor),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))


  const onDrop = (item: any, monitor: any) => {
    const offset = monitor.getClientOffset()

    if (offset && rootRef.current) {
      const rect = rootRef.current.getBoundingClientRect()
      x = offset.x - rect.left;
      y = offset.y - rect.top;
      setPoint({ x: Math.floor(y), y: Math.floor(x) })


      const dragHypothesis: IDragHypothesis = {
        id: item.id,
        x: x.toString(),
        y: y.toString(),
        hypothesis: item.name,
        approach: props.approach
      }

      props.onDropped(dragHypothesis)

    }


  }

  return (
    <div className={props.className} ref={drop} >
      <div className="square-container" ref={rootRef}>
        {
          drags.map((d: IDragHypothesis) => {
            return (
              <DragSquare key={d.id} name={d.hypothesis} id={d.id} x={d.x} y={d.y} readOnlyAccess={props.readOnlyAccess}></DragSquare>
            )
          })
        }
        <div className="result-card-title">{props.title}</div>
        <div className="result-card-subtitle">{props.subtitle}</div>

      </div>

    </div>
  )
}
