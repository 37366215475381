import { useEffect, useState } from 'react';
import { useAccount, useMsal } from '@azure/msal-react';
import { AccountPopUp } from '../AccountPopUp/AccountPopUp';
import { HeaderMobNavMenu } from '../HeaderMobNavMenu/HeaderMobNavMenu';
import { ProfileModal } from '../Profile/ProfileModal';
import { Notification } from '../Notification/Notification';
import { BlobImage } from '../Blobs/BlobImage';
import { apiCheckUnseenNotificationsPresent } from '../../services/apiService';

import './Header.css';
import { HelpModal } from '../Help/HelpModal';

export const Header = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showHeaderMobNavMenu, setHeaderMobNavMenu] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [notificationsAvailable, setNotificationsAvailable] = useState<boolean>(false);
  const [showHelpModal, setShowHelpModal] = useState(false);

  useEffect(() => {
    checkUnseenNotificationsAvailable();
  }, []);

  const profiledCreated = () => {
    setShowProfile(false);
  };

  const onNotificationClose = () => {
    setShowNotificationModal(false);
    checkUnseenNotificationsAvailable();
  };

  const onHelpModalClose = () => {
    setShowHelpModal(false);
  };

  const checkUnseenNotificationsAvailable = async () => {
    apiCheckUnseenNotificationsPresent().then((res) => {
      setNotificationsAvailable(res.data);
    });
  };

  return (
    <header className="header-container shadow-sm">
      <div className="logoImg w-[50%] sm:w-[40%]">
        <BlobImage
          file={`logo_${process.env.REACT_APP_COMPANYID?.toLocaleLowerCase()}.jpg`}
        ></BlobImage>
        <span>{process.env.REACT_APP_LOGIN_TITLE}</span>
      </div>
      <div className="navbar justify-end w-[40%] sm:w-[100%]">
        <div className="hidden sm:flex">
          <div
            className="Appkit4-icon icon-notification-outline ap-font-20 sm:ap-font-24 icon-spacing mt-1 mx-0"
            onClick={() => setShowNotificationModal(true)}
          ></div>
          {notificationsAvailable && <div className="notifications-available-dot"></div>}
          <div
            className="Appkit4-icon icon-help-question-outline ap-font-20 icon-spacing mt-1 mx-3"
            onClick={() => setShowHelpModal(!showHelpModal)}
          ></div>
          <div
            className="i-circle-header hidden sm:bg-[#d04a02] sm:block mr-7 rounded-full h-6 w-6"
            onClick={() => setShowAccountModal(!showAccountModal)}
          >
            {account?.name ? account.name[0] : ''}
          </div>
        </div>
        <div
          className="space-y-1 sm:hidden"
          onClick={() => setHeaderMobNavMenu(!showHeaderMobNavMenu)}
        >
          {notificationsAvailable && (
            <div className="notifications-available-dot ml-[10px] z-10"></div>
          )}
          <span className="block w-5 h-0.5 bg-gray-600"></span>
          <span className="block w-5 h-0.5 bg-gray-600"></span>
          <span className="block w-3 h-0.5 bg-gray-600"></span>
        </div>
      </div>
      {showHeaderMobNavMenu && (
        <HeaderMobNavMenu
          onClose={() => setHeaderMobNavMenu(false)}
          onShowProfile={() => setShowProfile(true)}
          onShowNotification={() => setShowNotificationModal(true)}
          onShowHelp={() => setShowHelpModal(true)}
          show={showHeaderMobNavMenu}
        ></HeaderMobNavMenu>
      )}
      {showAccountModal && (
        <AccountPopUp
          onClose={() => setShowAccountModal(false)}
          onShowProfile={() => setShowProfile(true)}
          show={showAccountModal}
        ></AccountPopUp>
      )}
      {showProfile && <ProfileModal onCreated={profiledCreated} flag="update"></ProfileModal>}
      {showNotificationModal && (
        <Notification show={showNotificationModal} onclose={onNotificationClose}></Notification>
      )}
      {showHelpModal && <HelpModal onClose={onHelpModalClose}></HelpModal>}
    </header>
  );
};
