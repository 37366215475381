import { useContext } from 'react';
import { Layout } from '../../components/Layout/Layout';
import { Register } from '../../components/Register/Register';
import { UserContext } from '../../components/UserContext/UserContext';
import { AppPages } from '../../utils/Constants';
import { useTrackPage } from '../../utils/useTrackPage';

export const RegisterProblem = () => {
  const [user, setUser] = useContext(UserContext);
  useTrackPage(AppPages.PROBLEM);
  return <Layout currentTab="problem">{
    user && <Register></Register>
  }</Layout>;
};
