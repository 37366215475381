import React, { useContext } from 'react'
import { Approval } from '../../components/Approval/Approval'
import { Layout } from '../../components/Layout/Layout'
import { UserContext } from '../../components/UserContext/UserContext';


//Approve Request Page
export const ApprovalPage = () => {
  const [user, setUser] = useContext(UserContext);
  return (
    <Layout currentTab="requests">
      {user && <Approval></Approval>}
    </Layout>
  )
}
