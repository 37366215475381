import React, { useContext, useEffect, useState } from 'react';

import { Button } from '@appkit4/react-components';
import DOMPurify from 'dompurify';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { IQuestionnaire } from '../../models/IQuestionnaire';
import { GuadrantChart } from './GuadrantChart';
import './ResultChart.css';
import { BlobLink } from '../Blobs/BlobLink';
import {
  apiAddNotification,
  apiGetLookup,
  apiGetProblem,
  apiGetQuestionnaire,
  apiSetLastPage,
  apiUpdateRequestStatus,
} from '../../services/apiService';
import { ILookup } from '../../models/ILookup.type';
import { AppPages, RequestStatus } from '../../utils/Constants';
import {
  getInitProblem,
  getInitQuestionnaire,
  validateUserReadOnlyAccess,
} from '../../utils/QuestionUtil';
import { RequestModal } from '../Modal/RequestModal';
import { IProblem } from '../../models/IProblem';
import { INotificationType } from '../Notification/INotificationType';
import { UserContext } from '../UserContext/UserContext';

export const ResultChart = () => {
  const navigate = useNavigate();
  const [user] = useContext(UserContext);
  const { problemId } = useParams();

  const [a3pdf, setA3pdf] = useState('');
  const [parpdf, setParpdf] = useState('');
  const [showRequestModal, setShowRequestModal] = useState<boolean>(false);
  const [readOnlyAccess, setReadOnlyAccess] = useState<boolean>(false);

  const initQuestionnaire: IQuestionnaire = getInitQuestionnaire();

  const [problem, setProblem] = useState<IProblem>(getInitProblem());

  const loadQuestionaire = async () => {
    // set last problem page to intake
    const response = await apiSetLastPage(problemId || '', AppPages.RESULT);

    if (problemId) {
      apiGetProblem(problemId).then((res) => {
        setProblem(res.data.RegisterProblem);
      });
    }
  };

  const handleClick = async () => {
    const action = problem.actionType;
    if (action === 'Gem') {
      navigate(`/${AppPages.GEM}/${problem._id}`);
    } else if (action === 'Quick Wins') {
      navigate(`/${AppPages.QUICKWINS}/${problem._id}`);
    } else if (action === 'Strategic') {
      //change to in progress
      apiUpdateRequestStatus(problemId || '', {
        requestStatus: RequestStatus.InProgress,
        requestMessage: '',
      }).then(() => {
        navigate(`/${AppPages.STRATEGIC}/${problem._id}`);
      });
    }
  };

  const openModal = () => {
    setShowRequestModal(true);
  };

  const Reevaluate = () => {
    apiUpdateRequestStatus(problemId || '', { requestStatus: '', requestMessage: '' }).then(() => {
      navigate(`/${AppPages.INTAKE}/${problem._id}`);
    });
  };

  const closeModal = (result: boolean, status: string, message: string) => {
    setShowRequestModal(false);
    if (result) {
      apiUpdateRequestStatus(problemId || '', {
        requestStatus: status,
        requestMessage: message,
      }).then(() => {
        apiAddNotification(problemId || '', problemId || '', INotificationType.Request).then(() => {
          navigate('/');
        });
      });
    }
  };

  useEffect(() => {
    loadQuestionaire();
    apiGetLookup(`${process.env.REACT_APP_PDF_GEM1}`).then((res: any) => {
      let pdfs = res.data.lookups.map((option: ILookup) => {
        return option.value;
      });
      if (pdfs) setA3pdf(pdfs[0]);
    });
    apiGetLookup(`${process.env.REACT_APP_PDF_QUICKWIN1}`).then((res: any) => {
      let pdfs = res.data.lookups.map((option: ILookup) => {
        return option.value;
      });
      if (pdfs) setParpdf(pdfs[0]);
    });
  }, []);

  useEffect(() => {
    if (user) {
      if (problemId) {
        validateUserReadOnlyAccess(problemId, user?.mail).then((readOnly: boolean) => {
          setReadOnlyAccess(readOnly);
        });
      }
    }
  }, [user, problemId]);

  return (
    <div className="result-chart-container">
      <div className="title">
        {(problem.actionType == 'Quick Wins' || problem.actionType == 'Gem') && (
          <div>Congratulations!</div>
        )}
        <div>
          Your problem can be categorized as a
          <span className="result-red">{problem.actionType}</span>
        </div>
      </div>
      <div className="content">
        {problem.actionType == 'Gem' && (
          <div className="description">
            <div className="paragraph">
              Based on the inputs provided your problem classifies under the Gem category (High
              impact & High ease of implementation). The best tool to use for this type of problem
              is the A3 template.
            </div>
            <div className="paragraph">
              If you need guidance on how to use the tool, please refer to the{' '}
              <BlobLink file={a3pdf}>link here</BlobLink>.
            </div>
            <div className="paragraph hidden sm:block ">
              {!readOnlyAccess && (
                <Button kind="negative" className="action-button " onClick={handleClick}>
                  Start Solving
                </Button>
              )}
            </div>
            <div className="paragraph sm:hidden">
              {!readOnlyAccess && (
                <div className="flex flex-row items-center justify-center mt-[10px] w-[300px]  p-1 rounded-[2px] min-w-[30px] gap-1">
                  <svg
                    className="h-6 w-6 text-[#b8ad17]"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <circle cx="12" cy="12" r="10" /> <line x1="12" y1="16" x2="12" y2="12" />{' '}
                    <line x1="12" y1="8" x2="12.01" y2="8" />
                  </svg>
                  <div className=" text-red-500">Switch to desktop to continue solving.</div>
                </div>
              )}
            </div>
          </div>
        )}
        {problem.actionType == 'Quick Wins' && (
          <div className="description">
            <div className="paragraph">
              Based on the inputs provided your problem classifies under the quick win category (Low
              impact & High ease of implementation). The best tool to use for this type of problem
              is PAR (Problem, Action, Result).
            </div>
            <div className="paragraph">
              If you need guidance on how to use the tool, please refer the{' '}
              <BlobLink file={parpdf}>training materials here</BlobLink>
            </div>
            <div className="paragraph">
              {!readOnlyAccess && (
                <Button kind="negative" className="action-button" onClick={handleClick}>
                  Start Solving
                </Button>
              )}
            </div>
          </div>
        )}
        {problem.actionType == 'Strategic' && (
          <div className="description">
            <div className="paragraph">
              Based on the inputs provided your problem classifies under the Strategic category
              (High impact &amp; Low ease of implementation)
            </div>

            {!readOnlyAccess && (
              <>
                <div className="paragraph strategic hidden sm:block">
                  {(problem.requestStatus == RequestStatus.Approved ||
                    problem.requestStatus == RequestStatus.InProgress) && (
                    <>
                      <div className="">Continue to solve problem</div>
                      <Button kind="negative" className="action-button" onClick={handleClick}>
                        Start Solving Problem
                      </Button>
                    </>
                  )}
                  {(!problem.requestStatus || problem.requestStatus == '') && (
                    <>
                      <div className="">Please contact your CI Team for more information</div>
                      <Button kind="negative" className="action-button" onClick={openModal}>
                        Contact CI Coach
                      </Button>
                    </>
                  )}
                  {problem.requestStatus == RequestStatus.Declined && (
                    <>
                      <div className="">Re-evaluate your problem intake form</div>
                      <Button kind="negative" className="action-button" onClick={Reevaluate}>
                        Re-evaluate Problem
                      </Button>
                    </>
                  )}
                </div>
                <div className="paragraph strategic sm:hidden">
                  <div className="flex flex-row items-center justify-center mt-[10px] w-[300px]  p-1 rounded-[2px] min-w-[30px] gap-1">
                    <svg
                      className="h-6 w-6 text-[#b8ad17]"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <circle cx="12" cy="12" r="10" /> <line x1="12" y1="16" x2="12" y2="12" />{' '}
                      <line x1="12" y1="8" x2="12.01" y2="8" />
                    </svg>
                    <div className=" text-red-500">Switch to desktop to continue solving.</div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        {problem.actionType == 'Avoid' && (
          <div className="description">
            <div className="paragraph">
              Based on the inputs provided your problem classifies under the Avoid category
            </div>
            <div className="paragraph">
              Please reach out to the Continuous improvement team for further guidance
            </div>
          </div>
        )}

        <div className="chart">
          <GuadrantChart action={DOMPurify.sanitize(problem.actionType)}></GuadrantChart>
        </div>
      </div>
      <RequestModal
        show={showRequestModal}
        type={RequestStatus.Pending}
        problem={problem}
        onClose={closeModal}
      ></RequestModal>
    </div>
  );
};
