import React, { useContext, useEffect, useState } from 'react';

import DOMPurify from 'dompurify';
import { UserContext } from '../UserContext/UserContext';
import { useAccount, useMsal } from '@azure/msal-react';
import { ITraining } from '../../models/ITraining';
import './TrainingTopic.css';
import { BlobImage } from '../Blobs/BlobImage';
import { BlobLink } from '../Blobs/BlobLink';
import { apiFindUser, apiUpdateUserTraining } from '../../services/apiService';

export const TrainingTopic = (props: { training: ITraining; onChange: any }) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);

  const [user, setUser] = useContext(UserContext);
  const [trainings, setTraining] = useState<{ title: string; completed: boolean }[]>([]);

  const updateTraining = (title: string) => {
    apiUpdateUserTraining(account?.username, title).then((res) => {
      props.onChange(res.data.newUserProfile);
      setTraining(res.data.newUserProfile.trainings);
    });
  };

  useEffect(() => {
    if (user)
      apiFindUser(account?.username).then((res) => {
        if (res.data.userProfile) {
          setTraining(res.data.userProfile.trainings);
        }
      });
  }, []);

  return (
    <div className="training-topic-container">
      <div className="logo-container">
        <BlobImage file={`${props.training.image}`}></BlobImage>
      </div>
      <div className="flex flex-col sm:flex-row sm:w-[100%]">
        <div className="training-topic-description">
          <div className="training-topic-title-container ">
            <div className="training-topic-title">{props.training.title} </div>
            <div className="flex mb-2 items-start justify-start sm:justify-start">
              <div>
                {props.training.required && (
                  <div className="training-mandatory-flag">Mandatory</div>
                )}
              </div>
              <div>
                {trainings?.find(
                  (t) => t.title == props.training.title && t.completed === true,
                ) && <div className="training-completed-flag">Completed</div>}
              </div>
            </div>
          </div>
          <div className="training-topic-subtitle">{props.training.subtitle}</div>
        </div>
        <div className="training-topic-button">
          <BlobLink
            file={`${DOMPurify.sanitize(props.training.fileName)}`}
            onClick={() => updateTraining(props.training.title)}
          >
            Launch
          </BlobLink>
        </div>
      </div>
    </div>
  );
};
