import { IResponse } from "../models/IResponse";
import { IResponseQuestion } from "../models/IResponseQuestion";
import { v4 as uuidv4 } from 'uuid';
import { IProblem } from "../models/IProblem";
import { IQuestionnaire } from "../models/IQuestionnaire";
import { IRequestQuery } from "../models/IRequestQuery";
import { Constants, RequestStatus } from "./Constants";
import { IStepHeading } from "../models/IStepHeading";
import { apiFetchProblemUsingProblemId, apiGetGem, apiGetRole, apiGetStrategic } from "../services/apiService";
import { ILeaderboardFilter } from "../models/ILeaderboardFilter";
import { IQueryFilter } from "../models/IQueryFilter";
import { ITraceEvent } from "../models/ITraceEvent";

export const getInitProblem = (): IProblem => {
  const initProblem: IProblem = {
    _id: '',
    companyId: '',
    userId: '',
    owner: '',
    title: '',
    description: '',
    reportTo: '',
    teamMember: [],
    location: '',
    observedUnits: [],
    impactedUnits: [],
    impactedMetrics: '',
    targetDate: new Date(),
    createdAt: new Date(),
    status: '',
    resolved: false,
    numOfLikes: '',
    actionType: '',
    requestStatus: '',
    requestMessage: '',
    requestStatusUpdatedBy: "",
    requestStatusUpdatedOn: new Date(),
    updatedAt: new Date(),
    updatedBy: '',
    private: false
  };

  return initProblem;
}

export const getInitQuestionnaire = () => {
  const initQuestionnaire: IQuestionnaire = {
    companyId: '',
    problemId: '',
    userId: '',
    action: '',
    questions: [],
    sequence: ''
  };

  return initQuestionnaire;
}

export const getDateOffset = (year: number, month: number, day: number): Date => {
  const date = new Date();
  date.setFullYear(date.getFullYear() + year);
  date.setMonth(date.getMonth() + month);
  date.setDate(date.getDate() + day);
  return date
}

export const getInitLeaderboardFilter = (type: string) => {
  const initLeaderboardFilter: ILeaderboardFilter = {
    companyId: process.env.REACT_APP_COMPANYID || '',
    type: type,
    startDate: getDateOffset(-100, 0, 0),
    endDate: new Date(),
    division: [],
    unit: [],
    search: "",
    problemTypes: []
  }
  return initLeaderboardFilter;
}

export const getInitQueryFilter = () => {
  const initFilter: IQueryFilter = {
    companyId: process.env.REACT_APP_COMPANYID || '',
    startDate: getDateOffset(-100, 0, 0),
    endDate: new Date(),
    divisions: [],
    users: [],
  }
  return initFilter;
}

export const getEventTrace = (userId: string, name: string, problemId: string) => {
  const traceEvent: ITraceEvent = {
    companyId: process.env.REACT_APP_COMPANYID || '',
    userId: userId,
    type: "Stage",
    name: name,
    problemId: problemId,
    startTime: new Date(),
    endTime: new Date(),
    duration: 0
  }
  return traceEvent;
}

export const getPageTrace = (userId: string, page: string, problemId: string, startTime: Date, endTime: Date) => {
  const traceEvent: ITraceEvent = {
    companyId: process.env.REACT_APP_COMPANYID || '',
    userId: userId,
    type: "PageView",
    name: page,
    problemId: problemId,
    startTime: startTime,
    endTime: endTime,
    duration: Math.ceil((endTime.getTime() - startTime.getTime()) / 1000)
  }
  return traceEvent;
}

export function getInitResponse(): IResponse {
  const initResponse: IResponse = {
    companyId: '',
    problemId: '',
    userId: '',
    achievedBenefit: '',
    questions: [],
    headings: []
  };
  return initResponse;
}

export function getMaxStep(response: IResponse): number {
  let maxStep = Math.max(...response.questions
    .filter((q: IResponseQuestion) => (q.questionType != 'hypothesis') && (!q.readonly) && (q.answerText || q.answerAttachments?.length > 0))
    .map((q: IResponseQuestion) => q.step),
  );
  if (maxStep == -Infinity) maxStep = 1;
  return maxStep;
}

export function getLastStep(response: IResponse): number {
  let lastStep = Math.max(...response.questions.map((q: IResponseQuestion) => q.step));
  return lastStep;
}

export function findInvalidSteps(response: IResponse, previousMaxStep: number): number[] {
  return response.questions
    .filter((q: IResponseQuestion) => q.step < previousMaxStep && ((q.answerText == '' && q.required == true) || (q.questionType == "rating" && q.comment == '')))
    .map((q: any) => q.step);
}


export function validateResponse(response: IResponse): boolean {
  const missingAnswer = response.questions.find((q) => !q.answerText && q.required);
  if (missingAnswer) return false;
  else return true;
};

export const getNewStepGroupQuestions = (response: IResponse, step: number, cascadeSteps: number[]): IResponseQuestion[] => {
  const groupIds = response.questions
    .filter((q) => {
      return q.step == step;
    })
    .map((o) => {
      return o.group;
    });
  const maxGroupId = Math.max(...groupIds) + 1;

  const stepGroupQuestions = response.questions.filter((q) => {
    return q.step == step && q.group == 1 && q.questionNo !== '-1';
  });

  let newStepGroupQuestions = stepGroupQuestions.map((q) => {
    return {
      ...q,
      group: maxGroupId,
      _id: uuidv4().toString(),
      answerText: '',
      answerAttachments: [],
    };
  });


  if (cascadeSteps.length > 0) {
    let newQuestions: IResponseQuestion[] = [];
    cascadeSteps.map(cascadeStep => {
      const cascadeStepQuestions = response.questions.filter((q) => {
        return q.step == cascadeStep && q.group == 1;
      });

      const newCascadeStepQuestions = cascadeStepQuestions.map((q) => {
        return {
          ...q,
          group: maxGroupId,
          _id: uuidv4().toString(),
          answerText: '',
          answerAttachments: [],
        };
      });

      newStepGroupQuestions = newStepGroupQuestions.concat(newCascadeStepQuestions);
    })
    return newStepGroupQuestions;
  } else {
    return newStepGroupQuestions;
  }
};

export const getProblemStatus = (problem: IProblem) => {
  if (
    !problem.actionType ||
    problem.actionType == 'Avoid'
  ) {
    return 'Intake';
  } else if (problem.actionType == 'Strategic') {
    if (problem.resolved) return RequestStatus.Solved
    else if (problem.requestStatus) return problem.requestStatus
    else return "In Progress"
  } else {
    return problem.resolved ? 'Solved' : 'In Progress';
  }

};

export const getInitRequestQuery = (pending: boolean, search: string, status: string[], page: number) => {
  const requestQuery: IRequestQuery = {
    companyId: process.env.REACT_APP_COMPANYID || '',
    pending: pending,
    status: status,
    search: search,
    start: (page - 1) * Constants.requestPerPage,
    limit: Constants.requestPerPage
  }
  return requestQuery
}

export const getNameInitials = (name: string) => {
  const names = name.split(' ');
  const initials = `${names[0][0]}${names[names.length - 1][0]}`;
  return initials.toUpperCase();
}

export const getInitStepHeading = (): IStepHeading => {
  const heading: IStepHeading = { step: 0, title: '', subtitle: '', quote: '', style: '', stepInTitle: '' }
  return heading;
}

export const validateUserReadOnlyAccess = async (problemId: string, userId: string) => {
  const res = await apiFetchProblemUsingProblemId(problemId);
  const currentProblem: IProblem = res?.data.RegisterProblem;
  if (currentProblem) {
    if (currentProblem.userId.toLowerCase() === userId.toLowerCase() || currentProblem.teamMember.findIndex(item => item.toLowerCase() === userId.toLowerCase()) >= 0) {
      return false;
    }
    if (currentProblem.actionType === "Gem") {
      const res = await apiGetGem(problemId);
      if (res) {
        const gem: IResponse = res.data.gemViewDto;
        if (isTagUser(gem, userId)) return false;
      }
    }
    if (currentProblem.actionType === "Strategic") {
      const res = await apiGetStrategic(problemId);
      if (res) {
        const strategic: IResponse = res.data.gemViewDto;
        if (isTagUser(strategic, userId)) return false;
      }
    }
    return true;
  }
  return false;
}

export const isTagUser = (problemResponse: IResponse, userId: string) => {
  if (problemResponse) {
    const taguserQuestion = problemResponse.questions.find((q: IResponseQuestion) => q.questionType === "tagusers");
    if (taguserQuestion) {
      if (Array.isArray(taguserQuestion.answerText)) {
        if (taguserQuestion.answerText.includes(userId)) {
          return true;
        }
      }
    }
  }
  return false;
}

export const getUpdatedFilter = (value: string, filter:IQueryFilter): IQueryFilter => {
  switch (value) {
    case "All Time":
     return { ...filter, ["endDate"]: new Date(), ["startDate"]: getDateOffset(-100, 0, 0) }
    case "Yearly":
      return { ...filter, ["endDate"]: new Date(), ["startDate"]: getDateOffset(-1, 0, 0) }
    case "Monthly":
      return { ...filter, ["endDate"]: new Date(), ["startDate"]: getDateOffset(0, -1, 0) }
    case "Weekly":
      return { ...filter, ["endDate"]: new Date(), ["startDate"]: getDateOffset(0, 0, -7) }
    default:
      return { ...filter, ["endDate"]: new Date(), ["startDate"]: getDateOffset(-100, 0, 0) }
  }
}


export const getAuthorizedPages = async (roles:string[]) => {

  let result : string[] = [];
  let promises: any[] = [];

  roles.map((role:string)=>{
    promises.push(apiGetRole(role));
  })

  const data = await Promise.all(promises);
  data.forEach((roleAccess)=>{
    result = [...result, ...roleAccess.data.authorizedPages];
  })

  let authorizedPages: string[] = [];
  result.forEach((element)=>{
    if(!authorizedPages.includes(element)){
      authorizedPages.push(element);
    }
  })
  
  return authorizedPages;

}