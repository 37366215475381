import { useEffect, useState } from 'react';
import './Switch.css';

export const Switch = (props: {
  checked: boolean;
  onChange: any;
  label?: string;
  class?: string;
}) => {
  const [checked, setChecked] = useState<boolean>();

  useEffect(() => {
    setChecked(!checked);
  }, [props.checked]);

  useEffect(() => {
    setChecked(props.checked);
  }, []);

  return (
    <div>
      <label className="switch">
        <input type="checkbox" checked={checked} onChange={props.onChange} />
        <span className="slider round">
          <label className={`switch-text-${checked}`}>{checked ? 'ON' : 'OFF'}</label>
        </span>
      </label>
      {props.label && props.class && (
        <label className={`switch-text-${props.class}`}>{props.label}</label>
      )}
    </div>
  );
};
