import { useAccount, useMsal } from '@azure/msal-react'
import React, { useEffect, useState } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { Backend } from '../pages/Backend/Backend'
import { GemPage } from '../pages/Gem/GemPage'
import { Home } from '../pages/Home/Home'
import { LeaderBoarderPage } from '../pages/LeaderBoard/LeaderBoarderPage'
import { Login } from '../pages/Login/Login'
import { Questionnaire } from '../pages/Questionnaire/Questionnaire'
import { QuickWinsPage } from '../pages/QuickWins/QuickWinsPage'
import { RegisterProblem } from '../pages/RegisterProblem/RegisterProblem'
import { Reporting } from '../pages/Reporting/Reporting'
import { ApprovalPage } from '../pages/Requests/ApprovalPage'
import { RequestsPage } from '../pages/Requests/RequestsPage'
import { Result } from '../pages/Result/Result'
import { StrategicPage } from '../pages/Strategic/StrategicPage'
import { Training } from '../pages/Training/Training'
import { AppPages } from '../utils/Constants'
import { _isAuthorized } from '../utils/Utils'


export const AppRoutes = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path={`${AppPages.PROBLEM}`} >
        <Route path={``} element={<RegisterProblem />} />
        <Route path={`:problemId`} element={<RegisterProblem />} />
      </Route>
      <Route path={`${AppPages.INTAKE}`}>
        <Route path={``} element={<Questionnaire />} />
        <Route path={`:problemId`} element={<Questionnaire />} />
      </Route>
      <Route path={`${AppPages.RESULT}/:problemId`} element={<Result />} />
      <Route path={`${AppPages.TRAINING}`} element={<Training />} />
      <Route path={`${AppPages.QUICKWINS}`}  >
        <Route path={``} element={<QuickWinsPage />} />
        <Route path={`:problemId`} element={<QuickWinsPage />} />
      </Route>
      <Route path={`${AppPages.GEM}`} >
        <Route path={``} element={<GemPage />} />
        <Route path={`:problemId`} element={<GemPage />} />
      </Route>

      <Route path={`${AppPages.STRATEGIC}`}  >
        <Route path={``} element={<StrategicPage />} />
        <Route path={`:problemId`} element={<StrategicPage />} />
      </Route>
      <Route path={`${AppPages.REQUESTS}`} element={<RequestsPage />} />
      <Route path={`${AppPages.APPROVAL}/:problemId`} element={<ApprovalPage />} />
      <Route path={`${AppPages.REPORTING}`} element={<Reporting />} />
      <Route
        path={`${AppPages.BACKEND}`}
        element={
          _isAuthorized(AppPages.BACKEND, account?.username) ? (
            <Backend />
          ) : (
            <Login accessDenied={true}></Login>
          )
        }
      />
      <Route path={`${AppPages.LEADERBOARD}`} element={<LeaderBoarderPage />} />
    </Routes>
  )
}
