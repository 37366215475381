import { Button, Modal } from "@appkit4/react-components";
import React, { useEffect } from "react";

export const TenantAccessDenied = (props: { show: boolean, onClose(): any} ) => {
    const [visible, setVisible] = React.useState(false);

    useEffect(() => {
        setVisible(visible);
    }, [visible]);

    useEffect(() => {
        setVisible(props.show);
    }, [props.show])

    const closeModal = () => {
        setVisible(false);
        props.onClose();
    }

    return (<>
        <Button onClick={() => { setVisible(true) }}>
          Open Dialog
        </Button>
        <Modal
            visible={visible}
            title={""}
            ariaLabel={"modal demo"}
            onCancel={() => setVisible(false)}
            modalStyle={{ width: '33.75rem' }}
            footerStyle={{ 'paddingTop': '8px', 'marginTop': '-8px', 'minHeight': '64px' }}
            header={""}
            icons={""}
            footer={
              <>
                  
                  <Button onClick={closeModal}>OK</Button>
              </>
            }
            bodyStyle={{ minHeight: '92px' }}
        >
          
        </Modal>
      </>);
}