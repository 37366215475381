import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { IProblem } from '../../models/IProblem';
import { INotificationType } from '../Notification/INotificationType';
import { ProblemModal } from '../Problem/ProblemModal';
import { LikeModal } from '../Like/LikeModal';
import { ProblemDeleteConfirmModal } from './ProblemDeleteConfirmModal';
import { CommentModalWrapper } from '../Comment/CommentModalWrapper';
import { UserContext } from '../UserContext/UserContext';
import { Badge } from '@appkit4/react-components/badge';
import { Switch } from '../Switch/Switch';
import { v4 as uuidv4 } from 'uuid';
import {
  apiAddNotification,
  apiCreateLike,
  apiDecrementLike,
  apiDeleteLike,
  apiFindUser,
  apiGetCommments,
  apiGetProblem,
  apiHasLiked,
  apiIncrementLike,
  apiToggleProblemPrivateStatus,
  apiDeleteProblem,
} from '../../services/apiService';
import './ProblemCard.css';
import { getProblemStatus } from '../../utils/QuestionUtil';
import { getDateInfo, getUpdatedDateInfo } from '../../utils/Utils';
import { ILike } from '../../models/ILike';

export const ProblemCard = (props: { problem: IProblem; bucket: string; onChange: any }) => {
  const [problemModal, setProblemModal] = useState(false);
  const [deleteProblemModal, setDeleteProblemModal] = useState(false);
  const [likeModal, setLikeModal] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const navigate = useNavigate();
  const [numberOfComments, setNumberOfComments] = useState(0);
  const [user, setUser] = useContext(UserContext);
  const [numberOfLikes, setNumberOfLikes] = useState(0);
  const [liked, setLiked] = useState(false);
  const [privateProblem, setPrivateProbem] = useState(props?.problem?.private);
  const [status, setStatus] = useState('');
  const [department, setDepartment] = useState('');
  const [updatedBy, setUpdatedBy] = useState<string>('');
  const likeReqObject: ILike = {
    companyId: process.env.REACT_APP_COMPANYID || '',
    userId: user?.mail,
    likeId: uuidv4().toString(),
    firstName: user?.givenName,
    lastName: user?.surname,
    problemId: props.problem._id,
  };
  const visitProblem = () => {
    apiGetProblem(props.problem._id).then((res) => {
      const lastPage = res.data.RegisterProblem.lastProblemPage;
      navigate(`/${lastPage}/${props.problem._id}`);
    });
  };
  async function showProblemModal() {
    setProblemModal(true);
  }
  const onLikeStatusChange = async () => {
    if (liked) {
      try {
        //remove like
        await apiDeleteLike(likeReqObject);
        //reduce the number of likes from the problem
        await apiDecrementLike(props.problem._id);
        setLiked(false);
        let likeCount = numberOfLikes - 1;
        setNumberOfLikes(likeCount);
      } catch (err) {}
    } else {
      try {
        await apiCreateLike(likeReqObject);
        await apiIncrementLike(props.problem._id);
        setLiked(true);
        let likeCount = numberOfLikes + 1;
        setNumberOfLikes(likeCount);
        await apiAddNotification(props.problem._id, likeReqObject.likeId, INotificationType.LIKE);
      } catch (err) {}
    }
  };

  const hasLiked = async () => {
    const liked = await apiHasLiked(likeReqObject);
    setLiked(liked.data.like);
  };
  const changeLikesCount = (action: any) => {
    if (action == 'decrement') {
      setLiked(false);
      setNumberOfLikes(numberOfLikes - 1);
    } else {
      setLiked(true);
      setNumberOfLikes(numberOfLikes + 1);
    }
  };

  const handleDeleteProblem = (deleteProblem: Boolean) => {
    if (props?.problem?._id && deleteProblem) {
      apiDeleteProblem(props.problem._id);
      props.onChange();
    }
    setDeleteProblemModal(false);
  };

  useEffect(() => {
    const populateInteractions = async () => {
      const response = await apiGetCommments(props.problem._id);
      hasLiked();
      const department = await apiFindUser(props.problem.userId);
      setDepartment(department?.data?.userProfile?.department);
      setNumberOfComments(response.data.AllCommentsWithProblemId.length);
      setNumberOfLikes(Number(props.problem.numOfLikes));
      if (props.problem.updatedBy) {
        const updatedByUserName = await getUsername(props.problem.updatedBy);
        setUpdatedBy(updatedByUserName);
      }
    };

    populateInteractions();
    setStatus(getProblemStatus(props.problem));
  }, []);

  const getUsername = async (email: string) => {
    const user = await apiFindUser(email);
    return user ? `${user.data.userProfile.firstName} ${user.data.userProfile.lastName}` : '';
  };

  return (
    <div className="problem-card">
      <div className="user">
        <div className="i-circle sm:i-circle-user rounded-full h-7 w-7 items-center justify-center">
          <div className="mx-0.5">{props.problem.owner[0].toUpperCase()}</div>
        </div>
        <div className="user-info">
          {props.problem.owner}
          <div>
            <span className="time-label">{getDateInfo(props.problem.createdAt)}</span>
            <span className="dept-label">{department}</span>
          </div>
          {props.problem.updatedBy && props.problem.userId != props.problem.updatedBy && (
            <div>
              <span className="time-label">{getUpdatedDateInfo(props.problem.updatedAt)}</span>
              <span className="dept-label">Updated by {updatedBy}</span>
            </div>
          )}
        </div>
      </div>
      <div className="problem-title">
        <div
          className="bucket-start"
          onClick={() => {
            props.bucket !== 'left' ? showProblemModal() : visitProblem();
          }}
        >
          {props.problem.title}
        </div>
        <div className="status-badge">
          <button
            className={
              status == 'Solved'
                ? 'rounded text-white w-20 h-4 bg-[#b8ce95]'
                : status == 'In Progress'
                ? 'rounded text-white w-20 h-4 bg-[#f3c413]'
                : 'rounded text-white w-20 h-4 bg-[#f3c413]'
            }
          >
            {status}
          </button>
          {props.problem.actionType && (
            <button className="rounded text-white w-20 h-4 bg-[#b8ce95] ">
              {props.problem.actionType}
            </button>
          )}
        </div>
      </div>
      <div className="problem-description">{props.problem.description}</div>
      <div>
        {props.bucket !== 'left' && (
          <div className="intraction-section">
            <div
              className={
                liked ? `Appkit4-icon icon-thumb-up-fill` : `Appkit4-icon icon-thumb-up-outline`
              }
              aria-hidden="true"
              onClick={onLikeStatusChange}
            ></div>
            <span className="int-item" onClick={() => setLikeModal(!likeModal)}>
              {numberOfLikes} {numberOfLikes == 1 ? 'like' : 'likes'}
            </span>
            <div
              className="Appkit4-icon icon-comment-outline"
              onClick={() => setCommentModal(!commentModal)}
              aria-hidden="true"
            ></div>
            <span className="int-item" onClick={() => setCommentModal(!commentModal)}>
              {numberOfComments} {numberOfComments == 1 ? 'comment' : 'comments'}
            </span>
          </div>
        )}
        {props.bucket === 'left' && (
          <div className="intraction-section">
            {status !== 'Intake' && (
              <div
                className={`Appkit4-icon icon-thumb-up-outline`}
                onClick={() => setLikeModal(!likeModal)}
                aria-hidden="true"
              ></div>
            )}
            {status !== 'Intake' && (
              <span className="int-item" onClick={() => setLikeModal(!likeModal)}>
                {numberOfLikes}
              </span>
            )}
            {status !== 'Intake' && (
              <div
                className="Appkit4-icon icon-comment-outline"
                onClick={() => setCommentModal(!commentModal)}
                aria-hidden="true"
              ></div>
            )}
            {status !== 'Intake' && (
              <span className="int-item" onClick={() => setCommentModal(!commentModal)}>
                {numberOfComments}
              </span>
            )}
            {status !== 'Intake' && (
              <span
                className="int-item"
                title="Private problems are only visible to team members tagged as collaborators"
              >
                <Switch
                  checked={privateProblem}
                  onChange={() => {
                    apiToggleProblemPrivateStatus(props.problem._id, !privateProblem);
                    setPrivateProbem(!privateProblem);
                  }}
                  label="Private"
                  class="problem-card"
                />
              </span>
            )}
            {!props.problem.resolved && (
              <>
                <div
                  className="Appkit4-icon icon-delete-outline"
                  onClick={() => {
                    setDeleteProblemModal(true);
                  }}
                  aria-hidden="true"
                ></div>
                <span className="int-item" onClick={() => setDeleteProblemModal(true)}>
                  Delete
                </span>
              </>
            )}
          </div>
        )}
        {problemModal && (
          <ProblemModal
            show={problemModal}
            problem={props.problem}
            changeLikeCount={(e: any) => changeLikesCount(e)}
            changeCommentCount={() => setNumberOfComments(numberOfComments + 1)}
            changeModalStatus={() => setProblemModal(false)}
          ></ProblemModal>
        )}
        {likeModal && (
          <LikeModal
            show={likeModal}
            problem={props.problem}
            changeModalStatus={() => setLikeModal(false)}
          ></LikeModal>
        )}
        {commentModal && (
          <CommentModalWrapper
            show={commentModal}
            addComment={props.bucket !== 'left'}
            problem={props.problem}
            changeLikeCount={(e: any) => changeLikesCount(e)}
            changeCommentCount={() => setNumberOfComments(numberOfComments + 1)}
            changeModalStatus={() => setCommentModal(!commentModal)}
          ></CommentModalWrapper>
        )}
        {deleteProblemModal && (
          <ProblemDeleteConfirmModal
            problem={props.problem}
            visible={deleteProblemModal}
            onClick={handleDeleteProblem}
          ></ProblemDeleteConfirmModal>
        )}
      </div>
      <hr className="opacity-10 mt-0 mb-0"></hr>
    </div>
  );
};
