import React, { useState, useEffect } from 'react';

import { Modal } from '@appkit4/react-components/modal';
import { Button } from '@appkit4/react-components/button';
import { Constants } from '../../utils/Constants';
import { IResponse } from '../../models/IResponse';

import './StrategicConfirmModal.css';

//Strategy Submit Confirm Modal
export const StrategicConfirmModal = (props: {
  show: boolean;
  strategic: IResponse;
  onClose(ok: boolean, achievedBenefit: string): any;
}) => {
  const [visible, setVisible] = useState(false);
  const [achievedBenefit, setAchievedBenefit] = useState('');

  useEffect(() => {
    setVisible(visible);
  }, [visible]);

  useEffect(() => {
    setVisible(props.show);
    setAchievedBenefit(props.strategic.achievedBenefit);
  }, [props.show]);

  const closeModal = (result: boolean) => {
    props.onClose(result, achievedBenefit);
  };

  const achieveBenefitChanged = (event: any) => {
    setAchievedBenefit(event.target.value);
  };

  return (
    <div className="strategic-confirm-modal-container">
      <Modal
        visible={visible}
        closable={false}
        header={<strong className="home_profile_title">Confirm</strong>}
        footer={
          <>
            <Button className="strategic-confirm-button" kind="text" onClick={() => closeModal(false)}>
              Cancel
            </Button>
            <Button className="strategic-confirm-button" kind="negative" onClick={() => closeModal(true)}>
              Submit Problem
            </Button>
          </>
        }
      >
        <div className="strategic-confirm-container">
        Please confirm that all the hypothesis for this Strategic problem were successfully tested and are ready for implementation.
         </div>
      </Modal>
    </div>
  );
};
