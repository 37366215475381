import axios from "axios";
import { IRequestQuery } from "../models/IRequestQuery";
import { IRequesStatus } from "../models/IRequestStatus";
import { IResponse } from "../models/IResponse";
import { INotification } from "../models/INotification";
import {IComment} from "../models/IComment"
import { AppPages, Constants } from '../utils/Constants';
import { IQuickWinsQuestionResponse } from "../models/IQuickWinsResponse";
import { INotificationType } from "../components/Notification/INotificationType";
import { ILike } from "../models/ILike";
import { ICreateProfile } from "../models/ICreateProfile";
import { IQuestionnaire } from "../models/IQuestionnaire";
import { IProblem } from "../models/IProblem";
import { ILeaderboardFilter } from "../models/ILeaderboardFilter";
import { IQueryFilter } from "../models/IQueryFilter";
import { ITraceEvent } from "../models/ITraceEvent";


//Lookup APIs
export const apiGetLookup = async (field: string) => {
   return axios.get(`${process.env.REACT_APP_WEBAPI}/lookup/${process.env.REACT_APP_COMPANYID}/${field}`);
}

//Storage APIs
export const apiGetImage = async (file: string) => {
   return axios.get(`${process.env.REACT_APP_WEBAPI}/storage/${file}`, { responseType: "blob" });
}

export const apiGetVideo = async (file: string) => {
   return axios.get(`${process.env.REACT_APP_WEBAPI}/storage/${file}`, {
      responseType: "blob",
      maxContentLength: Infinity,
      maxBodyLength: Infinity
   });
}

export const apiGetPDF = async (file: string) => {
   return axios(`${process.env.REACT_APP_WEBAPI}/storage/pdf/${file}`, {
      method: 'GET',
      responseType: 'blob'
   })  
}

//Problem APIs
export const apiGetProblem = (problemId:string) => {
   return axios.get(`${process.env.REACT_APP_WEBAPI}/${AppPages.PROBLEM}/${problemId}`)
}

export const apiSubmitProblem = (problem: IProblem) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/${AppPages.PROBLEM}`, problem);
}

export const apiUpdateProblem = (problemId:string, problem: IProblem) => {
   return axios.put(`${process.env.REACT_APP_WEBAPI}/${AppPages.PROBLEM}/${problemId}`, problem)
}

export const apiGetQuestionnaire = (problemId: string) => {
   return axios.get(`${process.env.REACT_APP_WEBAPI}/questionnaire/${AppPages.PROBLEM}/${problemId}`)
}

export const apiPostQuestionnaire = (questionnaire: IQuestionnaire) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/questionnaire`, questionnaire)
}

export const apiSetLastPage = async (problemId:string, page:string) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/${AppPages.PROBLEM}/set-last-page/${problemId}/${page}`, );
}

export const apiProblemPagination = async (startingIndex:number, problemsPerPage:number, preferences:any) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/${AppPages.PROBLEM}/pagination/${startingIndex }/${problemsPerPage}`,preferences );
}

export const apiToggleProblemPrivateStatus = async (problemId: string, status: Boolean) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/${AppPages.PROBLEM}/set-private/${problemId }/${status}`);
}
export const apiDeleteProblem = async (problemId: string) => {
   return axios.delete(`${process.env.REACT_APP_WEBAPI}/${AppPages.PROBLEM}/delete/${problemId}`);
}

//User APIs
export const apiUpdateUserTraining = async (userId: string|undefined, trainingTitle:string) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/user/${AppPages.TRAINING}`, {companyId:process.env.REACT_APP_COMPANYID, userId:userId, title:trainingTitle});
}

export const apiGetTrainings = async () => {
   return axios.get(`${process.env.REACT_APP_WEBAPI}/${AppPages.TRAINING}/${process.env.REACT_APP_COMPANYID}` )
}

export const apiFindUser = async (userId: string|undefined) => {
   return axios.get(`${process.env.REACT_APP_WEBAPI}/user/find/${process.env.REACT_APP_COMPANYID}/${userId}`)
}

export const apiGetAllUsers = async () => {
   return axios.get(`${process.env.REACT_APP_WEBAPI}/user/all/${process.env.REACT_APP_COMPANYID}`)
}

// set user first-time-status to false. accepts user.mail as userId
export const apiSetFirstTimeUserToFalse = async (userId: string) => {
   await axios.post(`${process.env.REACT_APP_WEBAPI}/user/first-time-user/${process.env.REACT_APP_COMPANYID}/${userId}`)
}

export const apiSubmitUserProfile = async (profile: ICreateProfile) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/user`, profile)
}

export const apiGetRole = async (role:string) => {
   return  axios.get(`${process.env.REACT_APP_WEBAPI}/role/${process.env.REACT_APP_COMPANYID}/${role}`);
}

export const apiTagUser = async (searchText:string) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/user/tag/${process.env.REACT_APP_COMPANYID}`, {
      user: searchText || '',
      numOfUsers: Constants.teamMembersGroupCount,
    });
}

// Blacklist APIs
// blacklist the token in the header (called upon session timeout/logout)
export const apiBlacklistToken = async () => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/blacklisttoken`);
}
//Gem APIs

export const apiGetGem = async (problemId: string) => {
   return axios.get(`${process.env.REACT_APP_WEBAPI}/${AppPages.GEM}/${process.env.REACT_APP_COMPANYID}/${problemId}`);
}

export const apiUpdateGemResponse = async (updatedGem: IResponse) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/${AppPages.GEM}`, updatedGem);
}

//Strategic APIs
export const apiGetStrategic = async (problemId: string) => {
   return axios.get(`${process.env.REACT_APP_WEBAPI}/${AppPages.STRATEGIC}/${process.env.REACT_APP_COMPANYID}/${problemId}`);
}

export const apiUpdateStrategicResponse = async (updatedStrategic: IResponse) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/${AppPages.STRATEGIC}`, updatedStrategic);
}

// API to serach for the problem using problemId
export const apiFetchProblemUsingProblemId = async (problemId: string) => {
   return await axios.get(`${process.env.REACT_APP_WEBAPI}/${AppPages.PROBLEM}/${problemId}`);
}
export const apiResolveProblem = async (problemId:string, resolvedStatus:any) => {
   return  axios.post(`${process.env.REACT_APP_WEBAPI}/${AppPages.PROBLEM}/resolve/${problemId}/${resolvedStatus}` );
}

export const apiCompleteProblem = async (problemId:string) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/${AppPages.PROBLEM}/complete/${problemId}`)
}



export const apiGetHomepageProblemPopUp = async (problemId: string)=> {
   return axios.get(`${process.env.REACT_APP_WEBAPI}/${AppPages.PROBLEM}/response/homepage/${problemId}`);
}

// Like APIs
export const apiGetAllUsersWhoLikedProblem = async (problemId: string) => {
   return axios.get(`${process.env.REACT_APP_WEBAPI}/like/${process.env.REACT_APP_COMPANYID}/${problemId}`)
}

//Request APIS
export const apiGetRequestProblem = async (problemId: string) => {
   return axios.get(`${process.env.REACT_APP_WEBAPI}/request/${problemId}`)
}

export const apiUpdateRequestStatus = async (problemId: string, requestStatus: IRequesStatus ) =>
{
   return axios.post(`${process.env.REACT_APP_WEBAPI}/request/submit/${problemId}`, requestStatus);
} 

export const apiLoadRequestProblems = async (requestQuery: IRequestQuery) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/request/query`, requestQuery)
}

export const apiCreateLike = async (likeReqObject: ILike) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/like/create`, likeReqObject);
}

export const apiIncrementLike = async (problemId: string) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/${AppPages.PROBLEM}/increment-likes/${problemId}`,);
}

export const apiDeleteLike = async (likeReqObject: ILike) => {
   return axios.delete(`${process.env.REACT_APP_WEBAPI}/like`, { data: likeReqObject });
}

export const apiDecrementLike = async (problemId: string) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/${AppPages.PROBLEM}/decrement-likes/${problemId}`);
}

export const apiHasLiked = async (likeReqObject: ILike) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/like/get-one`, likeReqObject)
}

// Notification APIs
// get all notifications for user between range (using pagination)
export const apiGetNotificationsForUserBetweenRange = async (userId: string, start: number, limit: number) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/notification/${process.env.REACT_APP_COMPANYID}/${userId}/${start}/${limit}`);
}
// mark all notifications seen for user
export const apiMarkAllNotificationsSeenForUser = async (userId: string) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/notification/seenAll/${process.env.REACT_APP_COMPANYID}/${userId}`);
}
// mark one notification seen for user
export const apiMarkOneNotificationSeenForUser = async (notification: INotification) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/notification/seenOne`, notification);
}
export const apiAddNotification = async (problemId: string, notificationId: string, notificationType: INotificationType) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/notification/trigger`, {problemId, notificationId, notificationType});
}
// check if unseen notifications present
export const apiCheckUnseenNotificationsPresent = async () => {
   return await axios.get(`${process.env.REACT_APP_WEBAPI}/notification/unseen-available/${process.env.REACT_APP_COMPANYID}`);
}


// Quick Wins API
// save Quick WIns Response
export const apiQuickWinsResponse = async (problemId: string, response: IQuickWinsQuestionResponse) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/${AppPages.QUICKWINS}/response/${problemId}`, response);
}

export const apiGetQuickWinsResponse = async (problemId: string) => {
   return axios.get(`${process.env.REACT_APP_WEBAPI}/${AppPages.QUICKWINS}/response/${process.env.REACT_APP_COMPANYID}/${problemId}`);
}
//Comment APIs
export const apiGetPaginationComments = async (problemId: string, currPage: number, commentsPerLoad:number) => {
   return axios.get(`${process.env.REACT_APP_WEBAPI}/comment/${problemId}/pagination/${(currPage) * commentsPerLoad}`);
}

export const apiGetCommments = (problemId: string) => {
   return axios.get(`${process.env.REACT_APP_WEBAPI}/comment/${problemId}`);
}

export const apiAddComment  = async (commentObject:IComment)=> {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/comment`, commentObject)
}

//set action API
export const apiSetAction = async (problemId: string, action:string) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/${AppPages.PROBLEM}/set-action/${problemId}/${action}` )
}

//Upload API

export const apiUploadFile = async (formData:FormData) => {
   return await axios({
      method: "post",
      url: `${process.env.REACT_APP_WEBAPI}/storage/upload`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
  })
}






//Leaderboard APIs
export const apiQueryLeaderboard = async (leaderboardFilter: ILeaderboardFilter) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/report/leaderboard`, leaderboardFilter );
}

//Report APIs
export const apiQueryUserProblems = async (queryFilter: IQueryFilter) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/report/problems`, queryFilter );
}

export const apiQueryUnitUsers = async (queryFilter: IQueryFilter) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/report/users`, queryFilter );
}

export const apiQueryUsers = async (queryFilter: IQueryFilter) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/report/users`, queryFilter );
}

export const apiQueryMetricsProblems = async (queryFilter: IQueryFilter) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/report/metrics`, queryFilter );
}

export const apiQueryAppUsage = async (queryFilter: IQueryFilter) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/report/usage`, queryFilter );
}

export const apiQueryProblemStages = async (queryFilter: IQueryFilter) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/report/stages`, queryFilter );
}

export const apiQueryFeedbacks = async (queryFilter: IQueryFilter) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/report/feedbacks`, queryFilter );
}

export const apiQueryCycleTime = async (queryFilter: IQueryFilter) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/report/cycletime`, queryFilter );
}



// Feedback Questions

export const apiGetFeedbackResponse = async (companyId: string, problemId: string) => {
   return await axios.get(`${process.env.REACT_APP_WEBAPI}/feedback/${companyId}/${problemId}`);
}

export const apiPostFeedbackResponse = async (feedback: IResponse) => {
   return await axios.post(`${process.env.REACT_APP_WEBAPI}/feedback`, feedback);
}


//Trace
export const apiTrace = async (traceEvent: ITraceEvent) => {
   return axios.post(`${process.env.REACT_APP_WEBAPI}/trace`, traceEvent );
}

export const apiTraceEvent = async (userId: string, problemId: string, stage: string) => {
   const traceEvent: ITraceEvent = {
      companyId: process.env.REACT_APP_COMPANYID || '',
      userId: userId,
      type: "Stage",
      name: stage,
      problemId: problemId,
      startTime: new Date(),
      endTime: new Date(),
      duration: 0
    }
   return axios.post(`${process.env.REACT_APP_WEBAPI}/trace`, traceEvent );
}


