import { useEffect, useState } from "react";
import { apiGetAllUsers } from "../services/apiService";
import { Select } from "@appkit4/react-components";
import { dropdownSort } from "../utils/Utils";

export const UserDropdown = (props: { onSelected(vals: any): any }) => {

    const [options, setOptions] = useState<{ value: string, label: string, group: string }[]>([]);
    const [value, setValue] = useState<any>('');

    useEffect(() => {
        apiGetAllUsers().then((res: any) => {
            let users = res.data.map((user: any) => {
                return { value: user.email, label: user.firstName + " " + user.lastName, group: user.division }
            });
            let sortedUsers = users.sort(dropdownSort);
            sortedUsers.unshift({ value: '', label: "All Users", group: '' })
            setOptions(sortedUsers);
        })
    }, []);

   

    const selectionChanged = (vals: any) => {
        setValue(vals);
        props.onSelected(vals)
    }

    return (
        <>
            {options.length > 0 &&
                <div>
                    <Select
                        multiple={false}
                        placeholder={"Select a user"}
                        dropdownAlwaysDown={false}
                        data={options}
                        value={value}
                        valueKey={'value'}
                        labelKey={'label'}
                        showSelectAll={true}
                        searchable={true}
                        onSelect={(vals: any) => { selectionChanged(vals) }}
                    >
                    </Select>
                </div>
            }
        </>
    )
}

