import React, { useState } from 'react';
import { Tab, Tabs } from '@appkit4/react-components';

import { ProblemCharts } from './ProblemCharts';
import { MetricsCharts } from './MetricsCharts';
import { CIInsightsCharts } from './CIInsightsCharts';
import { useAccount, useMsal } from '@azure/msal-react';

import './Reports.css';
import { AppRoles } from '../../utils/Constants';

//Reports UI Component
export const Reports = () => {
  const [activeIndex, setactiveIndex] = useState(0);

  const onTabChange = (i: number) => {
    setactiveIndex(i);
  };

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);

  return (
    <div className="reports-container">
      <div className="title">Reports</div>
      {account?.idTokenClaims?.roles?.includes(AppRoles.Coach) ? (
        <div className="w-[350px] sm:w-[100%]">
          <Tabs type="underline" activeIndex={activeIndex} onTabChange={onTabChange}>
            <Tab label={`Summary`}>
              <ProblemCharts></ProblemCharts>
            </Tab>
            <Tab label={`Metrics & Users`}>
              <div className="hidden sm:block">
                <MetricsCharts></MetricsCharts>
              </div>
              <div className=" sm:hidden">
                <div className="flex flex-row items-center justify-center mt-[100px] w-[300px]  p-1 rounded-[2px] min-w-[30px] gap-1">
                  <svg
                    className="h-6 w-6 text-[#b8ad17]"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <circle cx="12" cy="12" r="10" /> <line x1="12" y1="16" x2="12" y2="12" />{' '}
                    <line x1="12" y1="8" x2="12.01" y2="8" />
                  </svg>
                  <div className=" text-red-500">Switch to desktop to view the content.</div>
                </div>
              </div>
            </Tab>
            <Tab label={`CI Insights`}>
              <CIInsightsCharts></CIInsightsCharts>
            </Tab>
          </Tabs>
        </div>
      ) : account?.idTokenClaims?.roles?.includes(AppRoles.Admin) ? (
        <div>
          <Tabs type="underline" activeIndex={activeIndex} onTabChange={onTabChange}>
            <Tab label={`Summary`}>
              <ProblemCharts></ProblemCharts>
            </Tab>
            <Tab label={`Metrics & Users`} className="hidden sm:block">
              <MetricsCharts></MetricsCharts>
            </Tab>
            <Tab label={`CI Insights`}>
              <CIInsightsCharts></CIInsightsCharts>
            </Tab>
          </Tabs>
        </div>
      ) : (
        <div>
          <Tabs type="underline" activeIndex={activeIndex} onTabChange={onTabChange}>
            <Tab label={`Summary`}>
              <ProblemCharts></ProblemCharts>
            </Tab>
          </Tabs>
        </div>
      )}
    </div>
  );
};
