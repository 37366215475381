import React from 'react'

import "./StrategicInformation.css"


//A UI component to show strategy information
export const StrategicInformation = () => {
  return (
    <div className="information-container">
    <div className="left">
      <div className="header">Impact on Performance</div>
      <div className="title">1. Metric, target, timeline</div>
      <div className="content">Does the hypothesis bring your team closer to achieving the metric, its target and at the desired timeframe?</div>
      <div className="title">2. Constraints</div>
      <div className="content">Does the hypothesis help you and your team eliminate the major constraint?</div>
      <div className="title">3. Root-cause</div>
      <div className="content">Does the hypothesis address the major root-causes of the problem?</div>
      <div className="title">4. Wastes</div>
      <div className="content">Does the hypothesis eliminate the major wastes found in this problem?</div>

    </div>
    <div className="right">
    <div className="header">Ease of Implementation</div>
      <div className="title">1. Complexity</div>
      <div className="content">What level of complexity is involved in executing the opportunity? Are specialized skills or technology solutions required?</div>
      <div className="title">2. FTEs</div>
      <div className="content">How many dedicated people will be required in order to prove or disprove the hypothesis?</div>
      <div className="title">3. Time</div>
      <div className="content">How long will it will take to test the hypothesis?</div>
      <div className="title">4. Stakeholders</div>
      <div className="content">How many BU's/function/customers will be impacted by the hypothesis that will be tested?</div>
      <div className="title">5. Barriers</div>
      <div className="content">Does the hypothesis alleviate or completely remove any internal barrier found in this problem?</div>
      <div className="title">6. Cost</div>
      <div className="content">How much money will it cost to prove or disprove the hypothesis the opportunity?</div>
    </div>

  </div>
  )
}
