import react, { useEffect, useState } from 'react';
import { apiGetVideo } from '../../services/apiService';


//show video from blob storage
export const BlobVideo = (props: { file: string }) => {

    const [video, setVideo] = useState('');

    useEffect(() => {
        apiGetVideo(props.file).then(
            (response: any) => {
                setVideo(URL.createObjectURL(response.data));
            }
        )
    }, [])

    return (
        video != null ? <video controls src={video} ></video> : <></>

    )
}