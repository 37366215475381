import { useEffect, useState } from "react";
import { Tooltip } from "@appkit4/react-components";
import { ToastContainer, toast } from "react-toastify";

import { IResponseQuestion } from "../../models/IResponseQuestion";
import { SlideShow } from "../SlideShow/SlideShow";
import { ValidateImageUpload } from "../../utils/ValidateImageUpload";
import { ValidateVideoUpload } from "../../utils/ValidateVideoUpload";

import "react-toastify/dist/ReactToastify.css";
import "./QuestionRichText.css"
import { RawContent } from "../RawContent/RawContent";
import { apiUploadFile } from "../../services/apiService";


//Rich Text Component with title, upload features
export const QuestionRichText = (props: { question: IResponseQuestion, onChange: any, onFocus: any, readOnlyAccess: boolean }) => {


    const [files, setFiles] = useState<{ data: string[] }>({ data: [] });
    const [fileIndex, setFileIndex] = useState<number>(0);
    const [value, setValue] = useState<string>('');

    const handleSubmit = async (file: any, isImage: Boolean) => {
        if (isImage) {
            const validileName = await ValidateImageUpload(file.name);
            if (!validileName) {
                toast.error(`Error: Image Extension should be of type ${process.env.REACT_APP_IMAGE_FILES}`);
                return;
            }
        } else {
            const validileName = await ValidateVideoUpload(file.name);
            if (!validileName) {
                toast.error(`Error: Video Extension should be of type ${process.env.REACT_APP_VIDEO_FILES}`);
                return;
            }
        }

        const formData = new FormData();
        if (file)
            formData.append("upload", file);
        try {
            apiUploadFile(formData).then((res: any) => {
                let newfiles: string[] = [...files.data];
                newfiles.push(res.data.url);
                setFiles({ data: newfiles });
                setFileIndex(files.data.length);
                props.onChange(props.question._id, "answerAttachments", newfiles)
            });



        } catch (error) {
        }
    }

    const uploadImage = async (event: any) => {
        await handleSubmit(event.target.files[0], true);
    }

    const uploadVideo = async (event: any) => {
        await handleSubmit(event.target.files[0], false);
    }

    useEffect(() => {
        setValue(props.question.answerText);
        setFiles({ data: props.question.answerAttachments });
    }, [])

    useEffect(() => {
        setValue(props.question.answerText);
    }, [props.question.answerText])

    useEffect(() => {

    }, [props.question])

    const deleteSilde = (sliderIndex: number) => {
        const newFiles = { ...files };
        newFiles.data.splice(sliderIndex, 1);
        setFiles(newFiles);
        if (sliderIndex > 0) setFileIndex(sliderIndex - 1);
        if (sliderIndex > files.data.length) setFileIndex(files.data.length);
    }

    const handleAnswerChange = (event: any) => {
        setValue(event.target.value);
        props.onChange(props.question._id, [event.target.name], event.target.value,)

    }

    
    const handleOnFocus = (event: any) => {
        props.onFocus(props.question.step+props.question.questionNo, [event.target.name], event.target.value,)
    }

    return (
        <div className="question-richtext-container" >
            <div className="question">
                {props.question.questionNo !== '-1' ? `${props.question.step}${props.question.questionNo}.   ` : ""}
                {/* {props.question.step}{props.question.questionNo}.  */}
                <RawContent input={props.question.question} links={props.question.questionLinks}></RawContent>   
                {props.question.required && <span className="question-red-star">*</span>}  
            </div>
            {props.question.subtitle && <div className="subtitle">
                <div><span className="Appkit4-icon icon-information-outline"></span></div>
                <div className="subtitle-content">{props.question.subtitle}</div>
            </div>}
            <div className="tool-bar">
                <div className="video-upload">
                    <Tooltip
                        className="tooltip"
                        trigger='hover'
                        position='top-left'
                        distance={4}
                        id="tooltipVideo"
                        content='Upload Video'
                    >
                        {(props.readOnlyAccess === false) && <label aria-describedby="tooltipVideo">
                            <span className="Appkit4-icon icon-video-outline"></span>
                            <input type="file" onChange={uploadVideo} accept={process.env.REACT_APP_VIDEO_FILES} />
                        </label>}
                    </Tooltip>
                </div>
                <div className="image-upload">

                    <Tooltip
                        className="tooltip"
                        trigger='hover'
                        position='top-left'
                        distance={4}
                        id="tooltipImage"
                        content='Upload Image'
                    >
                        {(props.readOnlyAccess === false) && <label aria-describedby="tooltipImage">
                            <span className="Appkit4-icon icon-image-outline"></span>
                            <input type="file" onChange={uploadImage} accept={process.env.REACT_APP_IMAGE_FILES} />
                        </label>}
                    </Tooltip>
                </div>
                <ToastContainer/>
            </div>
            <div className="content">

                <div className="textarea">
                    <textarea name="answerText" value={value} onChange={handleAnswerChange} onFocus={handleOnFocus} readOnly={props.question.readonly || props.readOnlyAccess}></textarea>
                </div>
            </div>


            {files?.data.length > 0 && <SlideShow files={files.data} currentIndex={fileIndex} onDelete={props.readOnlyAccess ? "" : deleteSilde} ></SlideShow>}
        </div>
    )
}