import React, { useEffect, useState } from 'react'


import { Button } from '@appkit4/react-components'
import { IResponseQuestion } from '../../models/IResponseQuestion'
import { groupArrayOfObjects, mapStrategicNavigationStep } from '../../utils/Utils'

import './Hypothesis.css'
import { QuestionGridRow } from '../QuestionGrid/QuestionGridRow'
import { IResponse } from '../../models/IResponse'
import { IStepHeading } from '../../models/IStepHeading'


//Grid style components for Step 6 about hypothesis
export const Hypothesis = (props: { step: number, stepQuestions: IResponseQuestion[], strategic: IResponse,heading: IStepHeading, onChanged(key: string, name: string, vals: any): any, onAddGroup(step:number): any, readOnlyAccess: boolean }) => {

    const [groups, setGroups] = useState({});

    useEffect(() => {
        setGroups(groupArrayOfObjects(props.stepQuestions, "group"));
    }, [props.stepQuestions])

    useEffect(() => {
    },
        [groups])

    const handleAnswer = (key: any, name: any, val: any) => {
        props.onChanged(key, name, val);
    }

    const addAction = () => {
        props.onAddGroup(props.step);
    }
    
    return (
        <div className="hypothesis-grid-container">
            <div className="step-title">Step {props.heading.stepInTitle?props.heading.stepInTitle:props.heading.step}: {props.heading.title}</div>
            <div className="hypothesis-info-table">
                <div className="hypothesis-info-table-statement-header">Problem statement</div>
                <div className="hypothesis-info-table-statement">
                    <textarea readOnly={true} disabled={true} value={props.strategic.questions.filter((q:any)=>q.step==1&&q.questionNo=='h')[0].answerText}></textarea>
                    
                    </div>
                <div className="hypothesis-info-table-columns-header">
                    <div>Major root causes</div>
                    <div>Major wastes</div>
                    <div>80/20</div>
                    <div>Constraint</div>
                    <div>Barrier</div>
                </div>
                <div className="hypothesis-info-table-columns">
                <div>{props.strategic.questions.filter((q:any)=>q.step==2 && q.group==props.strategic.questions.find((q:any)=>q.step==2&&q.questionNo=='c' && q.answerText=='1')?.group)[0].answerText}</div>
                    <div>{props.strategic.questions.filter((q:any)=>q.step==3&&q.group==props.strategic.questions.find((q:any)=>q.step==3&&q.questionNo=='c' && q.answerText=='1')?.group)[0].answerText}</div>
                    <div>{props.strategic.questions.filter((q:any)=>q.step==4&&q.questionNo=='a')[0].answerText}</div>
                    <div>{props.strategic.questions.filter((q:any)=>q.step==1&&q.questionNo=='a')[0].answerText}</div>
                    <div>{props.strategic.questions.filter((q:any)=>(q.step==5||q.step==6)&&q.answerText>='4').map(i=>i.question)!.reduce((p,c)=>p+", "+c,'')?.substring(1)}</div>
                </div>
            </div>
            {   
                groups && Object.keys(groups).map(
                    (keyName: string, i: number) => {
                        return (
                            <QuestionGridRow key={props.step + keyName} group={Number(keyName)} stepGroupQuestions={groups[keyName as keyof typeof groups]} onChanged={handleAnswer} readOnlyAccess={props.readOnlyAccess}></QuestionGridRow>
                        )
                    }
                )
            }
            {
                (props.stepQuestions[0].multigroup) && (props.readOnlyAccess === false) && <div className="group-add">
                    <Button kind="text" onClick={addAction}><span className="Appkit4-icon icon-plus-outline"></span>Add Another</Button>
                </div>
            }
        </div>
    )
}
