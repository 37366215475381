/*
 * Accepts current tab name and redirects to the specific route.
 * @param {props} props - The props contains the name of current tab.
 */
import { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useMsal, useAccount } from '@azure/msal-react';
import './Navigation.css';
import { UserContext } from '../UserContext/UserContext';
import { AppPages } from '../../utils/Constants';
import { _isAuthorized } from '../../utils/Utils';
export const Navigation = (props: any) => {
  const tab = props.currentTab;
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  /* get the user context that includes authorizedPages */
  const [user] = useContext(UserContext);
  return (
    <nav className="nav sm:w-[79px] bg-[#E5E5E5] ">
      <ul className="flex flex-row justify-evenly sm:block  sm:text-center sm:p-0 sm:m-0 sm:min-w-[79px] h-[70px] items-center w-[100%] pl-0 mb-0">
        <li
          className={
            tab === AppPages.HOME ? 'active-tab w-full h-full' : 'inactive-tab w-full h-full'
          }
        >
          <Link to="/">
            <div className="flex flex-col justify-center items-center gap-1">
              <span className="Appkit4-icon icon-home-outline ap-font-24"></span>
              <div>Home</div>
            </div>
          </Link>
        </li>
        <li
          className={
            tab === AppPages.PROBLEM ? 'active-tab w-full h-full' : 'inactive-tab w-full h-full'
          }
        >
          <Link to={`/${AppPages.PROBLEM}`}>
            <div className="flex flex-col justify-center items-center gap-1">
              <span className="Appkit4-icon icon-news-outline ap-font-24"></span>
              <div>Problem</div>
            </div>
          </Link>
        </li>
        <li
          className={
            tab === AppPages.TRAINING ? 'active-tab w-full h-full' : 'inactive-tab w-full h-full'
          }
        >
          <Link to={`/${AppPages.TRAINING}`}>
            <div className="flex flex-col justify-center items-center gap-1">
              <span className="Appkit4-icon icon-library-outline ap-font-24"></span>
              <div>Training</div>
            </div>
          </Link>
        </li>
        {_isAuthorized(AppPages.REQUESTS, user) && (
          <li
            className={
              tab === AppPages.REQUESTS
                ? 'active-tab hidden sm:block'
                : 'inactive-tab hidden sm:block'
            }
          >
            <Link to={`/${AppPages.REQUESTS}`}>
              <div>
                <span className="Appkit4-icon icon-folder-opened-outline ap-font-24"></span>
                <div>Requests</div>
              </div>
            </Link>
          </li>
        )}
        <li
          className={
            tab === AppPages.LEADERBOARD ? 'active-tab w-full h-full' : 'inactive-tab w-full h-full'
          }
        >
          <Link to={`/${AppPages.LEADERBOARD}`}>
            <div className="flex flex-col justify-center items-center gap-1">
              <span className="Appkit4-icon icon-jewelry-outline ap-font-24"></span>
              <div>Leaderboard</div>
            </div>
          </Link>
        </li>
        <li
          className={
            tab === AppPages.REPORTING ? 'active-tab w-full h-full' : 'inactive-tab w-full h-full'
          }
        >
          <Link to={`/${AppPages.REPORTING}`}>
            <div className="flex flex-col justify-center items-center gap-1">
              <span className="Appkit4-icon icon-library-outline ap-font-24"></span>
              <div>Reporting</div>
            </div>
          </Link>
        </li>
        {_isAuthorized(AppPages.BACKEND, user) && (
          <li
            className={
              tab === AppPages.BACKEND
                ? 'active-tab hidden sm:block'
                : 'inactive-tab hidden sm:block'
            }
          >
            <Link to={`/${AppPages.BACKEND}`}>
              <div>
                <span className="Appkit4-icon icon-library-outline ap-font-24"></span>
                <div>Backend</div>
              </div>
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
};
