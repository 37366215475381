import React, { useState, useEffect, useContext } from 'react';

import { Button } from '@appkit4/react-components/button';

import { ICreateProfile } from '../../models/ICreateProfile';

import { DropdownList } from '../DropdownList';
import { useAccount, useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import { callMsGraph } from '../../services/graphService';

import "./CreateProfile.css";
import { apiFindUser, apiSubmitUserProfile } from '../../services/apiService';
import { UserContext } from '../UserContext/UserContext';

export const UpdateProfile = (props: { onUpdated: any }) => {
    const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const [user, setUser] = useContext(UserContext);
    const [validated, setValidated] = useState(false);

    let initProfile: ICreateProfile = {
        companyId: process.env.REACT_APP_COMPANYID || '',
        firstName: '',
        lastName: '',
        email: '',
        location: '',
        division: '',
        department: '',
        role: '',
        appRoles:[],
        unitPreferences: []
    }

    const [profile, setProfile] = useState(initProfile);

    useEffect(() => {
        RequestProfileData();
    }, []);

    useEffect(() => {
        const v = validInput();
        setValidated(v);
    }, [profile])

    function RequestProfileData() {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        instance.acquireTokenSilent(request).then((response) => {
            callMsGraph(response.accessToken).then(async(user) => {
                const profileData= await apiFindUser(user?.mail)
                let graphProfile: ICreateProfile = {
                    companyId: process.env.REACT_APP_COMPANYID || '',
                    firstName: user?.givenName || '',
                    lastName: user?.surname || '',
                    email: user?.mail || '',
                    location: profileData.data.userProfile.location || '',//user?.officeLocation || '',
                    division: profileData.data.userProfile.division,
                    department: profileData.data.userProfile.department,
                    role: user?.jobTitle || '',
                    appRoles:account?.idTokenClaims?.roles || [],
                    unitPreferences: profileData.data.userProfile.unitPreferences
                }
                setProfile(graphProfile);
            });
        }).catch((e) => {
        });
    }

    const handleChange = (event: any) => {
        setProfile({ ...profile, [event.target.name]: event.target.value })
    }

    const validInput = () => {
        if (!profile.firstName || !profile.lastName || !profile.email || !profile.location || !profile.division || !profile.department || !profile.role || !profile.unitPreferences || profile.unitPreferences.length === 0) {
            return false;
        } else {
            return true;
        }
    }

    const closeModal = (result: boolean) => {
         props.onUpdated();
      };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (!validInput()) {
            return null;
        }
        apiSubmitUserProfile(profile)
            .then(res => {
               if(res.data?.updatedUserProfile){
                setUser({ ...user, "givenName": res.data.updatedUserProfile.firstName, "surname": res.data.updatedUserProfile.lastName, "OfficeLocation": res.data.updatedUserProfile.location, "mail": res.data.updatedUserProfile.email, "unitPreferences": res.data.updatedUserProfile.unitPreferences, "role": account?.idTokenClaims?.roles ? account?.idTokenClaims?.roles[0] : '' });
                    props.onUpdated();

                } 
            })
    }

    return (
        <div className="profile_container">
            <form onSubmit={handleSubmit}>
                <div className="profile_profile">
                    <div className="profile-flex-parent">
                        <div className='profile-input-left'>
                            <label className='profile-label'>First Name<span>*</span></label>
                            <div className=''>
                                <input className="profile-input-short" type={"text"} name="firstName" readOnly={true} value={profile.firstName} onChange={handleChange} ></input>
                            </div>

                        </div>
                        <div className='profile-input-right'>
                            <label className='profile-label'>Last Name<span>*</span></label>
                            <input className="profile-input-short" type={"text"} name="lastName" readOnly={true} value={profile.lastName} onChange={handleChange} ></input>
                        </div>
                    </div>
                    <div className='profile-input-left'>
                        <label className='profile-label'>Email Address<span>*</span></label>
                        <input className="profile-input-short" type={"text"} name="email" readOnly={true} value={profile.email} onChange={handleChange} ></input>
                    </div>
                    <div><hr></hr></div>
                    <div className="profile-flex-parent">
                        <div className='profile-input-left'>
                            <label className='profile-label'>Location<span>*</span></label>
                            <input className="profile-input-short" type={"text"} name="location" value={profile.location} onChange={handleChange} ></input>
                        </div>
                        <div className='profile-input-right'>
                            <label className='profile-label'>Division<span>*</span></label>
                            <DropdownList multiple={false} field="division" value={profile.division} readonlyAccess={false} onSelected={vals => { setProfile({ ...profile, ['division']: vals }) }}></DropdownList>
                        </div>
                    </div>
                    <div className="profile-flex-parent">
                        <div className='profile-input-left'>
                            <label className='profile-label'>Unit/Department<span>*</span></label>
                            <DropdownList multiple={false} field="business-unit" value={profile.department} group={[profile.division]} readonlyAccess={false} onSelected={vals => { setProfile({ ...profile, ['department']: vals }) }}></DropdownList>
                        </div>
                        <div className='profile-input-right'>
                            <label className='profile-label'>Role<span>*</span></label>
                            <input className="profile-input-short" type={"text"} name="role" readOnly={true} value={profile.role} onChange={handleChange}></input>
                        </div>
                    </div>
                    <div><hr></hr></div>
                    <div className='profile-input-control'>
                        <label className='profile-label'>Select your Unit/Department Preferences<span>*</span></label>
                        <label className="profile-select-desc">Select all the business unit problem you would like to view</label>
                        <DropdownList multiple={true} field="business-unit" value={profile.unitPreferences} readonlyAccess={false} onSelected={vals => { setProfile({ ...profile, ['unitPreferences']: vals }) }}></DropdownList>
                    </div>
                </div>
                <div className="profile-save">
                    <Button className="profile-button" kind="negative" aria-label="close" data-dismiss="modal" disabled={!validated} onClick={handleSubmit} >Save</Button>
                    <Button className="profile-button" kind="text" onClick={() => closeModal(false)}> Cancel </Button>
                </div>
            </form>
        </div>
    )
}