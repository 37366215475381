import React from 'react'
import { IProblem } from '../../models/IProblem'

import "./ProblemView.css"


//Show Problem Detail for Approve Page
export const ProblemView = (props: { problem: IProblem }) => {
    return (
        <div className="problem-view-container">
            <div className="title">Problem</div>
            <hr/>
            <div className="horizontalLayout">
                <div className="field">
                    <div className='title'>Problem owner</div>
                    <div className="content">{props.problem.owner}</div>
                </div>
                <div className="field">
                    <div className='title'>Reports to</div>
                    <div className="content">{props.problem.reportTo}</div>
                </div>
                <div className="field">
                    <div className='title'>Team member</div>
                    <div className="content">{props.problem.teamMember}</div>
                </div>
                <div>
                </div>
            </div>
            <div className="verticalLayout">
                <div className="field">
                    <div className='title'>Problem title</div>
                    <div className="content">{props.problem.title}</div>
                </div>
                <div className="field">
                    <div className='title'>Brief description </div>
                    <div className="content">{props.problem.description}</div>
                </div>
                <div className="field">
                    <div className='title'>Location where you have observed the problem </div>
                    <div className="content">{props.problem.location}</div>
                </div>
                <div className="field">
                    <div className='title'>Units/Department where you observed the problem </div>
                    <div className="content">{props.problem.observedUnits}</div>
                </div>
                <div className="field">
                    <div className='title'>Units/Department impacted by the problem </div>
                    <div className="content">{props.problem.impactedUnits}</div>
                </div>
                <div className="field">
                    <div className='title'>Metrics impacted by solving the problem </div>
                    <div className="content">{props.problem.impactedMetrics}</div>
                </div>
                <div className="field">
                    <div className='title'>Target date to have solved the problem </div>
                    <div className="content">{new Date(props.problem.targetDate).toLocaleDateString()}</div>
                </div>
            </div>
        </div>

    )
}
