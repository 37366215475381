import { useContext } from 'react';
import { Intake } from '../../components/Intake/Intake';
import { Layout } from '../../components/Layout/Layout';
import { UserContext } from '../../components/UserContext/UserContext';
import { AppPages } from '../../utils/Constants';
import { useTrackPage } from '../../utils/useTrackPage';

export const Questionnaire = () => {
  const [user, setUser] = useContext(UserContext);
  useTrackPage(AppPages.INTAKE);
  return <Layout currentTab="problem">{
    user && <Intake></Intake>
  }</Layout>;
};
