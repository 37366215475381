import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams} from 'react-router-dom';
import { Button } from '@appkit4/react-components';
import { Switch } from '../Switch/Switch';
import { IResponseQuestion } from '../../models/IResponseQuestion';
import { INotificationType } from '../Notification/INotificationType';
import { UserContext } from '../UserContext/UserContext';
import {  mapStrategicNavigationStep } from '../../utils/Utils';
import { AppPages, Constants, ProblemStatus } from '../../utils/Constants';

import { BlobLink } from '../Blobs/BlobLink';
import { apiCompleteProblem, apiGetStrategic, apiResolveProblem, apiUpdateStrategicResponse, apiAddNotification, apiSetLastPage, apiTraceEvent, apiGetProblem, apiToggleProblemPrivateStatus } from '../../services/apiService';
import { QuestionStepper } from '../QuestionStepper/QuestionStepper';
import { Feedback } from '../Feedback/Feedback';
import { StrategicConfirmModal } from './StrategicConfirmModal';
import { IResponse } from '../../models/IResponse';
import { IDragHypothesis } from '../../models/IDragHypothesis';
import { IStepHeading } from '../../models/IStepHeading';
import { IProblem } from '../../models/IProblem';

import { StepQuestions } from '../StepQuestions/StepQuestions';
import { findInvalidSteps, getInitResponse, getLastStep, getMaxStep, getNewStepGroupQuestions, validateResponse } from '../../utils/QuestionUtil';
import { validateUserReadOnlyAccess } from '../../utils/QuestionUtil';

import './Strategic.css';
import { TeamMember } from '../TeamMember/TeamMember';

//Strategic Approcch Component
export const Strategic = () => {
  const initStrategic = getInitResponse();

  const [strategic, setStrategic] = useState<IResponse>(initStrategic);
  const strategicRef = useRef<IResponse>();
  const [headings, setHeadings] = useState<IStepHeading[]>([]); //// hold headings for Steps
  strategicRef.current = strategic;
  const [lastStep, setLastStep] = useState<number>(1); //set last step
  const [current, setCurrent] = useState<number>(1); //current step
  const [maxStep, setMaxStep] = useState<number>(1); //max step completed
  const [invalidSteps, setInvalidSteps] = useState<any[]>([]); //invalid steps
  const [user] = useContext(UserContext);
  const navigate = useNavigate();
  const [confirm, setConfirm] = useState(false);
  const [validated, setValidated] = useState(false);
  const [nextEnabled, setNextEnabled] = useState(false);
  const [saving, setSaving] = useState<boolean>(false); //show loading icon when click button
  const [filterCategory, setFilterCategory] = useState('');
  const { problemId } = useParams();
  const [readOnlyAccess, setReadOnlyAccess] = useState<boolean>(false);
  const [feedbackModal, setFeedbackModal] = useState<boolean>(false);
  const [privateProblem, setPrivateProbem] = useState<boolean>(false);

  //load Strategic
  useEffect(() => {
    fetchStrategic();
  }, [user]);

  //Validate 
  useEffect(() => {
    validateStep();
  }, [current]);

  //validate when the strategic is changed
  useEffect(() => {
    if (strategic) {
      validateStep();
    }
  }, [strategic]);

  const fetchStrategic = async () => {
    if (problemId && user) {
      // get readonly access (true/false)
      const readOnly: boolean = await validateUserReadOnlyAccess(problemId, user?.mail);
      setReadOnlyAccess(readOnly);
      // set last problem page to Strategic
      if(!readOnly) await apiSetLastPage(problemId, AppPages.STRATEGIC);

      await apiGetStrategic(problemId).then((res: any) => {
        if (!res.data.strategicViewDto.problemId) {
          res.data.strategicViewDto.problemId = problemId;
          res.data.strategicViewDto.companyId = process.env.REACT_APP_COMPANYID;
        }

        setHeadings(res.data.strategicViewDto.headings);

        setStrategic(res.data.strategicViewDto);

        let previousMaxStep = getMaxStep(res.data.strategicViewDto);

        setLastStep(1 + getLastStep(res.data.strategicViewDto));

        if (previousMaxStep > 1) {
          //load initial the steps status
          if (readOnly) {
            setCurrent(1);
          } else {
            setCurrent(previousMaxStep);
          }
          setMaxStep(previousMaxStep + 1);
          const invalidStepArray = findInvalidSteps(res.data.strategicViewDto, previousMaxStep);
          setInvalidSteps(invalidStepArray.map((s: number) => { return s > 5 ? s - 1 : s }));
        } else {
          setCurrent(1);
          setMaxStep(1);
        }
      });
      // set problem private status
      const problem: IProblem = (await apiGetProblem(problemId)).data.RegisterProblem;
      setPrivateProbem(problem?.private);
    }
  };


  const onNavigate = (step: number) => {
    checkInvalidSteps();
    if (step <= maxStep) step > 5 ? setCurrent(step + 1) : setCurrent(step); //step 5 have two pages
  };

  const validateStep = () => {
    if (current === 9) {
      const v = validateResponse(strategic);
      setValidated(v);
    } else if (current == 8) {
      const missingAnswer = strategic.questions.find(
        (q: any) =>
          (q.answerText === '' || q.answerText?.length == 0 || !q.answerText) && q.step === current && q.required === true && q.questionNo == "0"
      );
      setNextEnabled(missingAnswer ? false : true);
    } else {
      const missingAnswer = strategic.questions.find(
        (q: any) =>
          (q.answerText === '' || q.answerText?.length == 0 || !q.answerText ) && q.step === current && q.required === true
      );
      if (missingAnswer) {
      } else {
        //remove step from invalidSteps if it is validated
        if (invalidSteps.includes(current)) {
          setInvalidSteps(invalidSteps.fill((s: any) => s == current));
        }
      }
      setNextEnabled(missingAnswer ? false : true);
    }
  };

  //check whether the step was invalid when navigate back
  const checkInvalidSteps = () => {
    validateStep();
    const findInvalidSteps = strategic.questions
      .filter((q: any) => q.step < maxStep && ((q.answerText == '' && q.required == true)))
      .map((q: any) => q.step);
    setInvalidSteps(findInvalidSteps.map((s: number) => { return s > 5 ? s - 1 : s }));
  };

  const onContinue = () => {
    if (current >= maxStep) {
      setMaxStep(current + 1);
    }
    setCurrent(current + 1);
    checkInvalidSteps();
  };

  const onBack = () => {
    checkInvalidSteps();
    if (current > 1) setCurrent(current - 1);
  };

  const resolveProblem = async () => {
    setConfirm(true);

    if (problemId) {
      apiCompleteProblem(problemId).then((res: any) => { 
        apiTraceEvent(user?.mail, problemId||'',ProblemStatus.Resolved).then(()=>{
          apiAddNotification(problemId, problemId, INotificationType.TAG).then(()=>{});
        })
      });
    }
  };

  const handleAnswer = (key: any, name: any, val: any) => {
    let copyKey: string;
    if (name == 'answerText') {
      const updatingQuestion = strategic.questions.find((q) => q._id == key);
      if (updatingQuestion) {
        if (updatingQuestion.step === 7 && updatingQuestion.questionNo === 'b') {
          const copyStep5Question = strategic.questions.find(
            (q) => q.step === 8 && q.questionNo === 'a' && q.group === updatingQuestion.group,
          );
          if (copyStep5Question) { copyKey = copyStep5Question._id; }
        }
      }
    }
    if (strategic) {
      const updatedQuestions = strategic.questions.map((q) => {
        if (q._id == key) {
          return { ...q, [name]: val };
        } else
          if (copyKey && q._id == copyKey) {
            return { ...q, [name]: val };
          } else { return q; }
      });
      setStrategic((strategic) => ({ ...strategic, questions: updatedQuestions }));
    }
  };

  const handleDropAnswer = (key: any, name: any, val: any) => {
    if (strategicRef.current) {
      const droppingStep = strategicRef.current.questions.filter((q:IResponseQuestion)=>{return q._id == key})[0];
      if(droppingStep){
        const updatedQuestions = strategicRef.current.questions.map((q) => {
          if (q._id == key) {
            return { ...q, [name]: val };
          } else if (q.questionType === 'drag' && q.questionType == droppingStep.questionType && q.step == droppingStep.step && q._id != key && q.answerText === val) {
            return { ...q, [name]: "" }
          } else {
            return q;
          }
  
        });
  
        setStrategic((strategic) => ({ ...strategic, questions: updatedQuestions }));
      }

    }
  };

  // Need automatically populate question h on step 1 
  // Fill in all above answers from step 1 questions to question h
  const handleFocus = (key: any, name: any, val: any) => {
    if (key === '1h' && val.trim() == '') {
      let ready = true;  //make sure all previous answers have been set before generating the combining answers
      const step1Questions = strategic.questions.filter((q) => q.step == 1 && q.questionNo != 'h');
      let combiningAnswer = '';
      step1Questions.map((question: IResponseQuestion) => {
        if (!question.answerText) ready = false;
        combiningAnswer = combiningAnswer + question.answerText + ' ';
      });

      if (strategic && ready) {
        const updatedStrategic = strategic.questions.map((q) => {
          if (q.step == 1 && q.questionNo === 'h') {
            return { ...q, [name]: combiningAnswer };
          }
          return q;
        });
        setStrategic(strategic => ({ ...strategic, questions: updatedStrategic }));
      }
    }
  };


  //Handle event for Add Another (step 7 on json) on Hyposthese Page, which add a new questions on step 7 and step 8
  //Add additional group of questions to step 7 and step 8
  const addGroup = (step: number) => {
    if(step===7){ // handle step 7 need add additional
      const newquestions = getNewStepGroupQuestions(strategic, step, [step+1]);
      //assign the new question index number
      const indexQuestion = newquestions.find((q:IResponseQuestion)=>q.step===step && q.questionNo==='a');
      if(indexQuestion) indexQuestion.answerText = indexQuestion.group.toString();
      setStrategic({ ...strategic, questions: strategic.questions.concat(newquestions) });
    }
  };

  const removeGroup = (step:number, group: number) => {
    const newquestions = strategic.questions.filter((q) => !( q.step==step && q.group == group));
    setStrategic({ ...strategic, questions: newquestions });
  };

  //close the submit popup
  const closeConfirm = (result: boolean, achievedBenefit: string) => {
    setConfirm(false);
    if(result){
      const updatedStrategic = { ...strategic, userId: user.mail, achievedBenefit: achievedBenefit };
      apiUpdateStrategicResponse(updatedStrategic).then((res: any) => {
        const resolvedStatus = result;
        apiResolveProblem(strategic.problemId, resolvedStatus).then(() => {
          setFeedbackModal(true);
        });
      });
    }
  };

  const saveQuestions = () => {
    const updatedStrategic = { ...strategic, userId: user.mail };
    setSaving(true);
    apiUpdateStrategicResponse(updatedStrategic).then((res: any) => {
      setStrategic(res.data.strategicViewDto);
      setTimeout(() => {
        //privde time for user to see the loading icon
        setSaving(false);
      }, Constants.saveLoaderTimeout);
    });
  };

  //handle the update for event at step Hypothese 7 (question step 8 in json) when drop a hypothese to solution chart
  //need update the drop value, drop location x and y
  const dropHyperthesis = (dragHypothesis: IDragHypothesis) => {
    const qx = strategic.questions.find((n: any) => n.step == 8 && n.group == dragHypothesis.id && n.questionNo == 'x');
    const qy = strategic.questions.find((n: any) => n.step == 8 && n.group == dragHypothesis.id && n.questionNo == 'y');
    const qa = strategic.questions.find((n: any) => n.step == 8 && n.group == dragHypothesis.id && n.questionNo == '0');

    if (strategicRef.current) {
      const newquestions = strategicRef.current.questions.map((q) => {
        if (q._id == qx?._id) {
          return { ...q, ["answerText"]: dragHypothesis.x ? dragHypothesis.x : "" };
        } else if (q._id == qy?._id) {
          return { ...q, ["answerText"]: dragHypothesis.y ? dragHypothesis.y : "" };
        } else if (q._id == qa?._id) {
          return { ...q, ["answerText"]: dragHypothesis.approach ? dragHypothesis.approach : "" };
        } else {
          return q;
        }

      });
      setStrategic({ ...strategic, questions: newquestions });
    }
  }

  const mapNavigationStep = (): number => {
    return mapStrategicNavigationStep(current);
  }

  return (
    <div className="strategic-container">
      <div className="strategic-navigation-container">
        <div className="title">{headings[0]?.title}</div>
        <div className="subtitle">
          {headings[0]?.subtitle}{' '}
          <BlobLink file={headings[0]?.quote}>
            <span className="Appkit4-icon icon-help-question-outline"></span>
          </BlobLink>{' '}
        </div>
        {
          headings?.length > 0 && <QuestionStepper
            headings={headings}
            currentStep={mapNavigationStep()}
            maxStep={maxStep}
            invalidSteps={invalidSteps}
            onNavigate={onNavigate}
          ></QuestionStepper>
        }
         {problemId && <TeamMember problemId={problemId} readOnlyAccess={readOnlyAccess} ></TeamMember> }
      </div>

      <div className="strategic-step-container">
        {!readOnlyAccess && 
        <div className='switchbox' title='Private problems are only visible to team members tagged as collaborators'>
          <Switch
            checked={privateProblem} 
            onChange={() => {
              if (problemId) {
                apiToggleProblemPrivateStatus(problemId, !privateProblem);
                setPrivateProbem(!privateProblem);
              }
            }}
            label="Private"
            class="strategic"
          />
        </div>}
        {
          
            strategic.questions.length>0 &&    <StepQuestions 
                  response={strategic} questionStep={current>8?current-1:current} headingStep={current}
                  filterCategory={filterCategory}
                  onChanged={handleAnswer}
                  onFocus={handleFocus}
                  onAddGroup={addGroup}
                  onRemoveGroup={removeGroup}
                  onDropAnswer={handleDropAnswer}
                  onDropHyperthesis={dropHyperthesis}
                  readOnlyAccess={readOnlyAccess}
                ></StepQuestions>
        }
        <hr></hr>
        <div className="button-container">
          {current > 1 && (
            <Button kind="negative" onClick={onBack}>
              Back
            </Button>
          )}
          {current != lastStep && (
            <Button kind="negative" onClick={onContinue} disabled={!nextEnabled}>
              Continue
            </Button>
          )}
          {current == lastStep && (readOnlyAccess === false) && (
            <Button kind="negative" onClick={resolveProblem} disabled={!validated}>
              Submit Problem
            </Button>
          )}
          {
            !readOnlyAccess &&
            <Button
            kind="secondary"
            className=".ap-border-primary-red-01"
            onClick={saveQuestions}
            loading={saving}
          >
            Save
          </Button>
          }
        </div>
      </div>

      <StrategicConfirmModal show={confirm} onClose={closeConfirm} strategic={strategic}></StrategicConfirmModal>
      {feedbackModal && <Feedback companyId={strategic.companyId} problemId={strategic.problemId}></Feedback>}
    </div>
  );
};