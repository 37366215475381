import React, { useState } from 'react'
import { Tab, Tabs } from '@appkit4/react-components'

import "./Requests.css"
import { RequestList } from './RequestList';

//Show Requests Information 
export const Requests = () => {

    const [activeIndex, setactiveIndex] = useState(0);
    const [pendingCount, setPendingCount] = useState<number>(0);
    const [pastCount, setPastCount] = useState<number>(0);

    const onTabChange = (i: number) => {
        setactiveIndex(i);
    }

    const loadPending = (count: number) => {
        setPendingCount(count);
    }

    const loadPast = (count: number) => {
        setPastCount(count)
    }

    return (
        <div className="requests-container">
            <div className="title">Requests</div>
            <div>
                <Tabs type='underline' activeIndex={activeIndex} onTabChange={onTabChange}>
                    <Tab label={`Request Approvals (${pendingCount})`} >
                        <RequestList pending={true} onLoaded={loadPending}></RequestList>
                    </Tab>
                    <Tab label={`Past Requests (${pastCount})`} >
                        <RequestList pending={false} onLoaded={loadPast}></RequestList>
                    </Tab>
                </Tabs>
            </div>
        </div>)
}
