import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Gem } from '../../components/Gem/Gem';
import { Layout } from '../../components/Layout/Layout';
import { UserContext } from '../../components/UserContext/UserContext';
import { AppPages } from '../../utils/Constants';
import { useTrackPage } from '../../utils/useTrackPage';

import './GemPage.css';

export const GemPage = () => {
  const [user, setUser] = useContext(UserContext);
  const { problemId } = useParams();
  useTrackPage(AppPages.GEM, problemId);
  return (
    <Layout currentTab="problem">
      <div className="gem-page-container">
        {user && <Gem></Gem>}
      </div>
    </Layout>
  );
};
