import { useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { IQueryFilter } from '../../../models/IQueryFilter';
import { apiQueryCycleTime } from '../../../services/apiService';
import { getDateOffset, getInitQueryFilter, getUpdatedFilter } from '../../../utils/QuestionUtil';
import { DropdownList } from '../../DropdownList';

//Cycle Time Report Chart
export const CycleTimeChart = () => {
  const initData: any[] = [];
  const [data, setData] = useState<any[]>(initData);

  const [filter, setFilter] = useState<IQueryFilter>(getInitQueryFilter());
  const [frequence, setFrequence] = useState('All Time');

  const handleFrequence = (value: string) => {
    setFilter(getUpdatedFilter(value, filter));
    setFrequence(value);
  };

  useEffect(() => {
    apiQueryCycleTime(filter).then((res: any) => {
      setData(res.data);
    });
  }, [filter]);

  const barColors = ['#49ABA0', '#415385', '#E27588'];

  const getColor = (name: string) => {
    switch (name) {
      case 'Quick Wins':
        return barColors[0];
      case 'Gem':
        return barColors[1];
      case 'Strategic':
        return barColors[2];
      default:
        return barColors[0];
    }
  };

  return (
    <div>
      <div className="report-filter">
        <div>
          <DropdownList
            multiple={false}
            field="report-type"
            value={frequence}
            placeholder={'Report Type'}
            readonlyAccess={false}
            onSelected={handleFrequence}
          ></DropdownList>
        </div>
      </div>
      <div>
        {' '}
        <ResponsiveContainer width={350} height={350}>
          <BarChart data={data}>
            <XAxis dataKey="_id" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="Average Days">
              <LabelList dataKey="Average Days" position="top" />
              {data.map((entry, index) => (
                <Cell key={entry._id} fill={getColor(entry._id)} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
