import React, { useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";

import moment from 'moment'
import { SessionTimeOutModal } from './SessionTimeOutModal'
import { handleLogout } from '../../utils/Utils';
import { apiBlacklistToken } from '../../services/apiService';

export const SessionTimeOut = (props: any) => {

    const { instance } = useMsal();

    const [showModal, setShowModal] = useState(false)
    const [isLogout, setLogout] = useState(false)

    let timer: any = undefined;
    const events = ['click', 'load', 'keydown']
    const eventHandler = (eventType: any) => {

        if (!isLogout) {
            localStorage.setItem('lastInteractionTime', moment().format())
            if (timer) {
                props.onActive();
                startTimer();
            }
        }

    };

    useEffect(() => {
        addEvents();

        return (() => {

            removeEvents();
            clearTimeout(timer);
        })
    }, [])

    const startTimer = () => {

        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(async () => {

            let lastInteractionTime = localStorage.getItem('lastInteractionTime')
            const diff = moment.duration(moment().diff(moment(lastInteractionTime)));
            let timeOutInterval = props.timeOutInterval ? props.timeOutInterval : 600000;
            if (isLogout) {
                clearTimeout(timer)
            } else {
                if (diff.milliseconds < timeOutInterval) {
                    startTimer();
                    props.onActive();
                } else {
                    props.onIdle();
                    await apiBlacklistToken();    // blacklist the token before logging out
                    handleLogout(instance);
                }
            }

        }, props.timeOutInterval ? props.timeOutInterval : 600000)





    }
    const addEvents = () => {

        events.forEach(eventName => {
            window.addEventListener(eventName, eventHandler, true)
        })

        startTimer();
    }

    const removeEvents = () => {
        events.forEach(eventName => {
            window.removeEventListener(eventName, eventHandler, true)
        })
    };

    const handleContinueSession = () => {
        setShowModal(false)
        setLogout(false)
    }
    const handleLogoutLocal = () => {
        removeEvents();
        clearTimeout(timer);
        setLogout(true)
        props.onLogout();
        setShowModal(false)

    }

    return (
        <div>

            <SessionTimeOutModal
                showModal={showModal}
                handleContinue={handleContinueSession}
                handleLogout={handleLogoutLocal}
            />

        </div>
    )

}

