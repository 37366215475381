import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';

import "./QuestionStepper.css";
import { useEffect, useState } from 'react';
import { IStepHeading } from '../../models/IStepHeading';


//Question Navigate Stepper
export const QuestionStepper = (props: { headings: IStepHeading[], currentStep: number, maxStep: number, invalidSteps: any[], onNavigate(step: number): any }) => {

    const [questionSteps, setQuestionSteps] = useState<string[]>([]);

    //load question steps
    const getQuestionSteps = () => {
        const steps = props.headings.filter((heading:IStepHeading)=>heading.step>0).map((heading: IStepHeading) => {
            return heading.title.split('-')[0];
        })

        const unqiueHeadings = steps.filter((item, i, ar) => ar.indexOf(item) === i);

        setQuestionSteps(unqiueHeadings);
    }

    useEffect(() => {
        getQuestionSteps();
    }, [])


    return (
        <div className="stepper-container">
            <Stepper activeStep={props.currentStep + 1} orientation="vertical">
                {questionSteps?.length > 0 && questionSteps.map((label, index) => (
                    <Step key={label} onClick={() => props.onNavigate(index + 1)}>
                        <StepLabel StepIconProps={{
                            active: index == props.currentStep - 1 ? true : false,
                            completed: (index !== props.currentStep - 1) && (index < props.maxStep - 1) ? true : false,
                            error: (index !== props.currentStep - 1) && props.invalidSteps.find(p => p == index + 1)
                        }}>{label}</StepLabel>
                        <StepContent>

                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </div>
    );
}