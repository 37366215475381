import React from "react";

import "./ResultCard.css"

export const ResultCard = (props: {className:string, title:string, subtitle:string}) => {

    return (
        <div className={props.className}>
        <div className="result-card-title">{props.title}</div>
        <div className="result-card-subtitle">{props.subtitle}</div>
 </div>
    )
}