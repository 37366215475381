import { MetricsStackedBarChart } from './charts/MetricsStackedBarChart';
import { UsersByDepartmentChart } from './charts/UsersByDepartmentChart';
import { UsersByDivisionChart } from './charts/UsersByDivisionChart';

//Reports for Merics and Users
export const MetricsCharts = () => {
  return (
    <div className="report-tab-container">
      <div className='report-charts-container'>
        <div className='report-chart'>
          <div className="report-chart-title">Numbers of problems by metrics</div>
          <div className='report-chart-container'>
            <MetricsStackedBarChart></MetricsStackedBarChart>
          </div>
        </div>
        <div className="report-charts-container-responsive">
        <div className='report-chart'>
          <div className="report-chart-title">Users by Division</div>
          <div className='report-chart-container'>
            <UsersByDivisionChart></UsersByDivisionChart>
          </div>
        </div>
        <div className='report-chart'>
          <div className="report-chart-title">Users by Department</div>
          <div className='report-chart-container'>
            <UsersByDepartmentChart></UsersByDepartmentChart>
          </div>
        </div>
        </div>
        <div>
        </div>
      </div>
    </div>
  )
}
