import React, { useState } from 'react'
import { Tab, Tabs } from '@appkit4/react-components';
import { LeaderboardGrid } from './LeaderboardGrid';

import "./Leaderboard.css"
import { LeaderboardType } from '../../utils/Constants';

export const Leaderboard = () => {

    const [activeIndex, setactiveIndex] = useState(0);

    const onTabChange = (i: number) => {
        setactiveIndex(i);
    }

    return (
        <div className="leaderboard-container">
            <div className="title">Leaderboard</div>
            <div>
                <Tabs type='underline' activeIndex={activeIndex} onTabChange={onTabChange}>
                    <Tab label={`Individual `} >
                    <LeaderboardGrid type={LeaderboardType.Individual}></LeaderboardGrid>
                    </Tab>
                    <Tab label={`Unit `} >
                    <LeaderboardGrid type={LeaderboardType.Unit}></LeaderboardGrid>
                    </Tab>
                    <Tab label={`Division `} >
                    <LeaderboardGrid type={LeaderboardType.Division}></LeaderboardGrid>
                    </Tab>
                </Tabs>
            </div>
        </div>)
}
    

