import React, { useEffect, useState } from 'react'
import { ResponsiveContainer, BarChart, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';
import { IQueryFilter } from '../../../models/IQueryFilter';
import { apiQueryMetricsProblems } from '../../../services/apiService';
import { getDateOffset, getInitQueryFilter, getUpdatedFilter } from '../../../utils/QuestionUtil';
import { DropdownList } from '../../DropdownList';

//Metrics Report Chart
export const MetricsStackedBarChart = () => {

  const [filter, setFilter] = useState<IQueryFilter>(getInitQueryFilter())
  const [frequence, setFrequence] = React.useState('');
  const initData: any[] = [];
  const [data, setData] = useState<any[]>(initData);

  const handleFrequence = (value: string) => {
    setFilter(getUpdatedFilter(value, filter));
    setFrequence(value);
  }

  useEffect(() => {

    apiQueryMetricsProblems(filter).then((res: any) => {
      setData(res.data)
    })
  }, [frequence])

  return (
    <div>
      <div className="report-filter-container">
        <div className="report-filter-size-one">
          <DropdownList multiple={false} field="report-type" value={frequence} placeholder={"Report Type"} readonlyAccess={false} onSelected={handleFrequence}></DropdownList>
        </div>
      </div>
      <div>
        <ResponsiveContainer width={800} height={500}>
          <BarChart
            data={data}
          >
            <XAxis dataKey="name"  interval={0} textAnchor= "end" height={120} angle={-20}/>
            <YAxis width={180}/>
            <Tooltip />
            <Legend />
            <Bar dataKey="Quick wins" stackId="a" fill="#49ABA0" />
            <Bar dataKey="Gem" stackId="a" fill="#415385" />
            <Bar dataKey="Strategic" stackId="a" fill="#E27588" />
            <Bar dataKey="Avoid" stackId="a" fill="#E0301E" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>

  )
}
