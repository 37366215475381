/* 
*   The CommentModal includes list of comments and Add Comment Button
*   Accepts: {
*       problem: IProblem type relating the comments
*       changeCommentCount: function to change comment count when one is added
*       addComment: boolean -> if true, then the "Add Comment" button is visible, and false otherwise
*       width: string -> sets the width of the addCommentPanel (textbox for adding comment)
*       height: string -> sets the height of the addCommentPanel (textbox for adding comment)
*   }
*/

import React, { useEffect, useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '@appkit4/react-components/button';
import { FeedsComments, FeedsCommentItem } from '@appkit4/react-components/feeds-comments';
import { commentListItem } from '../../models/commentListItem';
import { UserContext } from '../UserContext/UserContext';
import {apiFindUser, apiGetPaginationComments, apiAddComment} from '../../services/apiService';
import { IComment } from '../../models/IComment';
import { INotificationType } from '../Notification/INotificationType';

import { Constants } from '../../utils/Constants';

import { apiAddNotification } from '../../services/apiService';

import './comments.css';
import { getDateInfo } from '../../utils/Utils';


export const CommentModal = (props: {
  problem: any;
  changeCommentCount: any;
  addComment: boolean;
  width: string;
  height: string;
}) => {
  const [user, setUser] = useContext(UserContext);
  const [comments, setComments] = useState<commentListItem[]>([]);
  const [commentsContent, setCommentsContent] = useState('');
  const [addingComment, setAddingComment] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currPage, setCurrPage] = useState(1); // storing current page number
  const [numberOfCommentsLeft, setNumberOfCommentsLeft] = useState(1);
  const commentsPerLoad = Constants.commentsPerLoad;
  
  const onCommentClick = (list: any) => {};
  const getMoreComments = () => {    
    setLoading(true);
    setCurrPage(currPage+1);
  };

  const commentize = async (comments: any) => {
    let commentizedComments: commentListItem[] = [];
    await Promise.all(
      comments.map(async (c: any) => {
        //get the name of commentor
        const userResponse: any = await apiFindUser(c.userId);
        commentizedComments.push({
          shortName: userResponse.data.userProfile?.firstName[0].toUpperCase() || 'A',
          shortNameBgColor: '#415385',
          fullName: userResponse.data.userProfile?.firstName || 'anonymous',
          commentsTime: getDateInfo(c.createdAt) + userResponse.data.userProfile?.department,
          createdAt: c.createdAt,
          commentsContent: c.content,
        });
      }),
    );
    return commentizedComments.sort((a:any, b:any)=>{
      return a.createdAt>b.createdAt? -1:1
    });
  };
  useEffect(() => {
    const fetchComments = async () => {
      const response = await apiGetPaginationComments(props.problem._id, currPage, commentsPerLoad);
      let _comments = await commentize(response.data.CommentsInIndex);
      setComments(_comments);
  
      if (response.data.TotalCommentsRemaining != 0) {
        setNumberOfCommentsLeft(response.data.TotalCommentsRemaining);
      }
      setLoading(false);
    };
    if (numberOfCommentsLeft>=-5 && user) {
      fetchComments();
    } 
  }, [addingComment, numberOfCommentsLeft, currPage]);

  const addComments = async (event: any) => {
    let commentObject: IComment = {
      companyId: process.env.REACT_APP_COMPANYID || '',
      problemId: props.problem._id,
      commentId: uuidv4().toString(),
      userId: user?.mail,
      firstName: user?.givenName,
      lastName: user?.surname,
      content: commentsContent,
    };
    await apiAddComment(commentObject)
      .then((res) => {
        props.changeCommentCount();
        setAddingComment(false);
        apiAddNotification(props.problem._id, commentObject.commentId, INotificationType.COMMENT).then(()=>{});
      })
      .catch((err) => {});
  };
  const closeModal = () => {
    setAddingComment(false);
  };
  const moreClick = () => {};
  return (
    <div>
      {!addingComment ? (
        <div className="comment-section"
        style={{ cursor: loading?"progress":"auto"}}>
          <div className='comment-list'>
          
            <FeedsComments
              type={'comments'}
              renderCommentList={() => {
                return comments?.map((item, index) => (
                  <FeedsCommentItem
                    item={item}
                    key={index.toString()}
                    onCommentClick={onCommentClick}
                  ></FeedsCommentItem>
                ));
              }}
            ></FeedsComments>
            {numberOfCommentsLeft > 0   && !loading &&
          (<span className='load-more-button-comments' onClick={getMoreComments}>Load More</span>)}
          </div>
          {props.addComment && <Button
              kind="negative"
              onClick={() => setAddingComment(true)}
              className="add-comment-button"
              compact={true}
            >
              Add comment
            </Button>
          }
        </div>
      ) : (!loading && (
        <div className="feeds-comments-wrapper" style={{ width: props.width, height: props.height, marginLeft: '10px'}}>
          <FeedsComments
            type={'addCommentPanel'}
            commentsContent={commentsContent}
            onCloseClick={closeModal}
            onAddClick={addComments}
            onMoreClick={moreClick}
            maxLength={Constants.textMaxCharacterLength}
            onCommentContentChange={setCommentsContent}
          ></FeedsComments>
        </div>
      ))}
    </div>
  );
};