import { useContext, useEffect, useState } from 'react';

import { AppPages } from '../../utils/Constants';
import { UserContext } from '../UserContext/UserContext';
import { Welcome } from '../Welcome/Welcome';
import { Button } from '@appkit4/react-components/button';
import { useNavigate } from 'react-router-dom';
import { RightBucket } from '../HomeBuckets/rightBucket';
import { LeftBucket } from '../HomeBuckets/leftBucket';
import './HomeDashboard.css';
export const HomeDashboard = (props: { firstTimeUser: Boolean }) => {
  const [user, setUser] = useContext(UserContext);
  const navigate = useNavigate();
  
  return (
    <>
      {user && props.firstTimeUser && <Welcome></Welcome>}
      {user && !props.firstTimeUser && (
        <div className="dashboard-container">
          <div className="dashboard-welcome">Welcome back, {user ? user.givenName : ''}</div>
          <div className='flex flex-col'>
          <div className="dashboard-content">
            <div className="prob-cat">
              <div className="problem-category-title">Problems you posted</div>
              <LeftBucket bucketType="left" user={user}></LeftBucket>
            </div>
            <div className="prob-cat">
              <div className="problem-category-title">Problems you are interested in</div>
              <RightBucket user={user}></RightBucket>
            </div>
            <div className="prob-cat">
              <div className="problem-category-title">Problems you are tagged in</div>
              <LeftBucket bucketType="tag" user={user}></LeftBucket>
            </div>            
          </div>
          <div className="problem-category-small">
              <h5>Need to solve a problem?</h5>
              <Button
                kind="negative"
                className="action-button"
                onClick={() => navigate(`/${AppPages.PROBLEM}`)}
              >
                Start Solving
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
