import { ResponsiveContainer, BarChart, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';


//Problem Stackedbar Chart
export const ProblemStackedBarCharts = (props: { data: any[] }) => {

  return (
        <ResponsiveContainer aspect={1} width={400} height={300} >
          <BarChart 
            data={props.data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Solved" stackId="a" fill="#EB1C2C" />
            <Bar dataKey="In Progress" stackId="a" fill="rgba(235, 28, 44, 0.5)" />
          </BarChart>
         </ResponsiveContainer>
  )
}
