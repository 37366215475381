import React, { useEffect, useState } from 'react'
import { IDragHypothesis } from '../../models/IDragHypothesis';
import { IResponse } from '../../models/IResponse'
import { IResponseQuestion } from '../../models/IResponseQuestion';
import { IStepHeading } from '../../models/IStepHeading';
import { StepStyle } from '../../utils/Constants';
import { getInitStepHeading } from '../../utils/QuestionUtil';
import { Hypothesis } from '../Hypothesis/Hypothesis';
import { HypothesisApproach } from '../HypothesisApproach/HypothesisApproach';
import { QuestionDraggable } from '../QuestionDraggable/QuestionDraggable';
import { QuestionGrid } from '../QuestionGrid/QuestionGrid';
import { QuestionStep } from '../QuestionList/QuestionStep';
import { QuestionRatingGroup } from '../QuestionRatingGroup/QuestionRatingGroup';


//Show Step UI
export const StepQuestions = (props: {
    response: IResponse, questionStep: number, headingStep: number, filterCategory: string, onChanged(key: string, name: string, vals: any): any, onFocus(key: string, name: string, vals: any): any, onAddGroup(step:number): any,
    onRemoveGroup(step:number, group: number): any,
    onDropAnswer(key: string, name: string, vals: any): any
    onDropHyperthesis(dragHyperthesis: IDragHypothesis): any
    readOnlyAccess: boolean;
}) => {

    const [step, setStep] = useState<{ heading: IStepHeading, questions: IResponseQuestion[] }>({heading: getInitStepHeading(), questions: [] });

    useEffect(() => {
        const questions = props.response.questions.filter((q: IResponseQuestion) => q.step === props.questionStep);
        const heading = props.response.headings.filter((h: IStepHeading) => h.step === props.headingStep)[0];

        setStep({ heading: heading, questions: questions });

    }, [props.response, props.questionStep, props.headingStep])


    return (
        <>
            {
                (step.questions.length >= 1) && (step.heading.style === StepStyle.RatingGroup) &&
                <QuestionRatingGroup
                    step={props.questionStep}
                    heading={step.heading}
                    stepQuestions={step.questions}
                    onChanged={props.onChanged}
                    readOnlyAccess={props.readOnlyAccess}
                ></QuestionRatingGroup>
            }

            {(step.questions.length >= 1) && (step.heading.style === StepStyle.DragToTable) &&
                <QuestionDraggable
                    step={props.questionStep}
                    heading={step.heading}
                    stepQuestions={step.questions}
                    onChanged={props.onDropAnswer}
                    readOnlyAccess={props.readOnlyAccess}
                ></QuestionDraggable>}

            {(step.questions.length >= 1) && (step.heading.style === StepStyle.List) &&
                <QuestionStep
                    step={props.questionStep}
                    heading={step.heading}
                    stepQuestions={step.questions}
                    filterCategory={props.filterCategory}
                    onChanged={props.onChanged}
                    onFocus={props.onFocus}
                    onAddGroup={props.onAddGroup}
                    onRemoveGroup={props.onRemoveGroup}
                    readOnlyAccess={props.readOnlyAccess}
                ></QuestionStep>
            }

            {(step.questions.length >= 1) && (step.heading.style === StepStyle.Grid) &&
                <QuestionGrid
                    step={props.questionStep}
                    heading={step.heading}
                    stepQuestions={step.questions}
                    onChanged={props.onChanged}
                    onAddGroup={props.onAddGroup}
                    readOnlyAccess={props.readOnlyAccess}
                ></QuestionGrid>
            }

            {(step.questions.length >= 1) && (step.heading.style === StepStyle.Hypothesis) &&
                <Hypothesis
                    step={props.questionStep}
                    heading={step.heading}
                    stepQuestions={step.questions}
                    strategic={props.response}
                    onChanged={props.onChanged}
                    onAddGroup={props.onAddGroup}
                    readOnlyAccess={props.readOnlyAccess}
                ></Hypothesis>
            }

            {(step.questions.length >= 1) && (step.heading.style === StepStyle.DragToSolutions) &&
                <HypothesisApproach
                    step={props.questionStep}
                    heading={step.heading}
                    stepQuestions={step.questions}
                    onChanged={props.onChanged}
                    onAddGroup={props.onAddGroup}
                    onDropped={props.onDropHyperthesis}
                    readOnlyAccess={props.readOnlyAccess}
                ></HypothesisApproach>
            }
        </>
    )
}
