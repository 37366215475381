import React, { useEffect, useState } from 'react'
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

import { apiGetLookup, apiQueryLeaderboard } from '../../services/apiService';

import "./LeaderboardGrid.css";
import { LeaderboardFilter } from './LeaderboardFilter';
import { LeaderboardType } from '../../utils/Constants';
import { ILeaderboardFilter } from '../../models/ILeaderboardFilter';
import { CustomToolbar } from '../../utils/UIUtil';

//Leaderboard Grid Layout
export const LeaderboardGrid = (props: { type: string }) => {

  const [gridData, setGridData] = useState([]);
  const [pageSize, setPageSize] = useState<number>(10);

  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [columnGroupingModel, setColumnGroupingModel] = useState<[]>([]);


  useEffect(() => {

    apiGetLookup(`leaderboard-${props.type.toLocaleLowerCase()}`).then((res:any)=>{ //load columun definitions
      const columndefs = JSON.parse(res.data.lookups[0].value);
      columndefs.unshift({ field: 'index',  headerName: 'No.',  filterable: false,  renderCell: (index:any) => index.api.getRowIndex(index.row.id) + 1,  width:20})
      setColumns(columndefs);
    })

    if(props.type !== LeaderboardType.Individual){
      apiGetLookup(`leaderboard-group-${props.type.toLocaleLowerCase()}`).then((res:any)=>{ //load columun definitions
        setColumnGroupingModel(JSON.parse(res.data.lookups[0].value));
      })
  
    }
  }, [])

  const handleFilter = (leaderboardFilter: ILeaderboardFilter) => {
    apiQueryLeaderboard(leaderboardFilter).then((res: any) => {
      setGridData(res.data);

    })
  }

  return (
    <div className="leaderboard-grid-container">
      <div className="leaderboard-filter">
        <LeaderboardFilter type={props.type} filterChanged={handleFilter}></LeaderboardFilter>
      </div>
      <div className="leaderboard-grid">
        <DataGrid
          experimentalFeatures={{ columnGrouping: props.type !== LeaderboardType.Individual }}
          rows={gridData}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 25, 50]}
          getRowId={(row: any) => row.id}
          columnGroupingModel={columnGroupingModel}
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{ toolbar: { printOptions: { disableToolbarButton: true } } }}
          autoHeight={true}
          headerHeight={60}
          rowHeight={60}
        />
      </div>
    </div>
  )
}
