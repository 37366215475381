import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '@appkit4/react-components';
import { IQuestion } from '../../models/IQuestion';
import { Question } from '../Question/Question';
import { IQuestionnaire } from '../../models/IQuestionnaire';
import { UserContext } from '../UserContext/UserContext';

import { AppPages, ProblemStatus } from '../../utils/Constants';
import { MakeSequence } from '../../utils/MakeSequence';

import './Intake.css';
import { apiGetQuestionnaire, apiPostQuestionnaire, apiSetAction, apiSetLastPage, apiTraceEvent } from '../../services/apiService';
import { validateUserReadOnlyAccess } from '../../utils/QuestionUtil';

export const Intake = () => {
  const [user] = useContext(UserContext);
  const navigate = useNavigate();
  const { problemId } = useParams();


  const [step, setStep] = useState(1);
  const [validated, setValidated] = useState(false);
  const [editable, setEditable] = useState(false);
  const [questions, setQuestions] = useState<{ data: IQuestion[] }>({ data: [] });
  const [readOnlyAccess, setReadOnlyAccess] = useState<boolean>(false);

  const handleAnswer = (key: any, name: any, val: any) => {
    if (name == 'answer') {
      const newState = questions.data.map((obj: any) => {
        if (obj.questionNo == key) {
          return { ...obj, [name]: val, additionalAnswer: '' };
        }
        return obj;
      });
      setQuestions({ data: newState });
    } else {
      const newState = questions.data.map((obj: any) => {
        if (obj.questionNo == key) {
          return { ...obj, [name]: val };
        }
        return obj;
      });
      setQuestions({ data: newState });
    }
  };

  const fetchQuestions = async () => {
    // set last problem page to intake
    if (problemId) {
      await apiSetLastPage(problemId, AppPages.INTAKE);
      apiGetQuestionnaire(problemId)
        .then((res) => {
          setQuestions({ data: res.data.questionnaire.questions });
        });
    }
  };

  const moveForward = (forward: boolean) => {
    if (editable) {
      setEditable(false);
    }
    if (forward) {
      setStep(step + 1);
    } else {
      setStep(step - 1);
    }

    setValidated(validateInput());

    if (!forward && step == 1) {
      navigate(`/${AppPages.PROBLEM}/${problemId}`);
    }

    if (forward && step == 2) {
      handleSubmit();
    }
  };

  const validateInput = () => {
    let result: boolean = true;
    if (step !== 2) {
      questions.data
        .filter((q: any) => q.sectionId === step)
        .map((q) => {
          if (q.skipAnswer && !q.additionalAnswer) {
            result = false;
          } else if (
            (q.answer || q.skipAnswer) &&
            ((q.answer == q.additionalCondition && q.additionalAnswer) ||
              q.answer != q.additionalCondition)
          ) {
          } else {
            result = false;
          }
        });
    } else {
      questions.data.map((q) => {
        if (q.skipAnswer && !q.additionalAnswer) {
          result = false;
        } else if (
          (q.answer || q.skipAnswer) &&
          ((q.answer == q.additionalCondition && q.additionalAnswer) ||
            q.answer != q.additionalCondition)
        ) {
        } else {
          result = false;
        }
      });
    }
    return result;
  };

  const handleSubmit = async () => {
    if (!validateInput()) {
      return null;
    }

    const sequence = await MakeSequence(questions.data);

    let questionnaire: IQuestionnaire = {
      companyId: process.env.REACT_APP_COMPANYID || '',
      userId: user?.mail || 'anonymous',
      problemId: problemId || '',
      action: '',
      questions: questions.data,
      sequence: sequence,
    };

    let res = await apiPostQuestionnaire(questionnaire);
    if (res.data.newQuestionnaire) {
      await apiSetAction(problemId || '', res.data.newQuestionnaire.action);
      await apiTraceEvent(user?.mail, problemId || '', ProblemStatus.InProgress);
      navigate(`/${AppPages.RESULT}/${problemId}`)
    };
  }

  const toggleEdit = () => {
    setEditable(!editable);
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  useEffect(() => {
    setValidated(validateInput());
  }, [questions, step]);

  useEffect(() => {
    if (user) {
      if (problemId) {
        validateUserReadOnlyAccess(problemId, user?.mail).then((readOnly: boolean) => {
          setReadOnlyAccess(readOnly);
          setEditable(!readOnly);
          if(readOnly)setStep(2);
        });
      }
    }
  }, [user, problemId]);


  return (
    <div className="intake-container">
      <div>
        <div className="bar">
          Register Problem &gt; Intake Questionnaire {step == 2 && '> Review'}
        </div>
        <div className="title-container">
          <div className="title">
            {step == 1 && 'Intake Questionnaire'}
            {step == 2 && 'Review'}
          </div>
          {step == 2 && editable == false && (
            <div className="review-edit">
              {' '}
              <Button
                kind="text"
                className={editable ? 'review-edit-button-active' : 'review-edit-button-inactive'}
                onClick={toggleEdit}
              >
                {' '}
                <span className="Appkit4-icon icon-pencil-outline">&nbsp;Edit</span>{' '}
              </Button>
            </div>
          )}
        </div>

      </div>
      {questions.data.map((q: any) => {
        return (
          <div key={q.questionNo}>{
            (step === q.sectionId || step == 2) && (
              <div className="question-container">
                <Question
                  question={q}
                  onChange={handleAnswer}
                  reviewing={step == 2 && !editable}
                ></Question>
              </div>
            )
          }
          </div>
        );
      })}
      <div>
        <hr></hr>
      </div>
      <div className="button-container">
        <Button kind="negative" onClick={() => moveForward(false)} className="question-button">
          {(step == 2 && editable) ? 'Cancel' : 'Back'}{' '}
        </Button>
        {!readOnlyAccess &&
          <Button
            kind="negative"
            onClick={() => moveForward(true)}
            className="question-button"
            disabled={!validated}
          >
            {step == 2 ? (editable ? 'Save' : 'Submit') : 'Review'}
          </Button>
        }

      </div>
    </div>
  );
};
