import { IQuestion } from '../../models/IQuestion';
import { IQuestionnaire } from '../../models/IQuestionnaire'
import { RawContent } from '../RawContent/RawContent';

import "./QuestionView.css"

//Show Questions with Answer in Pending Approval Page
export const QuestionView = (props: { questionnaire: IQuestionnaire }) => {
    return (
        <div className="question-view-container">
            <div className='title'>
                Intake Questionnaire
            </div>
            <hr></hr>
            {props.questionnaire.questions.map((question: IQuestion) => {
                return (

                    <div key={question.questionNo} className='question-container'>
                        <div className="question-title">
                            {question.questionNo}. <RawContent input={question.question} links={question.questionLinks}></RawContent>
                        </div>
                        {question.subtitle.length > 0 &&
                            <div className="question-subtitle">
                                {question.subtitle.map((s: any, index: any) => {
                                    return (<div key={index}>{s}</div>)
                                })}
                            </div>
                        }

                        <div className="answer">
                            {!question.skipAnswer && <div>{question.answer}</div>}
                            <div>{question.additionalAnswer}</div>
                        </div>
                    </div>
                );
            })}
        </div>
    )
}

