import { CalendarPicker, Search, Select } from '@appkit4/react-components';
import React, { useEffect, useState } from 'react';
import { ILeaderboardFilter } from '../../models/ILeaderboardFilter';
import { LeaderboardType } from '../../utils/Constants';
import { getDateOffset, getInitLeaderboardFilter } from '../../utils/QuestionUtil';
import { DropdownList } from '../DropdownList';

import './LeaderboardFilter.css';

//Leaderboard Filter Settings
export const LeaderboardFilter = (props: {
  type: string;
  filterChanged(filter: ILeaderboardFilter): any;
}) => {
  const [filter, setFilter] = useState<ILeaderboardFilter>(getInitLeaderboardFilter(props.type));
  const [dateRange, setDateRange] = useState<Array<Date> | undefined>(undefined);
  const [divisions, setDivisions] = useState([]);

  const [frequence, setFrequence] = React.useState('');
  const [problemTypes, setProblemTypes] = React.useState<string[]>([]);

  const handleChange = (name: string, value: any) => {
    setFilter({ ...filter, [name]: value });
  };

  const dateRangeChagne = (dates: Array<Date>) => {
    if (dates?.length == 2) {
      setFrequence('');
      setFilter({ ...filter, ['startDate']: dates[0], ['endDate']: dates[1] });
    }
    if (dates) setDateRange(dates);
  };

  const handleFrequence = (value: string) => {
    switch (value) {
      case 'All Time':
        setFilter({ ...filter, ['endDate']: new Date(), ['startDate']: getDateOffset(-100, 0, 0) });
        break;
      case 'Yearly':
        setFilter({ ...filter, ['endDate']: new Date(), ['startDate']: getDateOffset(-1, 0, 0) });
        break;
      case 'Monthly':
        setFilter({ ...filter, ['endDate']: new Date(), ['startDate']: getDateOffset(0, -1, 0) });
        break;
      case 'Weekly':
        setFilter({ ...filter, ['endDate']: new Date(), ['startDate']: getDateOffset(0, 0, -7) });
        break;
    }
    setDateRange(undefined);
    setFrequence(value);
  };

  useEffect(() => {
    props.filterChanged(filter);
  }, [filter]);

  return (
    <div className="leaderboard-filter-container">
      <div>
        <Search
          onChange={(value) => handleChange('search', value)}
          onSearch={(value) => handleChange('search', value)}
        />
      </div>
      <div className="hidden sm:block">
        <CalendarPicker
          selectRange={true}
          value={dateRange}
          placeholder="mm/dd/yyyy to mm/dd/yyyy"
          mode="showDoubleCalendar"
          locale="en"
          fieldTitle="Date range"
          disabledDates={[
            new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 2),
          ]}
          onChange={(value: Array<Date>) => dateRangeChagne(value)}
        />
      </div>
      <div className="hidden sm:block">
        <DropdownList
          multiple={false}
          field="report-type"
          value={frequence}
          placeholder={'Report Type'}
          readonlyAccess={false}
          onSelected={handleFrequence}
        ></DropdownList>
      </div>
      {props.type !== LeaderboardType.Division && (
        <div className="division hidden sm:block">
          <DropdownList
            multiple={true}
            field="division"
            value={divisions}
            readonlyAccess={false}
            placeholder={'Divisions'}
            onSelected={(value) => handleChange('divisions', value)}
          ></DropdownList>
        </div>
      )}
      {props.type === LeaderboardType.Individual && (
        <div className="unit hidden sm:block">
          <DropdownList
            multiple={true}
            field="business-unit"
            value={''}
            group={divisions}
            placeholder={'Units'}
            readonlyAccess={false}
            onSelected={(value) => handleChange('units', value)}
          ></DropdownList>
        </div>
      )}
      {props.type === LeaderboardType.Individual && (
        <div className="hidden sm:block">
          <DropdownList
            multiple={true}
            field="problem-type"
            value={problemTypes}
            placeholder={'Problem Types'}
            readonlyAccess={false}
            onSelected={(value) => {
              setProblemTypes(value);
              handleChange('problemTypes', value);
            }}
          ></DropdownList>
        </div>
      )}
    </div>
  );
};
