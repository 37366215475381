import { apiGetPDF } from '../../services/apiService';


//show pdf file from blob storage
export const BlobLink = ({ children, file, onClick }: any) => {

    const openFile = () => {
        apiGetPDF(file).then((response: any) => {
            window.open(URL.createObjectURL(response.data));
        })
        if(onClick){
            onClick();
        }
    }

    return (
        <a href="#" onClick={openFile} >{children}</a>
    )
}