import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { IProblem } from '../../models/IProblem';
import { IQuestionnaire } from '../../models/IQuestionnaire';
import { IRequesProblemtView } from '../../models/IRequestProblemView';
import { apiAddNotification, apiGetRequestProblem, apiTraceEvent, apiUpdateRequestStatus } from '../../services/apiService';
import { ProblemView } from './ProblemView';
import { QuestionView } from './QuestionView';

import "./Approval.css"
import { Button } from '@appkit4/react-components';
import { RequestModal } from '../Modal/RequestModal';
import { AppPages, ProblemStatus, RequestStatus } from '../../utils/Constants';
import { getInitProblem, getInitQuestionnaire } from '../../utils/QuestionUtil';
import { INotificationType } from '../Notification/INotificationType';
import { UserContext } from '../UserContext/UserContext';

//Main Component for Pending Approval Page
export const Approval = () => {
  const { problemId } = useParams();
  const navigate = useNavigate();
  const [user] = useContext(UserContext);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("");

  const initProblem: IProblem = getInitProblem();

  const initQuestionnaire: IQuestionnaire = getInitQuestionnaire();

  const [requestProblem, setRequestProblem] = useState<IRequesProblemtView>({ problem: initProblem, questionnaire: initQuestionnaire });

  useEffect(() => {
    apiGetRequestProblem(problemId || '').then((res: any) => {

      setRequestProblem(res.data);
    })

  }, [])

  const openModal = (kind: string) => {
    setModalType(kind);
  }

  const closeModal = (result: boolean, status:string, message: string) => {
    setModalType("");
    if(result){
      apiUpdateRequestStatus(problemId||'', {requestStatus: status, requestMessage: message}).then(()=>{
        apiAddNotification(problemId||'', problemId||'', status==="Approved"?INotificationType.APPROVE:INotificationType.DECLINE).then(()=>{
          apiTraceEvent(user?.mail, problemId||'',status).then(()=>{
            navigate(`/${AppPages.REQUESTS}`);
          })
          
        });
      });
    }
  };

  return (
    <div className='approval-container'>
      <div className="bar"> <span>Requests</span> &nbsp; &gt; &nbsp; Request Pending Approval</div>
      <div className='title'>Request Pending Approval</div>

      <ProblemView problem={requestProblem.problem}></ProblemView>
      <QuestionView questionnaire={requestProblem.questionnaire}></QuestionView>
      <hr></hr>
      <div className="title">Your approval</div>
      <div className="buttons">
        <Button kind="negative" onClick={()=>openModal(RequestStatus.Approved)} >Approve Problem Solving</Button>
        <Button kind="secondary" onClick={()=>openModal(RequestStatus.Declined)}>Decline</Button>
      </div>
       <RequestModal show={modalType!=""} type={modalType} problem={requestProblem.problem} onClose={closeModal}></RequestModal>  
    </div>
  )
}


