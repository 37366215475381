import { useContext } from 'react';
import { Layout } from '../../components/Layout/Layout';
import { TrainingContents } from '../../components/TrainingContents/TrainingContents';
import { UserContext } from '../../components/UserContext/UserContext';
import { AppPages } from '../../utils/Constants';
import { useTrackPage } from '../../utils/useTrackPage';

export const Training = () => {
  const [user, setUser] = useContext(UserContext);
  useTrackPage(AppPages.TRAINING);
  return <Layout currentTab="training">{user && <TrainingContents></TrainingContents>}</Layout>;
};
