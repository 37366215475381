import React, { useEffect, useState } from 'react';
import { IResponseQuestion } from '../../models/IResponseQuestion';

import { groupArrayOfObjects } from '../../utils/Utils';
import { Button } from '@appkit4/react-components';
import './QuestionGroup.css';
import { QuestionDatePicker } from '../QuestionComponents/QuestionDatePicker';
import { QuestionDropdown } from '../QuestionComponents/QuestionDropdown';
import { QuestionEditText } from '../QuestionComponents/QuestionEditText';
import { QuestionRichText } from '../QuestionComponents/QuestionRichText';
import { QuestionCategorySelectDropdown } from '../QuestionComponents/QuestionCategorySelectDropdown';
import { QuestionEmojiPicker } from '../QuestionComponents/QuestionEmojiPicker';


//Component Group within Step
export const QuestionGroup = (props: {
    group: number;
    stepGroupQuestions: IResponseQuestion[];
    filterCategory: string;
    onChanged(key: string, name: string, vals: any): any;
    onRemove(group: number): any;
    onFocus(key: string, name: string, vals: any): any;
    readOnlyAccess: boolean;
}) => {
    const [groups, setGroups] = useState({});
    const [value, setValue] = useState('');

    useEffect(() => {
        props.stepGroupQuestions.map((question: IResponseQuestion) => {
            if (question.questionType === 'categoryselect') {
                setValue(question.answerText);
            }
        })
    }, [])

    useEffect(() => {
    }, [value])

    useEffect(() => {
        setGroups(groupArrayOfObjects(props.stepGroupQuestions, 'group'));
    }, [props.stepGroupQuestions]);

    useEffect(() => {}, [groups]);

    const getOptions = (question: any) => {
        let array;
        if (question.questionType == "categoryselect") {
            question.categoryOptions.map((option: any) => {
                if (props.filterCategory == option.key) {
                    array = option.value.map((val: any) => {
                        return { value: val, label: val };
                    })
                }
            })
        } else {
            array = question.answerOptions.map((option: any) => {
                return { value: option, label: option };
            });
        }
        return array;
    };
  
    const handleAnswer = (key: any, name: any, val: any, question: IResponseQuestion) => {
        if (question?.questionType === "categoryselect") {
            setValue(val);
        }
        props.onChanged(key, name, val);
    };

    const handleFocus = (key: any, name: any, val: any, question: IResponseQuestion) => {
          props.onFocus(key, name, val);
    };

    const removeGroup = () => {
        props.onRemove(props.group);
    };

    return (
        <div className="step-group-questions-container">
            {props.group > 1 && (
                <div>
                <hr></hr>
                <div className="remove-bin">
                    {(props.readOnlyAccess === false) && <Button kind="text" onClick={removeGroup}>
                    <span className="Appkit4-icon icon-delete-outline"></span> Remove
                    </Button>}
                </div>
                </div>
            )}
            {props.stepGroupQuestions.map((question: any) => {
                if (question.questionType == 'categoryselect') {
                    return (
                        <QuestionCategorySelectDropdown
                            key={question._id}
                            question={question}
                            multiple={false}
                            value={value}
                            options={getOptions(question)}
                            onSelected={handleAnswer}
                            readOnlyAccess={props.readOnlyAccess}
                        ></QuestionCategorySelectDropdown>
                    );
                }
                if (question.questionType == 'richtext') {
                    return (
                        <QuestionRichText
                            key={question._id}
                            question={question}
                            onChange={handleAnswer}
                            onFocus={handleFocus}
                            readOnlyAccess={props.readOnlyAccess}
                        ></QuestionRichText>
                    );
                }
                if (question.questionType == 'text') {
                    return (
                        <QuestionEditText
                        key={question._id}
                        question={question}
                        onChange={handleAnswer}
                        readOnlyAccess={props.readOnlyAccess}
                        ></QuestionEditText>
                    );
                }
                if (question.questionType == 'singleselect') {
                    return (
                        <QuestionDropdown
                            key={question._id}
                            question={question}
                            multiple={false}
                            options={getOptions(question)}
                            onSelected={handleAnswer}
                            readOnlyAccess={props.readOnlyAccess}
                        ></QuestionDropdown>
                    );
                }
                if (question.questionType == 'multiselect') {
                    return (
                        <QuestionDropdown
                            key={question._id}
                            question={question}
                            multiple={true}
                            options={getOptions(question)}
                            onSelected={handleAnswer}
                            readOnlyAccess={props.readOnlyAccess}
                        ></QuestionDropdown>
                    );
                }
                if (question.questionType == 'datepicker') {
                    return (
                        <QuestionDatePicker
                            key={question._id}
                            question={question}
                            onChange={handleAnswer}
                            readOnlyAccess={props.readOnlyAccess}
                        ></QuestionDatePicker>
                    );
                }
                if (question.questionType == 'emojiselect') {
                    return (
                        <QuestionEmojiPicker
                            key={question._id}
                            question={question}
                            onChange={handleAnswer}
                        ></QuestionEmojiPicker>
                    );
                }
            })}
        </div>
    );
};
