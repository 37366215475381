/* Account Popup that appears when the top right user icon is clicked */
import React, { useEffect, useState, useContext } from 'react';
import { useAccount, useMsal } from '@azure/msal-react';
import { UserContext } from '../UserContext/UserContext';
import logoutImage from '../../assets/images/logout-fill.svg';
import profileIcon from '../../assets/images/profile_icon.svg';
import { handleLogout } from '../../utils/Utils';
import { apiSetFirstTimeUserToFalse, apiBlacklistToken } from '../../services/apiService';
import { BlobImage } from '../Blobs/BlobImage';

export const HeaderMobNavMenu = (props: {
  show: boolean;
  onClose: any;
  onShowProfile: any;
  onShowNotification: any;
  onShowHelp: any;
}) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const [user, setUser] = useContext(UserContext);
  const [nav, setNav] = useState(false);

  /* Handles user logout */
  const handleSubmitLogout = async (instance: any) => {
    await apiSetFirstTimeUserToFalse(user.mail); // set the first time user to false
    await apiBlacklistToken(); // blacklist the token before logging out
    handleLogout(instance);
  };
  const handleClose = () => {
    props.onClose();
    setNav(false);
  };
  useEffect(() => {
    setNav(props.show);
  });

  /* Opens the profile page to be updated when the profile button is clicked on the popup */
  const openProfile = () => {
    props.onClose();
    props.onShowProfile();
    setNav(false);
  };
  const openHelp = () => {
    props.onClose();
    props.onShowHelp();
    setNav(false);
  };
  const openNotification = () => {
    props.onClose();
    props.onShowNotification();
    setNav(false);
  };
  return (
    <>
      <div
        className={
          nav
            ? 'fixed left-0 top-[0px] text-[20px] leading-[18px] font-normal text-[#7D7D7D] w-full  h-full sm:hidden  bg-[#F3F3F3] ease-in-out duration-500 z-10 mb-[20px]'
            : 'ease-in-out duration-500 fixed left-[-100%]'
        }
      >
        <div className="flex justify-left items-center bg-white">
          <BlobImage
            file={`logo_${process.env.REACT_APP_COMPANYID?.toLocaleLowerCase()}.jpg`}
          ></BlobImage>
          <span>{process.env.REACT_APP_LOGIN_TITLE}</span>
          <button
            onClick={() => props.onClose()}
            type="button"
            className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 ml-[28%]"
          >
            <svg
              className="h-8 w-8"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <ul className="mt-8">
          <li className=" p-3 mb-4 ml-[10px] flex items-center" onClick={openProfile}>
            <img className="w-[8%]" src={profileIcon} /> <div className="ml-2">Profile</div>
          </li>
          <li
            className=" p-3 mb-4 ml-[10px] flex items-center hover:bg-[#d83737] hover:text-white"
            onClick={() => handleSubmitLogout(instance)}
          >
            <img className="w-[8%]" src={logoutImage} />
            <div className="ml-2">Logout</div>
          </li>
          <li>
            <hr className="bg-[#C5C5C5] w-[409px] top-[312px] mt-[20px] mb-[20px]"></hr>
          </li>
          <li className="p-3 mb-4 hover:bg-[#d83737] hover:text-white" onClick={openNotification}>
            <span className="Appkit4-icon icon-notification-outline ap-font-20 sm:ap-font-24 icon-spacing"></span>{' '}
            Notifications
          </li>
          <li className=" p-3 mb-4 hover:bg-[#d83737] hover:text-white" onClick={openHelp}>
            {' '}
            <span className="Appkit4-icon icon-help-question-outline ap-font-20 icon-spacing"></span>{' '}
            Help
          </li>
          <li className=" p-3 mb-4 flex items-center hover:bg-[#d83737] hover:text-white">
            <div className="i-circle-mobNav ml-2">{account?.name ? account.name[0] : ''}</div>
            <div className="ml-2">{account?.name}</div>
          </li>
        </ul>
      </div>
    </>
  );
};
