import { useContext } from 'react';
import { Layout } from '../../components/Layout/Layout';
import { ResultChart } from '../../components/ResultChart/ResultChart';
import { UserContext } from '../../components/UserContext/UserContext';
import { AppPages } from '../../utils/Constants';
import { useTrackPage } from '../../utils/useTrackPage';


export const Result = () => {
  const [user, setUser] = useContext(UserContext);
  useTrackPage(AppPages.RESULT);
  return <Layout currentTab="problem">{
    user && <ResultChart></ResultChart>
  }</Layout>;
};
