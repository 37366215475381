import React, { useEffect, useRef } from 'react';

export const Canvas = (props: any) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const draw = (ctx: any) => {
    const padding = 50;
    const length = ctx.canvas.width;
    ctx.strokeStyle = '#7D7D7D';
    ctx.fillStyle = '#7D7D7D';
    //const height = ctx.cavas.height;
    //draw X
    ctx.font = '14px sans-serif';
    ctx.beginPath();
    ctx.lineWith = 0.5;

    ctx.moveTo(padding, length - padding);
    ctx.lineTo(length - padding, length - padding);
    ctx.lineTo(length - padding - 10, length - padding - 10);
    ctx.moveTo(length - padding, length - padding);
    ctx.lineTo(length - padding - 10, length - padding + 10);
    ctx.stroke();
    // ctx.fillText("Ease of Implementation", (length-padding)*0.4, length-padding+20)
    // ctx.fillText("5 - Hard ", padding, length-padding+30)
    //ctx.fillText("3", (length-padding)*0.6, length-padding+30)
    ctx.fillText('Implementation', (length - padding) * 0.5, length - padding + 30);
    ctx.fillText('Easy', padding, length - padding + 30);
    ctx.fillText('Hard ', length - padding - 20, length - padding + 30);
    //draw Y
    ctx.beginPath();
    ctx.moveTo(padding, length - padding);
    ctx.lineTo(padding, padding);
    ctx.lineTo(padding + 10, padding + 10);
    ctx.moveTo(padding, padding);
    ctx.lineTo(padding - 10, padding + 10);
    ctx.stroke();
    ctx.fillText('High', padding - 50, padding + 20);
    //ctx.fillText("4", padding-30, (length-padding)*0.4)
    ctx.fillText('Low', padding - 50, length - padding + 5);
    ctx.save();
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.translate(length / 2, length / 2);
    ctx.rotate((-90 * Math.PI) / 180);
    ctx.fillText('Impact on Performance', 0, 0 - (length - padding) / 2);
    ctx.restore();
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas != null) {
      const context = canvas.getContext('2d');
      draw(context);
    }
  });

  return (
    <div className="flex">
      <canvas
        ref={canvasRef}
        {...props}
        width="550px"
        height="550px"
        className="hidden sm:block"
      ></canvas>
      <canvas
        ref={canvasRef}
        {...props}
        width="350px"
        height="350px"
        className="sm:hidden"
      ></canvas>
    </div>
  );
};
