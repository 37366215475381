import { useContext } from 'react';
import { Layout } from '../../components/Layout/Layout';
import { TrainingContents } from '../../components/TrainingContents/TrainingContents';
import { UserContext } from '../../components/UserContext/UserContext';
import './Backend.css';
export const Backend = () => {
  const [user, setUser] = useContext(UserContext);
  return (
    <Layout currentTab="backend">
      <div className="backend-page-info">This is the backend page</div>
    </Layout>
  );
};
