import { CycleTimeChart } from './charts/CycleTimeChart';
import { FeedbackChart } from './charts/FeedbackChart';
import { ProblemStageGrid } from './charts/ProblemStageGrid';
import { ScreenTimeGrid } from './charts/ScreenTimeGrid';

//Reports for CI Insights 
export const CIInsightsCharts = () => {
  return (
    <div>
      <div className="report-chart-title">
        Screen Time
      </div>
      <div>
        <ScreenTimeGrid></ScreenTimeGrid>
      </div >
      <div className="report-chart-title">Problem Stage</div>
      <div>
        <ProblemStageGrid></ProblemStageGrid>
      </div>
      <div className="report-charts-container-responsive">
        <div className='report-chart'>
          <div className="report-chart-title">
            Feedback
          </div>
          <div className='report-chart-container'>
            <FeedbackChart></FeedbackChart>
          </div>
        </div>
        <div className='report-chart'> <div className="report-chart-title">Cycle Time</div>
          <div className="report-chart-container">
            <CycleTimeChart></CycleTimeChart>
          </div></div>
      </div>
    </div>
  )
}
