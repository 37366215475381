import { Link } from 'react-router-dom';
import { IProblem } from '../../models/IProblem';
import { RequestStatus } from '../../utils/Constants';

import "./RequestCard.css"
import { Badge } from '@appkit4/react-components';
import { getNameInitials, getProblemStatus } from '../../utils/QuestionUtil';
import { getDateInfo } from '../../utils/Utils';
import { useEffect, useState } from 'react';
import { apiFindUser } from '../../services/apiService';


//Show Problem Information in Request List
export const RequestCard = (props: { problem: IProblem }) => {

  const [department, setDepartment] = useState('');
  
  useEffect(() => {
    const populateInteractions = async () => {
      const department = await apiFindUser(props.problem.userId);
      setDepartment(department?.data?.userProfile?.department);
    };
    populateInteractions();
  }, []);

  return (
    <div className="request-card-container">
      <div className="user">
        { !(props.problem.requestStatus === RequestStatus.Approved||props.problem.requestStatus === RequestStatus.Declined) && <span className="i-circle">{getNameInitials(props.problem.owner)}</span>}
        <div className="user-info">
          {getProblemStatus(props.problem) === RequestStatus.Pending && <span>{props.problem.owner} has sent you a request</span>}
          {getProblemStatus(props.problem) === RequestStatus.Approved && <span>You approved {props.problem.owner}'s request</span>}
          {getProblemStatus(props.problem) === RequestStatus.Declined && <span>You declined {props.problem.owner}'s request</span>}
          {getProblemStatus(props.problem) === RequestStatus.Solved && <span>{props.problem.owner} has solved the problem</span>}
          <div>
            <span className="time-label">{getDateInfo(props.problem.requestStatusUpdatedOn)}</span>
            <span className="dept-label">{department}</span>
          </div>
        </div>
        <div className="request-status">
          <span className={getProblemStatus(props.problem)}>
            <Badge value={getProblemStatus(props.problem)} />
          </span>
        </div>
      </div>
      { getProblemStatus(props.problem) === RequestStatus.Pending && props.problem.requestMessage && <div className="request-message">
        {props.problem.requestMessage}
      </div>
      }
      <div className="problem-title">
        <Link to={`/approval/${props.problem._id}`}>  {props.problem.title}</Link>
      </div>
      <div className="problem-description">{props.problem.description}</div>
      <div>

      </div>
    </div>
  );
};

