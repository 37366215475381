// import constants for notification text
import { NotificationText } from "../../../utils/Constants";

// when someone commented on the current user's post
export const commentOnCurrentUserPost = (userWhoCommentedFirstName: string, userWhoCommentedLasttName: string) => {
    return (
        `${userWhoCommentedFirstName} ${userWhoCommentedLasttName} ${NotificationText.textCommentOnCurrentUserPost}:`
    );
}

// when someone liked the current user's post
export const likeOnCurrentUserPost = (userWhoLikedFirstName: string, userWhoLikedLasttName: string) => {
    return (
        `${userWhoLikedFirstName} ${userWhoLikedLasttName} ${NotificationText.textLikeOnCurrentUserPost}:`
    );
}

// when someone liked the current user's post
export const currentUserTagged = (whoTaggedCurrentUserFirstname: string, whoTaggedCurrentUserLastname: string) => {
    return (
        `${whoTaggedCurrentUserFirstname} ${whoTaggedCurrentUserLastname} ${NotificationText.textCurrentUserTagged}:`
    );
}

// when someone commented on a problem the current user was tagged on
export const commentOnProblemWithCurrentUserTagged = (notificationOwnerFirstName: string, notificationOwnerLastName: string) => {
    return (
        `${notificationOwnerFirstName} ${notificationOwnerLastName} ${NotificationText.textCommentOnProblemWithCurrentUserTagged}:`
    );
}

// when someone liked a problem the current user was tagged on
export const likeOnProblemWithCurrentUserTagged = (notificationOwnerFirstName: string, notificationOwnerLastName: string) => {
    return (
        `${notificationOwnerFirstName} ${notificationOwnerLastName} ${NotificationText.textLikeOnProblemWithCurrentUserTagged}:`
    );
}

// when request to approve was received
export const requestToCoach = (notificationOwnerFirstName: string, notificationOwnerLastName: string) => {
    return (
        `${notificationOwnerFirstName} ${notificationOwnerLastName} ${NotificationText.textSendRequestToCoach}:`
    );
}

// when request was declined
export const declineProblem = (notificationOwnerFirstName: string, notificationOwnerLastName: string) => {
    return (
        `${notificationOwnerFirstName} ${notificationOwnerLastName} ${NotificationText.textDeclineRequest}:`
    );
}

// when request was approved
export const approveProblem = (notificationOwnerFirstName: string, notificationOwnerLastName: string) => {
    return (
        `${notificationOwnerFirstName} ${notificationOwnerLastName} ${NotificationText.textApproveRequest}:`
    );
}

// when problem reaching deadline or stale
export const problemDeadline = (daysToDeadline: number) => {
    if (daysToDeadline > 0){
        return (
            `${NotificationText.textProblemDeadlineGreaterThan0}, ${daysToDeadline} days left:`
        );
    } else if ( daysToDeadline == 0){
        return (
            `${NotificationText.textProblemDeadlineToday}:`
        );
    } else if ( daysToDeadline < 0){
        return (
            `${daysToDeadline*-1} ${NotificationText.textProblemDeadlineLessThan0}:`
        );
    }
    return ('')
}