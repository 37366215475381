import React, { useEffect, useState, useRef } from "react";

import { CalendarPicker, Input, Select } from "@appkit4/react-components";
import { IResponseQuestion } from "../../models/IResponseQuestion";

import "./QuestionGridCell.css"
import { QuestionGridDropCell } from "./QuestionGridDropCell";
import { RawContent } from "../RawContent/RawContent";
import { TagUsers } from "../TagUsers/TagUsers";
import { dropdownSort } from "../../utils/Utils";


//Cell component with the Row for Grid 
export const QuestionGridCell = (props: { question: IResponseQuestion, onChanged(key: string, name: string, vals: any): any, readOnlyAccess: boolean }) => {

    //set tomorrow as min date
    const tomorrow =  new Date();
    tomorrow.setHours(24,0,0,0);

    const [minDate, setMinDate] = useState<Date>((tomorrow));
    const [value, setValue] = useState('');
    const [allOptions, setAllOptions] = useState<string[]>();
    const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  
    useEffect(() => {
        fetchOptions();
    }, []);

    useEffect(() => {
        setValue(props.question.answerText);
    }, [props.question])

    const fetchOptions = () => {
        const tempOptions: string[] = [];
        const options = getOptions(props.question);
        options.map((option: any) => {
          tempOptions.push(option.value);
        })
        setAllOptions(tempOptions);
    }

    const selectionChanged = (vals: any) => {
        props.onChanged(props.question._id, "answerText", vals)
    }

    //load the dropdown options
    const getOptions = (question: any) => {
        const array = question.answerOptions.map((option: any) => {
            return { value: option, label: option };
        });
        return array.sort(dropdownSort);
    };

    function handleChangeDebounced(value: string) {
        // Clear the previous timeout
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
        }

        // Set a new timeout
        timeoutIdRef.current = setTimeout(() => {
            props.onChanged(props.question._id, "answerText", value)
        }, 300); // 300ms debounce time
    }

    const handleKeyboard = (event: any) => {
        setValue(event.target.value);
        handleChangeDebounced(event.target.value);
    }

    const handleChange = (event: any) => {
        props.onChanged(props.question._id, "answerText", value)
    }
    
    return (

        <div className="question-cell-container">
            {
                (props.question.group == 1) &&

                <div className="column-header">
                    <RawContent input={props.question.question} links={props.question.questionLinks}></RawContent>{props.question.required && <span className="question-red-star">*</span>}
                </div>
            }
            <div className="question-cell">

                {props.question.questionType == "date" &&
                    <CalendarPicker
                        disabled={props.readOnlyAccess}
                        className="cell-datepicker"
                        placeholder="mm/dd/yyyy"
                        editable={false}
                        locale="en"
                        value={props.question.answerText||undefined}
                        onValueChange={(vals: Date) => { selectionChanged(vals) }}
                        minDate={(props.question.answerText&&new Date(props.question.answerText)<minDate)?props.question.answerText:minDate}
                    />
                }
                {props.question.questionType == "text" &&
                    <textarea
                        readOnly={props.question.readonly || props.readOnlyAccess}
                        placeholder = {props.question.placeholder}
                        className="cell-text"
                        maxLength={2000}
                        name="description" value={value} onChange={handleKeyboard}
                    >
                    </textarea>
                }
                {props.question.questionType == "tagusers" &&
                    <TagUsers   value={value} readOnlyAccess={props.readOnlyAccess} onChange={selectionChanged}></TagUsers>
                }                
                 {props.question.questionType == "number" &&
                    <input
                        disabled={props.readOnlyAccess}
                        type="number"
                        min="1"
                        readOnly={props.question.readonly}
                        placeholder = {props.question.placeholder}
                        className="cell-number"
                        name="description" value={value} onChange={handleChange}
                    >
                    </input>
                }
                 {props.question.questionType == "hypothesis" &&
                    <div className="cell-label-center">
                        {value}
                    </div>
                }
                {props.question.questionType == "drag" &&
                   <QuestionGridDropCell question={props.question} onDropped={props.onChanged} readOnlyAccess={props.readOnlyAccess}></QuestionGridDropCell>
                }
                {props.question.questionType == "singleselect" &&
                    <Select
                        disabledItemValues={props.readOnlyAccess ? allOptions : []}
                        className="cell-singleselect"
                        multiple={false}
                        placeholder={''}
                        dropdownAlwaysDown={false}
                        data={getOptions(props.question)}
                        value={value}
                        valueKey={'value'}
                        labelKey={'label'}
                        searchable={false}
                        showSelectAll={true}
                        onSelect={(vals: any) => {
                            selectionChanged(vals);
                        }}
                    ></Select>
                }
                      {props.question.questionType == "multipleselect" &&
                    <Select
                        disabledItemValues={props.readOnlyAccess ? allOptions : []}
                        multiple={true}
                        placeholder={''}
                        dropdownAlwaysDown={false}
                        data={getOptions(props.question)}
                        value={value}
                        valueKey={'value'}
                        labelKey={'label'}
                        searchable={false}
                        showSelectAll={true}
                        onSelect={(vals: any) => {
                            selectionChanged(vals);
                        }}
                    ></Select>
                }


            </div>

        </div>

    )

}