import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useContext} from 'react';
import './Welcome.css';
import { UserContext } from '../UserContext/UserContext';
import { BlobImage } from '../Blobs/BlobImage';
import { AppPages } from '../../utils/Constants';
import { apiSetFirstTimeUserToFalse } from '../../services/apiService';
export const Welcome = () => {
  const navigate = useNavigate();
  const [user, setUser] = useContext(UserContext);
  const getStarted = () => {
    //set the first time user to false
    apiSetFirstTimeUserToFalse(user.mail).then(()=>{
      navigate(`/${AppPages.PROBLEM}`);
    })
  };

  return (
    <div className="welcome-container">
      <div className="welcome-title">Welcome to {process.env.REACT_APP_LOGIN_TITLE}</div>
      <div className="welcome-content">
        We Solve is a self-serve and engaging application that allows you to enter, prioritize, and
        solve problems. It will equip you and your team with the right tools and support to take you
        from problem identification to solutions, creating a community of problem solvers to
        collaborate and share ideas with.
      </div>
      <div className="welcome-image">
        <BlobImage
          file={`welcome_${process.env.REACT_APP_COMPANYID?.toLocaleLowerCase()}.jpg`}
        ></BlobImage>
      </div>
      <div className="welcome-content">Lets get started!</div>
      <div className="welcome-button">
        <button onClick={getStarted}>Get started</button>
      </div>
    </div>
  );
};
