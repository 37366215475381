import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../components/Layout/Layout';
import { Reports } from '../../components/Reports/Reports';
import { UserContext } from '../../components/UserContext/UserContext';
import { AppPages } from '../../utils/Constants';
import { useTrackPage } from '../../utils/useTrackPage';

import './Reporting.css';

export const Reporting = () => {

  const [user, setUser] = useContext(UserContext);
  useTrackPage(AppPages.REPORTING);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!user?.trainingCompleted) {
      navigate(`/${AppPages.TRAINING}`);
    } 
  }, []);
  
  return (
    <Layout currentTab="reporting">
     {
      user && <Reports/>
     } 
    </Layout>
  );
};
