import { useEffect, useState } from 'react';
import { Rate } from '@appkit4/react-components/rate';
import { IResponseQuestion } from "../../models/IResponseQuestion";

import "./QuestionEmojiPicker.css";

export const QuestionEmojiPicker = (props: {
    question: IResponseQuestion;
    onChange: any;
}) => {

    const [value, setValue] = useState<number>();

    const onChange = (value: number) => {
        setValue(value);
        props.onChange(props.question._id, "answerText", value.toString());
    };


    return (
        <div className="question-emojipicker-container">
            <div className="title">
                {props.question.step}{props.question.questionNo}. {props.question.question}{props.question.required && <span className="question-red-star"> * </span>}
            </div>
            <Rate type={"sentiment"} value={value} onChange={onChange} allowClear={false}></Rate>
        </div>
    );
}