import { useEffect, useState } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { apiQueryAppUsage, apiQueryUserProblems } from '../../../services/apiService';
import { getDateOffset, getInitQueryFilter, getUpdatedFilter } from '../../../utils/QuestionUtil';
import { IQueryFilter } from '../../../models/IQueryFilter';
import { DropdownList } from '../../DropdownList';
import { UserDropdown } from '../../UserDropdown';
import { CustomToolbar } from '../../../utils/UIUtil';


//Screen Time Report
export const ScreenTimeGrid = () => {

  const [gridData, setGridData] = useState([]);
  const [pageSize, setPageSize] = useState<number>(10);

  const [filter, setFilter] = useState<IQueryFilter>(getInitQueryFilter())
  const [frequence, setFrequence] = useState('All Time');
  const [user, setUser] = useState('');

  const handleFrequence = (value: string) => {
    setFilter(getUpdatedFilter(value, filter));
    setFrequence(value);
  }

  const userSelected = (value: any) => {
    setUser(value);
    setFilter({ ...filter, ["users"]: [value] })
  }

  function getHours(params: any) {
    const totalSeconds = Number(params.row.total);
    const totalMinutes = Math.floor(totalSeconds / 60);
  
    const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
  
    return `${hours}h ${minutes} m ${seconds}s ` ;
  }  

  const columns: GridColDef[] = [
    { field: 'page', headerName: 'Page', flex: 1, minWidth: 80 },
    { field: 'hours', headerName: 'Screen Time', valueGetter: getHours, flex: 1, minWidth: 80, cellClassName: 'leaderboarder-grid-solved-cell' },
  ]

  useEffect(() => {
    apiQueryAppUsage(filter).then((res: any) => {
      setGridData(res.data);
    })
  }, [filter])

  return (
    <div>
      <div className='report-filter'>
        <div>
          <DropdownList multiple={false} field="report-type" value={frequence} placeholder={"Report Type"} readonlyAccess={false} onSelected={handleFrequence}></DropdownList>
        </div>
        <div>
          <UserDropdown onSelected={userSelected}></UserDropdown>
        </div>
      </div>
      <div className="report-grid">
        <DataGrid
          rows={gridData}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 25, 50]}
          getRowId={(row: any) => row._id.userId + row._id.name}
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{ toolbar: { printOptions: { disableToolbarButton: true } } }}
          autoHeight={true}
          headerHeight={60}
          rowHeight={60}
        />
      </div>
    </div>
  )
}
