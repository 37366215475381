import React, { useState, useEffect } from 'react';

import { Modal } from '@appkit4/react-components/modal';

import { CreateProfile } from "./CreateProfile"
import { UpdateProfile } from "./UpdateProfile"

export const ProfileModal = (props: { onCreated: any,flag:any }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);
  const handleClose = () => {
    setVisible(false);
    props.onCreated()
  }
  return (
    <>
      <Modal
        visible={visible}
        closable={props.flag=='create'?false:true}
        maskCloseable={false}
        onCancel={handleClose}
        className="profile-modal"
        header={<strong className='home_profile_title'>{props.flag=='create'?'Create Profile':'Update Profile'}</strong>}
      >
        {props.flag=='create' &&<CreateProfile onCreated={handleClose} />}
        {props.flag=='update' &&<UpdateProfile onUpdated={handleClose} />}
      </Modal>
    </>
  );
}