import React, { useEffect, useState } from 'react'
import { Combobox, Tag } from '@appkit4/react-components';
import { IUserTag } from '../../models/IUserTag';
import { apiTagUser } from '../../services/apiService';
import { Constants } from '../../utils/Constants';

import "./TagUsers.css"

export const TagUsers = (props: {value:any, readOnlyAccess: boolean, onChange(value:any):any }) => {
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [data, setData] = useState<IUserTag[]>([]);


    const removeUser = (index: number) => {
        const _selectedUsers = [...selectedUsers];
        _selectedUsers.splice(index, 1);
        setSelectedUsers(_selectedUsers);
        props.onChange(_selectedUsers);
    };

    const handleSearchChange = (event: any) => {
        setSearchText(event.target.value);
    };

    const onSelectUser = (list: any) => {
        const filterList = list.filter((item:any)=>item!='')
        setSelectedUsers(filterList);
        props.onChange(filterList);
    };

    const fetchTeamMemberUsers = async () => {
        let _data: IUserTag[] = [{ desc: 'users', type: 'group', children: [] }];
        //get the list of users that match a search string
        const userList = await apiTagUser(searchText);
        //add the returned list to the user group list
        userList.data.allUsersContainingString.map((userDetails: any) => {
            _data[0].children.push({
                value: userDetails.email,
                desc:
                    userDetails.email + '  ' + '(' + userDetails.firstName + ' ' + userDetails.lastName + ')',
            });
        });
        setData(_data);
    };

    const onClear = () => {
        setSearchText('');
        fetchTeamMemberUsers();
    };


    //set the users list to its initial state
    const handleDropDownVisibility = (value: any) => {
        if (!value) setSearchText('');
    };

    useEffect(()=>{
        setSelectedUsers(props.value);
    },[props.value])

    useEffect(() => {
        //wait for the debounceTime to call fetchTeamMemberUsers
        const debounce = setTimeout(() => {
            fetchTeamMemberUsers();
        }, Constants.debounceTime);
        return () => clearTimeout(debounce);
    }, [searchText]);


    return (

        <div className="tag-user-component">
            <div>
                <Combobox
                    data={data}
                    valueKey={'value'}
                    labelKey={'desc'}
                    placeholder="Tag team member"
                    multiple
                    showSelectAll={false}
                    value={selectedUsers}
                    onChange={handleSearchChange}
                    onClear={onClear}
                    onSelect={onSelectUser}
                    onVisibleChange={handleDropDownVisibility}
                    disabled={props.readOnlyAccess}
                ></Combobox>
            </div>
            <div className="tag-user-list">
                {selectedUsers?.length !== 0 &&
                    (selectedUsers?.map((user, i) => {
                        return <Tag key={user} onClose={() => !props.readOnlyAccess && removeUser(i)}>{user}</Tag>;
                    }))
                }
            </div>
        </div>


    )
}
