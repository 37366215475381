import { useEffect, useState } from 'react';
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  Tooltip,
  Legend,
  Bar,
  YAxis,
  LabelList,
  Cell,
} from 'recharts';
import { IQueryFilter } from '../../../models/IQueryFilter';
import { apiQueryFeedbacks } from '../../../services/apiService';
import { getDateOffset, getInitQueryFilter, getUpdatedFilter } from '../../../utils/QuestionUtil';
import { DropdownList } from '../../DropdownList';

//Feedback Report Chart
export const FeedbackChart = () => {
  const initData: any[] = [];
  const [data, setData] = useState<any[]>(initData);
  const [division, setDivision] = useState<string[]>([]);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
  const [filter, setFilter] = useState<IQueryFilter>(getInitQueryFilter());
  const [frequence, setFrequence] = useState('All Time');

  const handleFrequence = (value: string) => {
    setFilter(getUpdatedFilter(value, filter));
    setFrequence(value);
  };

  const divisionSelected = (value: any) => {
    setFilter({ ...filter, ['divisions']: [value] });
    setDivision(value);
  };

  useEffect(() => {
    apiQueryFeedbacks(filter).then((res: any) => {
      const rates = res.data.map((item: any) => {
        return {
          _id: item._id,
          name: item._id == 1 ? 'Overall User Experience' : 'Training Materials and Guidance',
          description:
            item._id == 1
              ? 'Overall user experience with the application'
              : 'The availability of training materials and guidance throughout the tool',
          'Average Rate': item.avg,
        };
      });
      setData(rates);
    });
  }, [filter]);

  return (
    <div>
      <div className="report-filter">
        <div>
          <DropdownList
            multiple={false}
            field="report-type"
            value={frequence}
            placeholder={'Report Type'}
            readonlyAccess={false}
            onSelected={handleFrequence}
          ></DropdownList>
        </div>
        <div className="division">
          <DropdownList
            multiple={true}
            field="division"
            value={division}
            readonlyAccess={false}
            placeholder={'Division'}
            onSelected={(value) => divisionSelected(value)}
          ></DropdownList>
        </div>
      </div>
      <div>
        {data.length > 0 && (
          <div>
            {' '}
            <ResponsiveContainer width={350} height={350}>
              <BarChart data={data}>
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend
                  payload={data.map((item, index) => ({
                    id: item._id,
                    type: 'square',
                    value: `${item.description}`,
                    color: COLORS[item._id],
                  }))}
                />
                <Bar dataKey="Average Rate">
                  <LabelList dataKey="Average Rate" position="top" />
                  {data.map((entry, index) => (
                    <Cell key={entry._id} fill={COLORS[entry._id]} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>
    </div>
  );
};
