import React, { useContext } from 'react';
import { Layout } from '../../components/Layout/Layout';
import { Strategic } from '../../components/Strategic/Strategic';
import { UserContext } from '../../components/UserContext/UserContext';
import { AppPages } from '../../utils/Constants';
import { useTrackPage } from '../../utils/useTrackPage';

import './StrategicPage.css';

export const StrategicPage = () => {
  const [user, setUser] = useContext(UserContext);
  useTrackPage(AppPages.STRATEGIC);
  return (
    <Layout currentTab="problem">
      <div className="strategic-page-container">
      { user && <Strategic></Strategic>}
      </div>
    </Layout>
  );
};
