import React, { useEffect, useState } from 'react'

import { Pagination, Tooltip } from '@appkit4/react-components';

import "./SlideShow.css"
import { isVideoFile } from '../../utils/Utils';
import { BlobImage } from '../Blobs/BlobImage';
import { BlobVideo } from '../Blobs/BlobVideo';



export const SlideShow = (props: { files: string[], currentIndex: number, onDelete: any }) => {

    const [current, setCurrent] = useState<number>(0);

    const onPageChange = (page: number) => {
        setCurrent(page - 1);
    }

    const deleteSlide = () => {
        props.onDelete(current);
    }

    useEffect(() => {
        setCurrent(props.currentIndex);
    }, [props.currentIndex])

    return (
        <div className='slideshow-outer-container'>
            <div className="slideshow-container">
                {props.files?.map((file, index) => {
                    return (current === index && <div className="slide" key={file} >
                        { isVideoFile(file) ? <BlobVideo file={file}></BlobVideo> : <BlobImage file={file}></BlobImage>}
                    </div>)
                })
                }
            </div>
            <div className='pagination-container'>
                <div>
                    <Pagination key={current + 1} current={current + 1} total={props.files.length} onPageChange={onPageChange}></Pagination>
                </div>
                <div>
                    <Tooltip
                        trigger='hover'
                        position='top-left'
                        distance={4}
                        id="tooltipDesc"
                        content='Delete'
                    >
                        {props.onDelete != '' && <span aria-describedby="tooltipDesc" className="Appkit4-icon icon-delete-fill" onClick={deleteSlide}></span>}
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}