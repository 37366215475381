import React from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';

//Problem Pie Chart
export const ProblemPieChart = (props: { data: any[] }) => {

  const COLORS = ['#203570', '#49ABA0', '#E0301E', '#E27588'];

  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
     <ResponsiveContainer  aspect={1} width={360} height={360} >
      <PieChart >
      <Legend verticalAlign="bottom" height={36}/>
        <Pie
          data={props.data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          innerRadius="50%"
          outerRadius="100%"
          fill="#8884d8"
          dataKey="total"
        >
          {props.data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
     </ResponsiveContainer>
  )
}
