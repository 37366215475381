import { IResponseQuestion } from '../../models/IResponseQuestion'
import { IStepHeading } from '../../models/IStepHeading';
import { mapStrategicNavigationStep } from '../../utils/Utils';
import QuestionRating from '../QuestionComponents/QuestionRating';

import "./QuestionRatingGroup.css"


//The UI component to show a group of rating
export const QuestionRatingGroup = (props: { step: number, stepQuestions: IResponseQuestion[], heading: IStepHeading, onChanged(key: string, name: string, vals: any): any, readOnlyAccess: boolean }) => {

    return (
        <div className="step-rating-group-questions-container">

            <div className="step-title">Step {props.heading.stepInTitle?props.heading.stepInTitle:props.heading.step}: {props.heading.title}</div>
            <div className="step-subtitle">
                {props.heading.subtitle} <span className="question-red-star">*</span>
            </div>
            <div>
                <div className="rating-table-header">
                    <div className="rating-table-header-left">{props.heading.quote}</div>
                    <div className="rating-table-header-right">Description of Force</div>
                    <div className="rating-table-header-rational">Comments</div>
                </div>
                <div className="rating-table-body">
                    {props.stepQuestions.map((question: any) => {
                        if (question.questionType == 'rating') {
                            return (
                                <QuestionRating
                                    key={question._id}
                                    question={question}
                                    onChange={props.onChanged}
                                    readOnlyAccess={props.readOnlyAccess}
                                ></QuestionRating>
                            );
                        }

                    })}
                </div>
            </div>

        </div>
    );
};

