import { DataGrid, GridColDef } from '@mui/x-data-grid'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { IQueryFilter } from '../../../models/IQueryFilter';
import { apiQueryAppUsage, apiQueryProblemStages, apiQueryUnitUsers } from '../../../services/apiService';
import { getDateOffset, getInitQueryFilter, getUpdatedFilter } from '../../../utils/QuestionUtil';
import { CustomToolbar } from '../../../utils/UIUtil';
import { DropdownList } from '../../DropdownList';


//Problem Stage Table
export const ProblemStageGrid = () => {

  const [gridData, setGridData] = useState([]);
  const [pageSize, setPageSize] = useState<number>(10);

  const [filter, setFilter] = useState<IQueryFilter>(getInitQueryFilter())
  const [frequence, setFrequence] = useState('All Time');

  const handleFrequence = (value: string) => {
    setFilter(getUpdatedFilter(value, filter));
    setFrequence(value);
  }

  function getDays(params: any) {
    const today = new Date();
    const last = new Date(params.row.lastTouch);
    let difference = Math.abs(today.getTime() - last.getTime());
    const days = Math.ceil(difference / (1000 * 3600 * 24));
    return days;
  }

  const columns: GridColDef[] = [
    { field: 'title', headerName: 'Problem Title', flex: 1, minWidth: 80 },
    { field: 'owner', headerName: 'Problem Owner', flex: 1, minWidth: 80 },
    { field: 'type', headerName: 'Type of Problem', flex: 1, minWidth: 80 },
    { field: 'stage', headerName: 'Stage of Problem', flex: 1, minWidth: 80 },
    { field: 'days', headerName: 'Days in Stage', flex: 1, minWidth: 80, valueGetter: getDays, },
    { field: 'lastTouch', headerName: 'Last Updated', flex: 1, minWidth: 80, valueFormatter: params => moment(params?.value).format("DD/MM/YYYY") },
  ]

  useEffect(() => {
    apiQueryProblemStages(filter).then((res: any) => {
      setGridData(res.data);
    })
  }, [filter])

  return (
    <div>
      <div className='report-filter'>
        <div>
          <DropdownList multiple={false} field="report-type" value={frequence} placeholder={"Report Type"} readonlyAccess={false} onSelected={handleFrequence}></DropdownList>
        </div>
      </div>
      <div className="report-grid">
        <DataGrid
          rows={gridData}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 25, 50]}
          getRowId={(row: any) => row._id}
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{ toolbar: { printOptions: { disableToolbarButton: true } } }}
          autoHeight={true}
          headerHeight={60}
          rowHeight={60}
        />
      </div>
    </div>
  )
}
