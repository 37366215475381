import React, { useEffect, useState } from 'react'
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Legend } from 'recharts';
import { apiQueryUsers } from '../../../services/apiService';
import { getInitQueryFilter } from '../../../utils/QuestionUtil';

//Users By Division Chart
export const UsersByDivisionChart = () => {

  const initData: any[] = [];
  const [data, setData] = useState<any[]>(initData);

  useEffect(() => {
    const filter = getInitQueryFilter();
    apiQueryUsers(filter).then((res: any) => {
      setData(res.data)
    })
  }, [])

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#203570', '#49ABA0', '#E0301E', '#E27588'];

  return (
    <ResponsiveContainer width={420} height={700}>
      <PieChart >
        <Legend layout="vertical" verticalAlign="bottom" />
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label
          innerRadius="50%"
          outerRadius="90%"
          dataKey="total"
          nameKey="name"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}
