/*
 * Model that displays a single notification for the user
 * Accepts: {
 *   notificaation: an INotification that is to be displayed
 * }
 */

import { useEffect, useState } from 'react';
import { Avatar } from '@appkit4/react-components/avatar';
import { INotification } from '../../models/INotification';
import { INotificationType } from './INotificationType';
import { IProblem } from '../../models/IProblem';
// import text body
import {
  commentOnCurrentUserPost,
  likeOnCurrentUserPost,
  problemDeadline,
  currentUserTagged,
  commentOnProblemWithCurrentUserTagged,
  likeOnProblemWithCurrentUserTagged,
  requestToCoach,
  declineProblem,
  approveProblem,
} from './NotificationText/textBody';

import {
  apiFetchProblemUsingProblemId,
  apiMarkOneNotificationSeenForUser,
} from '../../services/apiService';

import { Constants } from '../../utils/Constants';

import './NotificationListItem.css';

export const NotificationListItem = (props: { notification: INotification }) => {
  const [textToShow, setTextToShow] = useState('');
  const [avatar, setAvatar] = useState('');
  const [notification, setNotification] = useState<INotification>(props.notification);
  const [problem, setProblem] = useState<IProblem>();
  const [timeLapse, setTimeLapse] = useState('');
  const [daysToDeadline, setDaysToDeadline] = useState(0);

  useEffect(() => {
    renderData();
    setNotification(props.notification);
    setAvatar(fetchAvatar());
    fetchProblem();
    setTimeLapse(fetchTimeLapseSinceNotification());
    setDaysToDeadline(getDaysToDeadline());
  }, [daysToDeadline]);

  const fetchAvatar = () => {
    return (
      props.notification.firstName[0].toLocaleUpperCase() +
      props.notification.lastName[0].toLocaleUpperCase()
    );
  };

  const fetchProblem = async () => {
    const problemId = props.notification.problemId;
    apiFetchProblemUsingProblemId(problemId).then((res) => {
      const currentProblem: IProblem = res?.data.RegisterProblem;
      if (currentProblem) {
        setProblem(currentProblem);
      }
    });
  };

  const getDaysToDeadline = () => {
    if (!problem) {
      return 0;
    }
    const targetDate = new Date(problem.targetDate).getDate();
    const notificationDate = new Date(props.notification.createdAt).getDate();
    return targetDate.valueOf() - notificationDate.valueOf();
  };

  const renderData = () => {
    // current user owns the problem
    if (props.notification.problemOwner === props.notification.userToBeNotified) {
      if (props.notification.notificationType === INotificationType.COMMENT) {
        setTextToShow(
          commentOnCurrentUserPost(props.notification.firstName, props.notification.lastName),
        );
      } else if (props.notification.notificationType === INotificationType.LIKE) {
        setTextToShow(
          likeOnCurrentUserPost(props.notification.firstName, props.notification.lastName),
        );
      } else if (props.notification.notificationType === INotificationType.DEADLINE) {
        setTextToShow(problemDeadline(daysToDeadline));
      } else if (props.notification.notificationType === INotificationType.DECLINE) {
        setTextToShow(declineProblem(props.notification.firstName, props.notification.lastName));
      } else if (props.notification.notificationType === INotificationType.APPROVE) {
        setTextToShow(approveProblem(props.notification.firstName, props.notification.lastName));
      }
    }
    // someone tagged the current user in their problem
    else if (
      props.notification.problemOwner === props.notification.notificationOwner &&
      props.notification.notificationType !== INotificationType.Request
    ) {
      if (props.notification.notificationType === INotificationType.TAG) {
        setTextToShow(currentUserTagged(props.notification.firstName, props.notification.lastName));
      }
    }
    // someone liked/commented on the problem current user was tagged on
    else if (props.notification.notificationOwner !== props.notification.userToBeNotified) {
      if (props.notification.notificationType === INotificationType.COMMENT) {
        setTextToShow(
          commentOnProblemWithCurrentUserTagged(
            props.notification.firstName,
            props.notification.lastName,
          ),
        );
      } else if (props.notification.notificationType === INotificationType.LIKE) {
        setTextToShow(
          likeOnProblemWithCurrentUserTagged(
            props.notification.firstName,
            props.notification.lastName,
          ),
        );
      } else if (props.notification.notificationType === INotificationType.Request) {
        setTextToShow(requestToCoach(props.notification.firstName, props.notification.lastName));
      }
    }
    return;
  };

  // returns a string of time elapsed since notification was launched
  const fetchTimeLapseSinceNotification = () => {
    const minuteInSeconds = 60;
    const hourInSeconds = 60 * minuteInSeconds;
    const dayInSeconds = 24 * hourInSeconds;
    const notificationTime: Date = new Date(notification.createdAt);
    const currentTime: Date = new Date();
    const timeDiffInSeconds = (currentTime.getTime() - notificationTime.getTime()) / 1000;

    if (Math.floor(timeDiffInSeconds / dayInSeconds) > 0) {
      const numOfDays = Math.floor(timeDiffInSeconds / dayInSeconds);
      if (numOfDays > 1) {
        return `${numOfDays} days ago`;
      }
      return `${1} day ago`;
    } else if (Math.floor(timeDiffInSeconds / hourInSeconds) > 0) {
      const numOfHours = Math.floor(timeDiffInSeconds / hourInSeconds);
      if (numOfHours > 1) {
        return `${numOfHours} hours ago`;
      }
      return `${1} hour ago`;
    } else if (Math.floor(timeDiffInSeconds / minuteInSeconds) > 0) {
      const numOfMinutes = Math.floor(timeDiffInSeconds / minuteInSeconds);
      if (numOfMinutes > 1) {
        return `${numOfMinutes} minutes ago`;
      }
      return `${1} minute ago`;
    }
    return `${Math.floor(timeDiffInSeconds)} seconds ago`;
  };

  const handleClick = async () => {
    setNotification({ ...notification, read: true });
    await apiMarkOneNotificationSeenForUser(notification);
  };

  return (
    <div className="item">
      {textToShow && (
        <div
          className={` flex gap-3 px-[10px] py-[20px] h-[100px] w-[420px] cursor-pointer   ${
            notification?.read ? `hover:bg-[#BBBBBB]` : ` bg-[#FCECEC] hover:bg-[#E6CBCB]`
          }`}
          onClick={handleClick}
        >
          <div className="i-circle-notifications">{avatar}</div>
          <div className="flex flex-col ">
            <div className="text-[11px]">
              <div>
                {' '}
                {textToShow} <b>{problem?.title}</b>
              </div>
            </div>
            <div className="text-[11px]">{timeLapse}</div>
          </div>
        </div>
      )}
    </div>
  );
};
