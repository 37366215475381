import { ILookup } from "../models/ILookup.type";

import { Select } from "@appkit4/react-components";
import React, { useEffect, useState } from "react";
import { apiGetLookup } from "../services/apiService";
import { dropdownSort } from "../utils/Utils";

export const DropdownList = (props: { multiple: boolean, field: string, value: any, placeholder?: string, group?: string[], readonlyAccess:boolean, onSelected(vals: any): any }) => {

    const [options, setOptions] = useState<{ value: string, label: string, group: string }[]>([]);
    const [value, setValue] = useState<any>('');

    const fetchOptions = async () => {
        apiGetLookup(props.field)
            .then(res => {
                let businessUnits = res.data.lookups.map((option: ILookup) => {
                    return { value: option.value, label: option.label, group: option.group }
                });
                if (props.group&&props.group.length>0) {
                    businessUnits = businessUnits.filter((option: any) => props.group?.includes(option.group));
                }
                setOptions(businessUnits.sort(dropdownSort));
            });
    }

    useEffect(() => {
        fetchOptions();
    }, []);

    useEffect(()=>{
      setValue(props.value);
    },[options])

    useEffect(() => {
        if (props.group) {
            fetchOptions();
            if (props.multiple) {
                setValue([]);
            } else {
                setValue('');
            }
        }
    }, [props.group]);

    useEffect(() => {
        setValue(props.value);
    }, [props.value])


    const selectionChanged = (vals: any) => {
        setValue(vals);
        props.onSelected(vals)
    }

    return (
        <>
            {options.length > 0 &&
                <Select
                    multiple={props.multiple}
                    placeholder={props.placeholder? props.placeholder:""}
                    dropdownAlwaysDown={false}
                    disabledItemValues={props.readonlyAccess ? options.map(o=>o.value) : []}
                    data={options}
                    value={value}
                    valueKey={'value'}
                    labelKey={'label'}
                    showSelectAll={true}
                    searchable={false}
                    onSelect={(vals: any) => { selectionChanged(vals) }}
                >
                </Select>}
        </>
    )
        ;
}