import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { now } from 'moment';
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../components/Layout/Layout'
import { Leaderboard } from '../../components/Leaderboard/Leaderboard'
import { UserContext } from '../../components/UserContext/UserContext';
import { AppPages } from '../../utils/Constants';
import { useTrackPage } from '../../utils/useTrackPage';

export const LeaderBoarderPage = () => {
  const navigate = useNavigate();
  const [user, setUser] = useContext(UserContext);

  useTrackPage(AppPages.LEADERBOARD);
  
  useEffect(() => {
    if (!user?.trainingCompleted) {
      navigate(`/${AppPages.TRAINING}`);
    } 
  }, []);  
  
 
  return (
    <Layout currentTab="leaderboard">
     { user&&<Leaderboard></Leaderboard>}
  </Layout>
  )
}

