import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_APPINSIGHTS,
        extensions: [reactPlugin],
        enableAutoRouteTracking: true,
        autoTrackPageVisitTime: true,
        enableCorsCorrelation: true
    }
});
appInsights.loadAppInsights();
appInsights.trackPageView();

appInsights.addTelemetryInitializer((env:ITelemetryItem) => {
    env.tags = env.tags || [];
    env.tags["companyId"] = process.env.REACT_APP_COMPANYID||"";
});

export { reactPlugin, appInsights};