import React from 'react';
import { BlobLink } from '../Blobs/BlobLink';

//create dynamic links for content input
export const RawContent = (props: { input: string; links: string[] }) => {
  return (
    <>
      {(() => {
        if (props.links?.length > 0) {
        
          //create dynamic links if links are provided
          const sections = props.input.split(props.links[0]); //split content based on the link source
          if (sections.length >1 && props.links.length == 2) {
            return (
              <>
                {sections[0]} <BlobLink file={`${props.links[1]}`}>{props.links[0]}</BlobLink>{' '}
                {sections[1]}
              </> //return new component with link
            );
          } else if(sections.length >1 && props.links.length == 3) { //adding icon
            return (
              <>
                {sections[0]} <BlobLink file={`${props.links[1]}`}>{props.links[0]}{' '}<span className={props.links[2]}></span></BlobLink>{' '}
                {sections[1]}
            
              </> //return new component with link
            )
          }
          else {
            return <> {props.input} </>;
          }
        } else {
          //legacy link
          switch (true) {
            case props.input === 'add definitions of direct and indirect constraint below question':
              {
                return (
                  <>
                    <div>
                      <b>Direct Constraint:</b> Are bottlenecks or obstacles that prevent Pembina from achieving the primary goal of generating profit
                    </div>
                    <div>
                      <b>Indirect Constraint:</b> Constraints that impact indirectly the primary goal of generating profit. These constraints are often a result of organizational systemic issues (systems, data, etc.)
                    </div>
                  </>
                );
              }
              break;

            default:
              {
                return <span>{props.input}</span>;
              }
              break;
          }
        }
      })()}
    </>
  );
};
