import { Tooltip } from "@appkit4/react-components"
import { blue, red } from "@material-ui/core/colors"
import {useDrag} from "react-dnd"

import "./DragSquare.css"


//The square component with tooltip to be able to be dragged
export const DragSquare = (props: { name:string|undefined, id: string, x:string|undefined, y:string |undefined, readOnlyAccess: boolean }) => {

const [{isDragging}, drag] = useDrag(()=>({
    type:"option",
    canDrag: (props.readOnlyAccess === false),
    item: {name: props.name, id: props.id},
    collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
    })

}))

    return (

      <Tooltip
      className="tooltip"
      trigger='hover'
      position='top-left'
      distance={4}
      id="dragHyperthesis"
      content={props.name}
  >
       <div aria-describedby="dragHyperthesis" className="square"  ref={drag} style={{ backgroundColor: 'yellow', top: `${props.y}px`, left: `${props.x}px` }}>
         {props.id}
       </div>
  </Tooltip>
    )
  }
  
