import { useEffect, useState } from 'react';
import { ILookup } from '../../models/ILookup.type';
import { apiGetLookup } from '../../services/apiService';
import { BlobLink } from '../Blobs/BlobLink';
import pwcLogoImage from '../../assets/images/PoweredbyPwC.svg';
import './Footer.css';

export const Footer = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState('');
  const [termsOfService, setTermsOfService] = useState('');
  const [ossOfService, setossOfService] = useState('');

  useEffect(() => {
    apiGetLookup(`${process.env.REACT_APP_PDF_FOOTER1}`).then((res: any) => {
      let pdfs = res.data.lookups.map((option: ILookup) => {
        return option.value;
      });
      if (pdfs) setPrivacyPolicy(pdfs[0]);
    });
    apiGetLookup(`${process.env.REACT_APP_PDF_FOOTER2}`).then((res: any) => {
      let pdfs = res.data.lookups.map((option: ILookup) => {
        return option.value;
      });
      if (pdfs) setTermsOfService(pdfs[0]);
    });
    apiGetLookup(`${process.env.REACT_APP_PDF_FOOTER3}`).then((res: any) => {
      let pdfs = res.data.lookups.map((option: ILookup) => {
        return option.value;
      });
      if (pdfs) setossOfService(pdfs[0]);
    });
  }, []);

  return (
    <footer className="footer-container">
      <div>
        <img src={pwcLogoImage} />
      </div>
      <hr className=" opacity-10 mt-0 mb-0"></hr>
      <div className=" sm:hidden">
        © 2023 PricewaterhouseCoopers LLP, an Ontario limited liability partnership. All rights
        reserved.
      </div>
      <div className=" hidden sm:block">
        © 2023 PricewaterhouseCoopers LLP, an Ontario limited liability partnership. All rights
        reserved. PwC refers to the Canadian member firm, and may sometimes refer to the PwC
        network. Each member firm is a separate legal entity. Please see &nbsp;
        <a href="www.pwc.com/structure" target="_blank">
          www.pwc.com/structure
        </a>
        &nbsp; for further details.
      </div>
      <div>
        <BlobLink file={privacyPolicy}>Privacy Policy</BlobLink>
      </div>
      <div>
        <BlobLink file={termsOfService}>Terms of Service</BlobLink>
      </div>
      <div>
        <BlobLink file={ossOfService}>OSS Standard</BlobLink>
      </div>
    </footer>
  );
};
