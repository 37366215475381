import React, { useEffect, useState } from 'react'
import { IDragHypothesis } from '../../models/IDragHypothesis'
import { IResponseQuestion } from '../../models/IResponseQuestion'
import { IStepHeading } from '../../models/IStepHeading'
import { groupArrayOfObjects, mapStrategicNavigationStep } from '../../utils/Utils'
import { StrategicInformation } from '../Strategic/StrategicInformation'
import { ApproachChart } from './ApproachChart'
import { DragQuestion } from './DragQuestion'

import "./HypothesisApproach.css"

//Strategic Step 7 Hypothesis Drag and Drop Component
export const HypothesisApproach = (props: { step: number, stepQuestions: IResponseQuestion[], heading: IStepHeading, onChanged(key: string, name: string, vals: any): any, onAddGroup(step:number): any, onDropped(dragHyperthesis: IDragHypothesis): any, readOnlyAccess: boolean }) => {


  const [groups, setGroups] = useState({});

  useEffect(() => {
    setGroups(groupArrayOfObjects(props.stepQuestions, "group"));
  }, [props.stepQuestions])

  return (
    <div className='ha-container'>

      <div className="step-title">Step {props.heading.stepInTitle?props.heading.stepInTitle:props.heading.step}: {props.heading.title}</div>
      <div className="step-subtitle">
        {props.heading.subtitle} <span className="question-red-star">*</span>
      </div>
      <div className="ha-dragndrop">

        <div className="ha-draggable">
          <div className="drag-instruction" >
            <div><span className="Appkit4-icon icon-information-outline"></span></div>
            <div className="drag-instructino-content">{props.heading.quote}</div>
          </div>
          {

            groups && Object.keys(groups).map(
              (keyName: string, i: number) => {
                let g: any[] = groups[keyName as keyof typeof groups];
                const w = g.find((p: any) => p.questionNo === '0' && p.answerText == "")
                if (w) return (

                  <DragQuestion key={props.step + keyName} group={Number(keyName)} dragNo={'a'} contentProperty={'answerText'} showIndex={true} stepGroupQuestions={groups[keyName as keyof typeof groups]} readOnlyAccess={props.readOnlyAccess}></DragQuestion>
                )
              }
            )
          }
        </div>
        <div className="ha-droppable">
          <ApproachChart stepQuestions={props.stepQuestions} onDropped={props.onDropped} action="" readOnlyAccess={props.readOnlyAccess}></ApproachChart>
          <div >
            <StrategicInformation></StrategicInformation>
          </div>
        </div>
      </div>
    </div>

  )
}
