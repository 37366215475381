import moment from 'moment-timezone';
import { IResponse } from "../models/IResponse";
import { IStepHeading } from "../models/IStepHeading";
import { apiGetLookup } from "../services/apiService";
import { getInitStepHeading } from './QuestionUtil';

export function handleLogout(instance: any) {
    instance.logoutPopup().catch((e: any) => {
        console.error(e);
    });
}

export function groupArrayOfObjects(list: any, key: any) {
    return list.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export function isVideoFile(file: string) {
    let result = false;
    const videoExtentions = process.env.REACT_APP_VIDEO_FILES?.split(", ");
    if(videoExtentions)
    {
        videoExtentions.map((extention:string)=>{
            if(file.endsWith(extention.trim())){
              result = true;
            }
        })
            
    }
    return result;
}


//Map strategic steps to navigation steps 
export  const mapStrategicNavigationStep = (step:number): number => {
    if (!step) return 1;
    if (step <= 5) return step;
    else if (step <= 8) return step - 1;
    else return step - 2;
  }

  //return specific heading by step number
  export  const getStepHeading = (headings: IStepHeading[], step:number): IStepHeading => {
    const stepHeading = headings.find((h:IStepHeading)=>h.step==step);
    return stepHeading? stepHeading: getInitStepHeading();
  }


/*
 * checks if the page is authorized.
 * @param {string,Object} page - The page name,
                          user - user details
 * returns true if the page is in the list of authorizedPages, flase if otherwise.
 */
export function _isAuthorized(page: string,user:any) {
    return user?.authorizedPages?.includes(page);
  }
  


export function getInitResponse (): IResponse {
        const initResponse: IResponse = {
          companyId: '',
          problemId: '',
          userId: '',
          achievedBenefit: '',
          questions: [],
          headings: []
        };
        return initResponse;
}

export const getDateInfo = (date: Date) => {
  const dateDiff = moment(new Date()).tz('America/New_York')
    .diff(moment(date).tz('America/New_York'), 'days');
  if (dateDiff == 0) return 'Today in ';
  if (dateDiff > 0) return dateDiff == 1 ? 'One day ago in ' : `${dateDiff} days ago in `;
  return 'Just Now in';
};

export const getUpdatedDateInfo = (date: Date) => {
  const dateDiff = moment(new Date()).tz('America/New_York')
    .diff(moment(date).tz('America/New_York'), 'days');
  if (dateDiff == 0) return 'Today ';
  if (dateDiff > 0) return dateDiff == 1 ? 'One day ago ' : `${dateDiff} days ago `;
  return 'Just Now ';
};

export const dropdownSort = (a: any, b: any): number => {
  if (a.label < b.label) {
      return -1;
  }
  if (a.label > b.label) {
      return 1;
  }
  return 0;
}


