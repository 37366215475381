import { useAccount, useMsal } from '@azure/msal-react';
import React, { useEffect, useState } from 'react'
import { IQueryFilter } from '../../models/IQueryFilter';
import { apiQueryUserProblems } from '../../services/apiService';
import { AppRoles } from '../../utils/Constants';
import { getInitQueryFilter, getUpdatedFilter } from '../../utils/QuestionUtil';
import { DropdownList } from '../DropdownList';
import { UserDropdown } from '../UserDropdown';
import { ProblemPieChart } from './charts/ProblemPieChart';
import { ProblemStackedBarCharts } from './charts/ProblemStackedBarChart';


//Reports for Problems
export const ProblemCharts = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);

  const initQueryFilter = getInitQueryFilter();
  initQueryFilter.users = [account?.idTokenClaims?.roles?.includes(AppRoles.Coach) ? '' : account?.username || '']

  const [filter, setFilter] = useState<IQueryFilter>(initQueryFilter)
  const [frequence, setFrequence] = React.useState('All Time');
  const initData: any[] = [];
  const [data, setData] = useState<any[]>(initData);

  const handleFrequence = (value: string) => {
    setFilter(getUpdatedFilter(value, filter));
    setFrequence(value);
  }

  const userSelected = (value: any) => {
    setFilter({ ...filter, ["users"]: [value] })
  }

  useEffect(() => {
    apiQueryUserProblems(filter).then((res: any) => {
      if(res.data){
        const result = [];
        result.push(res.data[0]?.quickwin);
        result.push(res.data[0]?.gem);
        result.push(res.data[0]?.strategic);
        result.push(res.data[0]?.avoid);
        setData(result);
      }
    })
  }, [filter])

  return (
    <div className="report-tab-container">
      <div className="report-filter">
        <div>
          <DropdownList multiple={false} field="report-type" value={frequence} placeholder={"Report Type"} readonlyAccess={false} onSelected={handleFrequence}></DropdownList>
        </div>
        <div>
          {account?.idTokenClaims?.roles?.includes(AppRoles.Coach) && <UserDropdown onSelected={userSelected}></UserDropdown>}
        </div>
      </div>
      <div className="report-charts-container-responsive">
        <div className="report-chart">
          <div className="report-chart-title">Numbers of problems solved vs in progress</div>
          {data.length > 1 &&
            <div className="report-chart-container report-problem-stackedbar">
              <ProblemStackedBarCharts data={data}></ProblemStackedBarCharts>
            </div>
          }
        </div>
        <div className="report-chart">
          <div className="report-chart-title">Numbers of total problems</div>
          <div className='report-chart-container'>
            <ProblemPieChart data={data}></ProblemPieChart>
          </div>
        </div>
      </div>
    </div>
  )
}
