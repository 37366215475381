import React from 'react'
import { IResponseQuestion } from '../../models/IResponseQuestion'
import { Rate } from '@appkit4/react-components/rate';
import "./QuestionRating.css"


//Rating Components to show rate as radio to choose
const QuestionRating = (props: { question: IResponseQuestion, onChange: any, readOnlyAccess: boolean }) => {

  const handleRateChange = (value: number) => {
    props.onChange(props.question._id, 'answerText', value);
  };

  const handleAnswerChange = (event: any) => {
    props.onChange(props.question._id, 'comment', event.target.value);
}

  return (
    <div className='rating-container'>
        <div className={"rating-name "+(props.question.step%2?"rating-background-color-1":"rating-background-color-2")}>
            {props.question.question}
        </div>
        <div className="rating-content">
            <div className="rating-description">
                {props.question.subtitle}
            </div>
            <div className="rating-radios">
              <div className="radio-left">
              Not present
              </div>
              <div className="radio-middle">
                 <div className="radio-middle-label">
                  <div>1</div><div>2</div><div>3</div><div>4</div><div>5</div>
                 </div>
                 <div>
                 <Rate type={props.readOnlyAccess ? 'readonly' : 'baseline'} value={props.question.answerText}   onChange={handleRateChange}></Rate>
                 </div>
              </div>
              <div className="radio-right">
                  Fully present
              </div>
           
            </div>
        </div>
        <div className="rating-rationale">
        <textarea placeholder={props.question.placeholder} value={props.question.comment} onChange={handleAnswerChange} readOnly={props.readOnlyAccess} />
        </div>
    </div>
  )
}

export default QuestionRating