import React, { useEffect, useState, useContext } from 'react';
import { Modal } from '@appkit4/react-components';
import { Button } from '@appkit4/react-components/button';
import { CommentModal } from '../Comment/CommentModal';
import { LikeModal } from '../Like/LikeModal';
import { ILike } from '../../models/ILike';
import { INotificationType } from '../Notification/INotificationType';
import { IProblem } from '../../models/IProblem';
import { v4 as uuidv4 } from 'uuid';
import { Loading } from '@appkit4/react-components/loading';
import { SlideShow } from '../SlideShow/SlideShow';
import placeholderImage from '../../assets/images/Placeholder-Image-Square.png';
import { AppPages } from '../../utils/Constants';
import { UserContext } from '../UserContext/UserContext';

import { apiAddNotification } from '../../services/apiService';

import './ProblemModal.css';
import {
  apiGetProblem,
  apiGetHomepageProblemPopUp,
  apiCreateLike,
  apiIncrementLike,
  apiDeleteLike,
  apiDecrementLike,
  apiHasLiked,
} from '../../services/apiService';
import { useNavigate } from 'react-router-dom';

export const ProblemModal = (props: {
  show: boolean;
  problem: IProblem;
  changeModalStatus: any;
  changeCommentCount: any;
  changeLikeCount: any;
}) => {
  const navigate = useNavigate();
  const [user, setUser] = useContext(UserContext);
  const [visible, setVisible] = useState(false);
  const [liked, setLiked] = useState(false);
  const [likeModal, setLikeModal] = useState(false);
  const [numberOfLikes, setNumberOfLikes] = useState(0);
  const [files, setFiles] = useState<{ data: string[] }>({ data: [] });
  const [fileIndex, setFileIndex] = useState<number>(0);
  const [question, setProblemQuestion] = useState('');
  const [answer, setProblemAnswer] = useState('');
  const likeReqObject: ILike = {
    companyId: process.env.REACT_APP_COMPANYID || '',
    likeId: uuidv4().toString(),
    userId: user?.mail,
    firstName: user?.givenName,
    lastName: user?.surname,
    problemId: props.problem._id,
  };
  useEffect(() => {
    setVisible(props.show);
    /* fetch the files associated with the problem */
    const fetchFiles = async () => {
      //fetch response to show on popup
      const response = await apiGetHomepageProblemPopUp(props.problem._id);
      setProblemQuestion(response.data.title);
      setProblemAnswer(response.data.description);
      setFiles({ data: response.data.attachments });
    };
    fetchFiles();
  }, []);

  useEffect(() => {
    fetchLikes();
    hasLiked();
  }, [liked]);

  const fetchLikes = async () => {
    //get the lates problem data
    const problem = await apiGetProblem(props.problem._id);
    setNumberOfLikes(Number(problem.data.RegisterProblem.numOfLikes));
  };

  const hasLiked = async () => {
    const liked = await apiHasLiked(likeReqObject);
    setLiked(liked.data.like);
  };

  const handleClose = () => {
    setVisible(false);
    props.changeModalStatus();
  };

  const onLikeStatusChange = async () => {
    if (liked) {
      //remove like
      try {
        await apiDeleteLike(likeReqObject);
        //reduce the number of likes from the problem
        await apiDecrementLike(props.problem._id);
        setLiked(false);
        let likeCount = numberOfLikes - 1;
        setNumberOfLikes(likeCount);
        props.changeLikeCount('decrement');
      } catch (err) {}
    } else {
      try {
        await apiCreateLike(likeReqObject);
        await apiIncrementLike(props.problem._id);
        setLiked(true);
        let likeCount = numberOfLikes + 1;
        setNumberOfLikes(likeCount);
        props.changeLikeCount('increment');
        await apiAddNotification(props.problem._id, likeReqObject.likeId, INotificationType.LIKE);
      } catch (err) {}
    }
  };

  const handleOpenProblem = () => {
    let actionType = '';
    const problemId = props.problem._id;
    if (props.problem.actionType === 'Quick Wins') {
      actionType = AppPages.QUICKWINS;
    } else if (props.problem.actionType === 'Gem') {
      actionType = AppPages.GEM;
    } else if (props.problem.actionType === 'Strategic') {
      actionType = AppPages.STRATEGIC;
    }

    if (actionType !== '' && problemId) {
      navigate(`${actionType}/${problemId}`);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        className="problem-modal"
        title={''}
        ariaLabel={'Lorem ipsum dolor sit'}
        header={
          <div className="problem-header" onClick={handleOpenProblem}>
            {props.problem.title}
          </div>
        }
        icons={''}
        footer={
          <Button className="close-button" kind="negative" onClick={handleClose}>
            Close
          </Button>
        }
      >
        <hr></hr>
        <div className="image-comment-view">
          {files?.data.length > 0 && (
            <div className="left-side">
              <SlideShow files={files.data} currentIndex={fileIndex} onDelete={''}></SlideShow>
            </div>
          )}

          <div className="right-side">
            <>
              <div className="problem-statement">Problem Statement</div>
              <div className="w-[70%] sm:max-w-none ">{answer}</div>
              <div className="like-container">
                <div
                  className={
                    liked ? `Appkit4-icon icon-thumb-up-fill` : `Appkit4-icon icon-thumb-up-outline`
                  }
                  aria-hidden="true"
                  onClick={onLikeStatusChange}
                ></div>
                <span className="int-item" onClick={() => setLikeModal(!likeModal)}>
                  {numberOfLikes} {numberOfLikes == 1 ? 'like' : 'likes'}{' '}
                </span>
              </div>
              <CommentModal
                problem={props.problem}
                changeCommentCount={() => props.changeCommentCount()}
                addComment={true}
                width={'550px'}
                height={'260px'}
              ></CommentModal>
            </>
            {likeModal && (
              <LikeModal
                show={likeModal}
                problem={props.problem}
                changeModalStatus={() => {
                  setLikeModal(false);
                }}
              ></LikeModal>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
