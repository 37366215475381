import "./RequestList.css"
import { IProblem } from '../../models/IProblem'
import { RequestCard } from './RequestCard'
import { useEffect, useState } from "react"
import { IRequestQuery } from "../../models/IRequestQuery"
import { apiLoadRequestProblems } from "../../services/apiService"
import { Pagination, Search, Select } from "@appkit4/react-components"
import { getInitRequestQuery } from "../../utils/QuestionUtil"
import { Constants, RequestStatus } from "../../utils/Constants"



//Show Request Problem List
export const RequestList = (props: { pending: boolean, onLoaded(count: number): any }) => {
    const requestStatuses = [
        { value: RequestStatus.Approved, label: RequestStatus.Approved },
        { value: RequestStatus.Declined, label: RequestStatus.Declined },
        { value: RequestStatus.Solved, label: RequestStatus.Solved },
        { value: RequestStatus.InProgress, label: RequestStatus.InProgress }
    ];

    const [problems, setProblems] = useState<IProblem[]>([]);
    const [count, setCount] = useState<number>(0); // the number of record 
    const [search, setSearch] = useState<string>('');
    const [problemStatus, setProblemStatus] = useState<string[]>([]);
    const [page, setPage] = useState<number>(1);
    const [totalPage, setTotalPage] = useState<number>(1)


    useEffect(() => {

        const requestQuery: IRequestQuery = getInitRequestQuery(props.pending, search, problemStatus.length > 0 ? problemStatus : requestStatuses.map(s => { return s.value }), page);
        loadRequestList(requestQuery);

    }, [problemStatus, search, props.pending, page])


    const loadRequestList = (requestQuery: IRequestQuery) => {
        apiLoadRequestProblems(requestQuery).then((response: any) => {
            setCount(response.data.Count);
            setTotalPage(Math.ceil(response.data.Count / Constants.requestPerPage))
            props.onLoaded(response.data.Count);
            setProblems(response.data.requestProblems);
        })
    }

    const onPageChange = (page: number) => {
        setPage(page);
    }

    return (
        <div className="request-list">
            {!props.pending && <div className="request-filter">
                <div>
                    <Search
                        onSearch={value => setSearch(value)}
                    />
                </div>
                <div>
                    <Select
                        data={requestStatuses}
                        value={problemStatus}
                        searchable={false}
                        multiple={true}
                        placeholder={''}
                        dropdownAlwaysDown={false}
                        valueKey={'value'}
                        labelKey={'label'}
                        showSelectAll={true}
                        onSelect={vals => setProblemStatus(vals)}>
                    </Select>
                </div>
            </div>
            }
            <div>
                {problems.map((p: IProblem) => {
                    return (<RequestCard key={p._id} problem={p} ></RequestCard>)
                })}
            </div>
            <div className="request-card-navigation">
                {
                    totalPage > 1 && <Pagination
                        current={page}
                        total={totalPage}
                        onPageChange={onPageChange}
                    ></Pagination>
                }
            </div>
        </div>
    )
}
