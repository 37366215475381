export const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_CLIENT_ID || "",
      authority: process.env.REACT_APP_AUTHORITY || "", 
      redirectUri: process.env.REACT_APP_REDIRECTURI,
      postLogoutRedirectUri: process.env.REACT_APP_REDIRECTURI,
    },
    cache: {
      cacheLocation: "sessionStorage", 
      storeAuthStateInCookie: false, 
    }
  };
  
  
  export const loginRequest = {
    redirectStartPage: `${process.env.REACT_APP_REDIRECTURI}`,
    scopes: ["User.Read"]
  };

 
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  };