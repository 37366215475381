import React, { useContext, useEffect, useState } from 'react';

import { Button } from '@appkit4/react-components';
import { TrainingTopic } from './TrainingTopic';
import { AppPages } from '../../utils/Constants';
import { ITraining } from '../../models/ITraining';
import { UserContext } from '../UserContext/UserContext';
import { useNavigate } from 'react-router-dom';

import './TrainingContents.css';
import { useAccount, useMsal } from '@azure/msal-react';
import { apiFindUser, apiGetTrainings } from '../../services/apiService';

export const TrainingContents = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const navigate = useNavigate();
  const [user, setUser] = useContext(UserContext);
  const [trainings, setTrainings] = useState<{ data: ITraining[] }>({ data: [] });
  const fetchQuestions = async () => {
    apiGetTrainings()
      .then((res) => {
        setTrainings({ data: res.data.trainings });
      });
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  useEffect(() => {}, [user]);

  const startSolving = () => {
    navigate(`/${AppPages.PROBLEM}`);
  };

  const updateTraining = (res: any) => {
    const trainingCompleted = res?.trainings?.find(
      (t: any) => t.completed === false && t.required === true,
    );
    if (trainingCompleted) {
    } else {
      apiFindUser(account?.username)
        .then((res) => {
          if (res.data.userProfile) {
            const trainingCompleted = res.data.userProfile.trainings?.find(
              (t: any) => t.completed === false && t.required === true,
            );
            if (trainingCompleted) {
              setUser({
                ...user,
                givenName: res.data.userProfile.firstName,
                surname: res.data.userProfile.lastName,
                OfficeLocation: res.data.userProfile.location,
                mail: res.data.userProfile.email,
                trainingCompleted: false,
                role: account?.idTokenClaims?.roles ? account?.idTokenClaims?.roles[0] : '',
                unitPreferences: res.data.userProfile.unitPreferences
              });
            } else {
              setUser({
                ...user,
                givenName: res.data.userProfile.firstName,
                surname: res.data.userProfile.lastName,
                OfficeLocation: res.data.userProfile.location,
                mail: res.data.userProfile.email,
                trainingCompleted: true,
                role: account?.idTokenClaims?.roles ? account?.idTokenClaims?.roles[0] : '',
                unitPreferences: res.data.userProfile.unitPreferences
              });
            }
          }
        });
    }
  };

  return (
    <div className="training-content-container">
      <div className="title">Welcome to {process.env.REACT_APP_LOGIN_TITLE}</div>
      <div className="subtitle">
        Below you will find helpful tools & resources that you can refer to as you work through your
        problems.
      </div>
      <div className="contents">Contents</div>
      <div className="topics">
        {trainings.data.map((training: ITraining) => {
          return (
            <TrainingTopic
              key={training.title}
              training={training}
              onChange={updateTraining}
            ></TrainingTopic>
          );
        })}
      </div>
      <div className="footer">
        {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. */}
      </div>
      <div className="button">
        <Button kind="negative" onClick={startSolving} disabled={!user?.trainingCompleted}>
          Start Solving
        </Button>
      </div>
    </div>
  );
};
