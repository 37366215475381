import { Select } from "@appkit4/react-components";
import { useEffect, useState } from "react";
import { apiGetLookup } from "../../services/apiService";
import { dropdownSort } from "../../utils/Utils";

import "./PreferenceDropdownList.css";

export const PreferenceDropdownList = (props: {multiple: boolean, field: string, value: any, group?: any, onSelected(vals: any): any }) => {

    const [options, setOptions] = useState([]);

    const fetchOptions = async () => {
        apiGetLookup(props.field)
            .then(res => {
                let businessUnits = res.data.lookups.map((option: any) => {
                    return { value: option.value, label: option.label, group: option.group }
                });
                if(props.group){
                    businessUnits = businessUnits.filter((option: any)=>option.group==props.group)
                }
                setOptions(businessUnits.sort(dropdownSort));
            });
    }

    useEffect(() => {
        fetchOptions();
    }, [props.group]);




    return (
        <Select
            multiple={props.multiple}
            placeholder={""}
            dropdownAlwaysDown={false}
            data={options}
            showSelectAll={false}
            value={props.value}
            valueKey={'value'}
            labelKey={'label'}
            searchable={false}
            className="PreferenceDropdownList"
            onSelect={(vals:any) => { props.onSelected(vals); }}>
        </Select>
    )
        ;
}