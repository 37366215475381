import { Button } from '@appkit4/react-components';
import { Switch } from '../Switch/Switch';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../UserContext/UserContext';
import { useEffect, useState, useContext } from 'react';
import { ILookup } from '../../models/ILookup.type';
import { IQuickWinsQuestion } from '../../models/IQuickWinsQuestion';
import { IQuickWinsQuestionResponse } from '../../models/IQuickWinsResponse';
import { INotificationType } from '../Notification/INotificationType';
import { IProblem } from '../../models/IProblem';
import { apiGetLookup, apiGetQuickWinsResponse, apiSetLastPage, apiTraceEvent, apiGetProblem, apiToggleProblemPrivateStatus } from '../../services/apiService';
import { BlobLink } from '../Blobs/BlobLink';
import { QuickWinsProblem } from '../QuickWinsProblem/QuickWinsProblem';
import { Feedback } from '../Feedback/Feedback';
import { AppPages, Constants, ProblemStatus } from '../../utils/Constants';
import { QuickWinsConfirmModal } from './QuickWinsConfirmModal';

import { apiCompleteProblem, apiQuickWinsResponse, apiAddNotification, apiResolveProblem } from '../../services/apiService';
import { validateUserReadOnlyAccess } from '../../utils/QuestionUtil';

import './QuickWins.css';
import { TeamMember } from '../TeamMember/TeamMember';

export const QuickWins = () => {
  const initQuickWinsResonse: IQuickWinsQuestionResponse = {
    _id: '',
    problemId: '',
    userId: '',
    questions: [],
  };
  const [user] = useContext(UserContext);
  const [quickWins, setQuickWins] = useState<IQuickWinsQuestionResponse>(initQuickWinsResonse);
  const [parpdf, setParpdf] = useState('');
  const [validated, setValidated] = useState<boolean>(false);
  const [confirm, setConfirm] = useState(false);
  const [questions, setQuestions] = useState<{ data: IQuickWinsQuestion[] }>({ data: [] });
  const navigate = useNavigate();
  const Categories = ['Problem', 'Action', 'Result'];
  const [pdfQuickwins, setpdfQuickwins] = useState('');
  const [saving, setSaving] = useState<boolean>(false); //show loading icon when click button
  const { problemId } = useParams();
  const [readOnlyAccess, setReadOnlyAccess] = useState<boolean>(false);
  const [feedbackModal, setFeedbackModal] = useState<boolean>(false);
  const [privateProblem, setPrivateProbem] = useState<boolean>(false);

  const validateQuickWins = (): boolean => {
    const missingAnswer = questions.data.find((q) => !q.answerText);
    if (missingAnswer) return false;
    else return true;
  };

  const loadQuickWinsProfile = async () => {
    if (problemId) {
      // get readonly access (true/false)
      const readOnly: boolean = await validateUserReadOnlyAccess(problemId, user?.mail);
      setReadOnlyAccess(readOnly);
      // set last problem page to quickwins
      if (!readOnly) await apiSetLastPage(problemId, AppPages.QUICKWINS);

      apiGetQuickWinsResponse(problemId)
        .then((res) => {
          setQuickWins(res.data.QuickWinsResponse);
          setQuestions({ data: res.data.QuickWinsResponse.questions });
      });
      // set problem private status
      const problem: IProblem = (await apiGetProblem(problemId)).data.RegisterProblem;
      setPrivateProbem(problem?.private);
    }
  };

  useEffect(() => {
    loadQuickWinsProfile();
    apiGetLookup(`${process.env.REACT_APP_PDF_QUICKWIN1}`).then((res: any) => {
      let pdfs = res.data.lookups.map((option: ILookup) => {
        return option.value;
      });
      if (pdfs) setpdfQuickwins(pdfs[0]);
    });
  }, [user]);

  useEffect(() => {
    const v = validateQuickWins();
    setValidated(v);
  }, [questions]);

  const handleAnswer = (key: any, name: any, val: any) => {
    if (name == 'answerText') {
      const newState = questions.data.map((obj: any) => {
        if (obj.questionNo == key) {
          return { ...obj, [name]: val };
        }
        return obj;
      });
      setQuestions({ data: newState });
    } else {
      const newState = questions.data.map((obj: any) => {
        if (obj.questionNo == key) {
          return { ...obj, [name]: val };
        }
        return obj;
      });

      setQuestions({ data: [...newState] });
    }
  };

  const handleSubmitQuestions = async () => {
    setConfirm(true);
    if (problemId) {
      await apiCompleteProblem(problemId);
      await apiTraceEvent(user?.mail, problemId || '', ProblemStatus.Resolved);
      await apiAddNotification(problemId, problemId, INotificationType.TAG);
    }
  };

  const closeConfirm = (result: boolean) => {
    setConfirm(false);
    if (result) {
      const updated = { ...quickWins, questions: questions.data };
      if (problemId) {
        apiQuickWinsResponse(problemId, updated).then(res => {
          const resolvedStatus = result;
          apiResolveProblem(problemId, resolvedStatus).then(res => {
            setFeedbackModal(true);
          });
        });
      }
    }
  }

  const handleSaveQuestions = () => {
    let updated = { ...quickWins, questions: questions.data };
    setSaving(true);
    if (problemId) apiQuickWinsResponse(problemId, updated)
      .then((res) => {
        setTimeout(() => {
          //provide time for user to see the loading icon
          setSaving(false);
        }, Constants.saveLoaderTimeout);
      });
  };

  return (
    <div className="quickwins-container">
      <div className="bar">Register Problem &gt; Intake Questionnaire &gt; Review &gt; Result</div>

      <div className="quickwins-side">
        <div className="quickwins-left">
          <div className="title">Quick Win</div>
          <div className="subtitle">
            PAR Tool
            <BlobLink file={pdfQuickwins}>
              <span className="Appkit4-icon icon-help-question-outline"></span>
            </BlobLink>{' '}
          </div>
          {problemId && <TeamMember problemId={problemId} readOnlyAccess={readOnlyAccess} ></TeamMember>}
        </div>
        {!readOnlyAccess && 
          <div className="switchbox" title='Private problems are only visible to team members tagged as collaborators'>
            <Switch
              checked={privateProblem} 
              onChange={() => {
                if (problemId) {
                  apiToggleProblemPrivateStatus(problemId, !privateProblem);
                  setPrivateProbem(!privateProblem);
                }
              }}
              label="Private"
              class="quickwins"
            />
          </div>
        }
        <div className="quickwins-right">
          {Categories.map((category) => {
            return (
              <div key={category}>
                <div className="header">{category}</div>
                {questions?.data &&
                  questions.data.map((question) => {
                    return (
                      <div key={question.questionNo}>
                        {question.category == category && (
                          <QuickWinsProblem
                            question={question}
                            onChange={handleAnswer}
                            readOnlyAccess={readOnlyAccess}
                          ></QuickWinsProblem>
                        )}
                      </div>
                    );
                  })}
              </div>
            );
          })}

          <hr></hr>
          <div className="quickwins-submit">
            {(readOnlyAccess === false) && <Button kind="negative" disabled={!validated} onClick={handleSubmitQuestions}>
              Problem Solved
            </Button>}
            {
              !readOnlyAccess &&
              <Button kind="secondary" loading={saving} onClick={handleSaveQuestions}>
                Save
              </Button>
            }
          </div>
          <QuickWinsConfirmModal show={confirm} onClose={closeConfirm}></QuickWinsConfirmModal>
          {feedbackModal && <Feedback companyId={quickWins.questions[0].companyId} problemId={quickWins.problemId}></Feedback>}
        </div>
      </div>


    </div>
  );
};
