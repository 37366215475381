import React, { useEffect, useState } from 'react'
import { IResponseQuestion } from '../../models/IResponseQuestion'
import { groupArrayOfObjects } from '../../utils/Utils'
import { QuestionGridCell } from './QuestionGridCell'

import './QuestionGridRow.css'



//Row Component for Grid
export const QuestionGridRow = (props: { group: number, stepGroupQuestions: IResponseQuestion[], onChanged(key: string, name: string, vals: any): any, readOnlyAccess: boolean}) => {

    const [groups, setGroups] = useState({});

    useEffect(() => {
        setGroups(groupArrayOfObjects(props.stepGroupQuestions, "group"));
    }, [props.stepGroupQuestions])

    useEffect(()=>{
    },[])
    useEffect(() => {
    },
        [groups])

    const handleAnswer = (key: any, name: any, val: any) => {
        props.onChanged(key, name, val);
    }

   
    return (
        <div className="question-grid-row-container">
            {
                

                props.stepGroupQuestions.filter((q:IResponseQuestion)=>q.questionType!=='hidden').map((question: any, index:number) => {
                    return <QuestionGridCell key={question._id+question.group+index} question={question} onChanged={handleAnswer} readOnlyAccess={props.readOnlyAccess}></QuestionGridCell>
                })
            }


        </div>
    )
}