import { useEffect, useState } from 'react';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';

import { Login } from './pages/Login/Login';
import { UserContext } from './components/UserContext/UserContext';
import { SessionTimeOut } from './components/SessionTimeOut/SessionTimeOut';
import { BrowserRouter } from 'react-router-dom';
import { _isAuthorized } from './utils/Utils';
import RequestInterceptor from './components/RequestInterceptor';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin }  from "./utils/appInsights"

import { EventType } from '@azure/msal-browser';
import { AppRoutes } from './components/AppRoutes';
import './App.css';

function App() {
  const [user, setUser] = useState<{ [key: string]: any }>();
  const [isActive, setIsActive] = useState(true);
  const [isLogout, setLogout] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [isAccessDenied, setAccessDenied] = useState(false);

  const { instance, accounts } = useMsal();
  
  useEffect(() => {
    const callbackId = instance.addEventCallback((message: any) => {
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        const result = message.payload;
      }
      if (message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        const validatedTenantId = process.env.REACT_APP_TENANTID?.split(', ').includes(
          message.payload.tenantId,
        );
        if (validatedTenantId === false) {
          setIsLogin(false);
          setAccessDenied(true);
          instance.logoutPopup().catch((e: any) => { });
        } else {
          setIsLogin(true);
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
    <DndProvider backend={HTML5Backend}>
      <UserContext.Provider value={[user, setUser]}>

        <div className="App">
          <UnauthenticatedTemplate>
            <Login accessDenied={isAccessDenied}></Login>
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <RequestInterceptor>
              <>
                {isLogin && 
                  <BrowserRouter>
                    <AppRoutes />
                  </BrowserRouter>
                }
                <SessionTimeOut
                  onActive={() => {
                    setIsActive(true);
                  }}
                  onIdle={() => {
                    setIsActive(false);
                  }}
                  onLogout={() => {
                    setLogout(true);
                  }}
                  timeOutInterval={process.env.REACT_APP_TIMEOUT}
                />
              </>
            </RequestInterceptor>
          </AuthenticatedTemplate>
        </div>

      </UserContext.Provider>
    </DndProvider>
    </AppInsightsContext.Provider>
  );
}

export default App;
