import React, { useContext } from 'react';
import { Layout } from '../../components/Layout/Layout';
import { QuickWins } from '../../components/QuickWins/QuickWins';
import { UserContext } from '../../components/UserContext/UserContext';
import { AppPages } from '../../utils/Constants';
import { useTrackPage } from '../../utils/useTrackPage';

export const QuickWinsPage = () => {
  const [user, setUser] = useContext(UserContext);
  useTrackPage(AppPages.QUICKWINS);
  return <Layout currentTab="problem">{user && <QuickWins></QuickWins>}</Layout>;
};
