/*
* Model that displays a list of notifications for the user
* Accepts: {
*   show: boolean value to display the modal (true -> visible, false -> not visible)
*   onClose: function to be executed on closing the modal
* }
*/

import { useState, useEffect, useContext } from 'react';
import { Modal, Button } from '@appkit4/react-components';
import { UserContext } from "../UserContext/UserContext";
import { NotificationListItem } from './NotificationListItem';

import { apiGetNotificationsForUserBetweenRange, apiMarkAllNotificationsSeenForUser } from '../../services/apiService';

import { INotification } from '../../models/INotification';
import { Constants } from '../../utils/Constants';

import "./Notification.css";

export const Notification = (props: {
    show: boolean;
    onclose: any;
}) => {
    const [user, setUser] = useContext(UserContext);
    const [visible, setVisible] = useState(false);
    const [notifications, setNotifications] = useState<INotification[]>([]);
    const [notificationIdx, setNotificationIdx] = useState<number>(0);
    const [isNotificationRemaining, setIfNotificationRemaining] = useState<boolean>(true);
    const numOfNotificationsToShowPerLoad = Constants.numOfNotifications;

    useEffect(() => {
        setVisible(props.show);
        fetchNotifications(notificationIdx);
    }, []);

    const fetchNotifications = async (start: number) => {
        const userId = user?.mail;
        apiGetNotificationsForUserBetweenRange(userId, start, numOfNotificationsToShowPerLoad)
        .then(res => {
            const allNotifications: INotification[] = res.data.notifications;
            const remainingNotifications: boolean = res.data.remainingNotifications;
            setIfNotificationRemaining(remainingNotifications);
            allNotifications.map((notification: INotification) => {
                setNotifications(oldNotifications => [...oldNotifications, notification]);
            });
        });
    }

    const handleClose = () => {
        setVisible(false);
        props.onclose();
    }

    const handleLoadMoreNotifications = () => {
        const newNotificationIdx = notificationIdx+numOfNotificationsToShowPerLoad;
        setNotificationIdx(newNotificationIdx);
        fetchNotifications(newNotificationIdx);
    }

    const handleMarkAllAsRead = async () => {
        const userId = user?.mail;
        notifications.map((notification: INotification) => {
            notification.read = true;
        })
        setNotifications(notifications);
        handleClose();
        await apiMarkAllNotificationsSeenForUser(userId);
    }

    return (
        <Modal
            className='notification-modal'
            visible={visible}
            title={''}
            ariaLabel={'Lorem ipsum dolor sit'}
            placement="top"
            closable={true}
            maskCloseable={true}
            onCancel={handleClose}
            icons={
                <div className='close-arrow'>
                    <span className="Appkit4-icon icon-arrow-right-outline" onClick={handleClose}></span>
                </div>
            }
        >
            <div className='modal-header'>Notifications</div>
            <span className='all-read-button' onClick={handleMarkAllAsRead}>Mark All As Read</span>
            <div className='notification-list'>
                {notifications.length > 0 
                    ? 
                    notifications.map((notification: INotification) => {
                        return <NotificationListItem key={notification.notificationId} notification={notification}></NotificationListItem>
                    })
                    : <div className='no-notification'>No Notification Found</div>
                }
            </div>
            {isNotificationRemaining && <span className='load-more-button' onClick={handleLoadMoreNotifications}>Load More</span>}
        </Modal>
    );

}