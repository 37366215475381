import React, { useEffect, useState } from 'react'

import { Button } from '@appkit4/react-components'
import { IResponseQuestion } from '../../models/IResponseQuestion'
import { groupArrayOfObjects } from '../../utils/Utils'

import { QuestionGroup} from './QuestionGroup'

import './QuestionStep.css'
import { IStepHeading } from '../../models/IStepHeading'


//Questions for a Step
export const QuestionStep = (props: {
    step: number;
    stepQuestions: IResponseQuestion[];
    heading: IStepHeading,
    filterCategory: string;
    onChanged(key: string, name: string, vals: any): any;
    onAddGroup(step:number): any;
    onRemoveGroup(step:number, group: number): any;
    onFocus(key: string, name: string, vals: any): any
    readOnlyAccess: boolean;
}) => {

    const [groups, setGroups] = useState({});


    useEffect(() => {
        setGroups(groupArrayOfObjects(props.stepQuestions, "group"));
    }, [props.stepQuestions])


    const handleAnswer = (key: any, name: any, val: any) => {
        props.onChanged(key, name, val);
    }

    const handleFocus = (key: any, name: any, val: any) => {
        props.onFocus(key, name, val);
    }

    const addGroup = () => {
        props.onAddGroup(props.step);
    }
    const removeGroup = (group: number) => {
        props.onRemoveGroup(props.step, group)
    }

    return (
        <div className="step-questions-container">
            <div className="step-title">Step {props.heading.stepInTitle?props.heading.stepInTitle:props.heading.step}: {props.heading.title}</div>
            <div className="step-subtitle">{props.heading.subtitle}</div>
            {
                groups && Object.keys(groups).map(
                    (keyName: string, i: number) => {
                         
                       return (
                            <QuestionGroup key={keyName} group={Number(keyName)} stepGroupQuestions={groups[keyName as keyof typeof groups]} onChanged={handleAnswer} onFocus={handleFocus} onRemove={removeGroup} filterCategory={props.filterCategory} readOnlyAccess={props.readOnlyAccess}></QuestionGroup>
                        )
                    }
                )
            }
            {
                (props.stepQuestions[0].multigroup) && (props.readOnlyAccess === false) && <div className="group-add">
                    <Button kind="text" onClick={addGroup}><span className="Appkit4-icon icon-plus-outline"></span>Add Cause</Button>
                </div>
            }

        </div>
    )
}