import { Button, Tag } from '@appkit4/react-components';
import React, { useEffect, useState } from 'react'
import { apiGetProblem, apiUpdateProblem } from '../../services/apiService';
import { Constants } from '../../utils/Constants';
import { TagUsers } from '../TagUsers/TagUsers';

import "./TeamMember.css";

export const TeamMember = (props: { problemId: string, readOnlyAccess: boolean }) => {

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [saving, setSaving] = useState<boolean>(false);
    const [dirty, setDirty] = useState<boolean>(false);

    const removeUser = (index: number) => {
        const _selectedUsers = [...selectedUsers];
        _selectedUsers.splice(index, 1);
        setSelectedUsers(_selectedUsers);
    };

    useEffect(() => {
        if (props.problemId) {
            apiGetProblem(props.problemId).then((res) => {
                setSelectedUsers(res.data.RegisterProblem.teamMember); //add the tagged users
            });
        }
    }, [props.problemId])

    const selectionChanged = (vals: any) => {
        setDirty(true);
        setSelectedUsers(vals);
    }

    const handleSubmit = async (event: any) => {

        setSaving(true);
        apiGetProblem(props.problemId).then((res) => {
            const problem = res.data.RegisterProblem;
            const updatedProblem = { ...problem, ['teamMember']: selectedUsers };
            apiUpdateProblem(props.problemId, updatedProblem).then((res: any) => {
                setTimeout(() => {
                  setSaving(false);
                  setDirty(false);
                }, Constants.saveLoaderTimeout);
              });            
        }).catch(()=>setSaving(false));
       
    };

    return (
        <div className="team-member-container">
            <div className="team-member-title">
                Team Members
            </div>
            <div>
                <TagUsers value={selectedUsers} readOnlyAccess={props.readOnlyAccess} onChange={selectionChanged}></TagUsers>
            </div>
            <div>
               { dirty && <Button
                    kind="secondary"
                    className="team-member-save"
                    loading={saving}
                    onClick={handleSubmit}
                >
                    Save
                </Button>
}
            </div>
        </div>
    )
}
