import { useEffect, useState } from "react"
import { Tooltip } from "@appkit4/react-components";
import { ToastContainer, toast } from "react-toastify";

import { IQuickWinsQuestion } from "../../models/IQuickWinsQuestion";
import { SlideShow } from "../SlideShow/SlideShow"
import { ValidateImageUpload } from "../../utils/ValidateImageUpload";
import { ValidateVideoUpload } from "../../utils/ValidateVideoUpload";
import { RawContent } from "../RawContent/RawContent";

import "react-toastify/dist/ReactToastify.css";
import "./QuickWinsProblem.css"
import { apiUploadFile } from "../../services/apiService";


export const QuickWinsProblem = (props: { question: IQuickWinsQuestion, onChange: any, readOnlyAccess: boolean }) => {


    const [files, setFiles] = useState<{ data: string[] }>({ data: [] });
    const [fileIndex, setFileIndex] = useState<number>(0);
    const [errorPopup, setErrorPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = async (file: any, isImage: Boolean) => {
        if (isImage) {
            const validileName = await ValidateImageUpload(file.name);
            if (!validileName) {
                toast.error(`Error: Image Extension should be of type ${process.env.REACT_APP_IMAGE_FILES}`);
                return;
            }
        } else {
            const validileName = await ValidateVideoUpload(file.name);
            if (!validileName) {
                toast.error(`Error: Video Extension should be of type ${process.env.REACT_APP_VIDEO_FILES}`);
                return;
            }
        }

        const formData = new FormData();
        if (file)
            formData.append("upload", file);
        try {
            apiUploadFile(formData).then((res: any) => {
                let newfiles: string[] = [...files.data];
                newfiles.push(res.data.url);
                setFiles({ data: newfiles });
                setFileIndex(files.data.length);
                props.onChange(props.question.questionNo, "answerAttachments", newfiles)
            });
        } catch (error) {
        }
    }

    const uploadImage = async (event: any) => {
        await handleSubmit(event.target.files[0], true);
    }

    const uploadVideo = async (event: any) => {
        await handleSubmit(event.target.files[0], false);
    }

    useEffect(() => {
        setFiles({ data: props.question.answerAttachments });
    }, [])

    const deleteSilde = (sliderIndex: number) => {
        const newFiles = { ...files };
        newFiles.data.splice(sliderIndex, 1);
        setFiles(newFiles);
        if (sliderIndex > 0) setFileIndex(sliderIndex - 1);
        if (sliderIndex > files.data.length) setFileIndex(files.data.length);
    }

    const handleAnswerChange = (event: any) => {
        props.onChange(props.question.questionNo, [event.target.name], event.target.value)

    }

    return (
        <div className="quickwins-problem-container" >
            <div className="question">
                <div className="group">{props.question.subcategory}</div>
                {props.question.question.map(q => {
                    return (
                        <div key={q}>
                            <RawContent input={q} links={props.question.questionLinks}></RawContent>
                            {props.question.required && <span className="question-red-star">*</span>}
                        </div>
                    );
                })}
            </div>
            <div className="tool-bar">
                <div className="video-upload">
                    <Tooltip
                        className="tooltip"
                        trigger='hover'
                        position='top-left'
                        distance={4}
                        id="tooltipVideo"
                        content='Upload Video'
                    >
                        <label aria-describedby="tooltipVideo">
                            {(props.readOnlyAccess === false) && <span className="Appkit4-icon icon-video-outline"></span>}
                            <input type="file" onChange={uploadVideo} accept={process.env.REACT_APP_VIDEO_FILES} />
                        </label>
                    </Tooltip>
                </div>
                <div className="image-upload">

                    <Tooltip
                        className="tooltip"
                        trigger='hover'
                        position='top-left'
                        distance={4}
                        id="tooltipImage"
                        content='Upload Image'
                    >
                        <label aria-describedby="tooltipImage">
                            {(props.readOnlyAccess === false) && <span className="Appkit4-icon icon-image-outline"></span>}
                            <input type="file" onChange={uploadImage} accept={process.env.REACT_APP_IMAGE_FILES} />
                        </label>
                    </Tooltip>
                </div>
                <ToastContainer/>
            </div>

            <div className="content">

                <div className="textarea">
                    <textarea name="answerText" value={props.question.answerText} onChange={handleAnswerChange} disabled={props.readOnlyAccess}></textarea>
                </div>
            </div>


            {files?.data.length > 0 && <SlideShow files={files.data} currentIndex={fileIndex} onDelete={props.readOnlyAccess ? "" : deleteSilde} ></SlideShow>}

        </div>
    )
}