import React, { useEffect, useState } from 'react'
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Legend } from 'recharts';
import { apiQueryUsers } from '../../../services/apiService';
import { getInitQueryFilter } from '../../../utils/QuestionUtil';
import { DropdownList } from '../../DropdownList';

//User By Department Chart
export const UsersByDepartmentChart = () => {

  const initData: any[] = [];
  const [data, setData] = useState<any[]>(initData);

  const [division, setDivision] = useState<string>('');

  useEffect(() => {
    const filter = getInitQueryFilter();
    filter.divisions.push(division);

    apiQueryUsers(filter).then((res: any) => {
      setData(res.data)
    })
  }, [division])

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#203570', '#49ABA0', '#E0301E', '#E27588', '#6B2CDA', '#D299FF'];

  return (
    <div>
      <div className="report-filter-size-one" >
        <DropdownList multiple={false} field="division" value={division} readonlyAccess={false} placeholder={"Select a division"} onSelected={value => setDivision(value)}></DropdownList>
      </div>
      <div>
        <ResponsiveContainer width={440} height={500}>
          <PieChart >
            <Legend layout="vertical" verticalAlign="bottom"  />
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={true}
              label
              innerRadius="50%"
              outerRadius="90%"
              fill="#8884d8"
              dataKey="total"
              nameKey="name"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>

      </div>
    </div>
  )
}
